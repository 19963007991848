import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import loadingicon from "../../../images/loading.gif";
import{GetUserDetails} from '../../../_helpers/Utility';
import SearchIcon from '@material-ui/icons/Search';
import { CSVReader, CSVDownloader } from "react-papaparse";
const Fileupload = require("../../../_helpers/fileupload");

import Pagenations from "../../Pagenations";
import StyleHeader from "../StickyHeader/StickyHeader";


import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
    return (
      <span className="shorting">
        <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
        <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
      </span>
    )
  }



const imgstyle = {
  height: "40px",
  width: "40px",
  borderradius: "50%",
};

export default function SDRAssignmentsTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ClientName");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsLoading,SetIsLoading] = React.useState(0)
  const [sortedColumn, setSortedColumn] = React.useState("ClientName");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  //import csv
  const [files, setFiles] = React.useState([]);
  const [reset, setReset] = React.useState(false);
  const [filename, setFilename] = React.useState("");
  const [csvdata, setCsvdata] = React.useState([]);
  const [DropBoxData, SetDropBoxData] = React.useState([]);
  const [MapAttrBox, SetMapAttrBox] = React.useState(false);
  const [opencsv, setOpencsv] = React.useState(false);
  const [MapCsvs, SetMapcsvs] = React.useState(false);
  const [TotalResult, SetTotalResult] = React.useState(0);
  const [TotalDisplayCount, SetTotalDisplayCount] = React.useState(false);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalSuccess, SetTotalSuccess] = React.useState(0);
  const [SampleExportData, SetSampleExportData] = React.useState([]);
  const [PhoneNumber1, SetPhoneNumber1] = React.useState(null);
  const [PhoneNumber2, SetPhoneNumber2] = React.useState(null);
  const [PhoneNumber3, SetPhoneNumber3] = React.useState(null);
  const [ClientName, SetClientName] = React.useState(null);
  const [SdrName, SetSdrName] = React.useState(null);
  const [EmailAccount, SetEmailAccount] = React.useState(null);



  useEffect( () => {
  
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    GettingSDRAssignmentList(GetUserData.ParentUserID,GetUserData.Role);
    SampleFileExportdetails();
  }, [Search, Page, RowsPerPage]);

  //Getting SDR Assignment list
  const GettingSDRAssignmentList = (UID,URole) => {
    SetIsLoading(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UID,
      Role: URole
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/sdrassignment/GetSDRAssignmentDetails",
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
      setIsDataLoaded(true);

    });
  }


  //search for record
  const RequestSearch = (Event) => {
      if (Event.key === "Enter") {
        var SearchedVal = document.getElementById("search").value?.trim();
        if(SearchedVal==Search){
          SetSearch(SearchedVal);
          SetPage(1);
        }else{
          SetSearch(SearchedVal);
          SetPage(1);
    
        }
      }
  };
  
  //change Page
  const HandleChangePage = (Event, NewPage) => {
      if(NewPage==Page){
        SetPage(NewPage);
      }else{
          SetPage(NewPage);
         
      }
  };
  
  //change display rows
  const ChangeRowSelected = (Event) => {
      SetRowsPerPage(Number(Event.target.value));
      SetPage(1);
     
  };
  
  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      Sort: true,
      Field: Field,
      SortBy: SortBy,
      UserID: UserID,
      Role: Role
    };
     Axios({
      url:CommonConstants.MOL_APIURL +"/sdrassignment/GetSDRAssignmentDetails",
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetPage(1)
      SetIsLoading(false)
    });
  };

   //edit page
   const EditBtn = (id) => {
    history.push("/editsdrassignment", id);
  };


  //Add 
  const AddBtn = () => {
    history.push("/addsdrassignment");
  }


  //Delete SDR Assignment
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a SDR Assignment.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputParameters = {
          ID: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          Role: Role,
        };
        Axios({
          url:CommonConstants.MOL_APIURL + "/sdrassignment/DeleteSDRAssignment",
          method: "POST",
          data: InputParameters,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "SDR Assignment deleted successfully.",
                "success"
              );
              GettingSDRAssignmentList(UserID,Role)
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };


   //import csv numbers
   const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOnError = (err) => {};

  const handleOnRemoveFile = (data) => {};

  const resetcsv = () => {
    setReset(true);
  };

  const handleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;
    setFiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length - 1] !== "csv") {
      setReset(true);
      resetcsv();
    } else {
      var csvfile =
        sep.slice(0, -1).join(".") +
        new Date().getTime() +
        "." +
        sep[sep.length - 1];
      setFilename(csvfile);

      // Filter out rows with all empty strings
      const filteredData = data.filter(
        (row) => !row.data.every((item) => item.trim() === "")
      );

      // Set the filtered data to csvdata
      setCsvdata(filteredData);
      SetDropBoxData(filteredData[0].data);
    }
  };

  // handle on error
  const HandleOnError = (err, file, inputElem, reason) => {};

  // handle on remove file
  const HandleOnRemoveFile = (data) => {};

  //import
  const importcsv = (e) => {
    e.preventDefault();
    setOpencsv(true);
  };

  // map csv
  const NumberMapCsv = () => {
    if (csvdata.length != 0) {
      DropBoxData.map((dddd, index) => {});
      SetMapAttrBox(false);
      document.getElementById("map_attribute").style.display = "block";
      setReset(true);
    } else {
      toast.error(
        <div className="toastsize">
          SDR Assignment
          <br />
          Please select file
        </div>
      );
      setReset(true);
      SetMapAttrBox(true);
    }
    hidesCSVHeaderOptions();
  };

  const hidesCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };

  $("select").on("change", function () {
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");
    if (Name == "ClientName") {
      SetClientName(value);
    } else if (Name == "SdrName") {
      SetSdrName(value);
    } else if (Name == "EmailAccount") {
      SetEmailAccount(value);
    }else if (Name == "PhoneNumber1") {
      SetPhoneNumber1(value);
    }else if (Name == "PhoneNumber2") {
      SetPhoneNumber2(value);
    }else if (Name == "PhoneNumber3") {
      SetPhoneNumber3(value);
    }
    hidesCSVHeaderOptions();
  });

  //mapcsv
  const Savecsv = async () => {
    try {
      
      let OriginalFileName = files.name;
      let onlyname = OriginalFileName.substring(
        0,
        OriginalFileName.lastIndexOf(".csv")
      );

      let saveAllContacts = [];
      let ClientName = parseInt(document.getElementById("ClientName")?.value);
      if (ClientName >= 0) {
        let ObjClientName = {
          FieldAlias: "Client Name",
          Fieldname: "ClientName",
          Customfield: null,
          index: ClientName,
        };
        saveAllContacts.push(ObjClientName);
      }
      let SdrName = parseInt(
        document.getElementById("SdrName")?.value
      );
      if (SdrName >= 0) {
        let ObjSdrName = {
          FieldAlias: "Sdr Name",
          Fieldname: "SdrName",
          Customfield: null,
          index: SdrName,
        };
        saveAllContacts.push(ObjSdrName);
      }

      let EmailAccount = parseInt(
        document.getElementById("EmailAccount")?.value
      );
      if (EmailAccount >= 0) {
        let ObjEmailAccount = {
          FieldAlias: "Email Account",
          Fieldname: "EmailAccount",
          Customfield: null,
          index: EmailAccount,
        };
        saveAllContacts.push(ObjEmailAccount);
      }

      let PhoneNumber1 = parseInt(document.getElementById("PhoneNumber1")?.value);
      if (PhoneNumber1 >= 0) {
        let ObjPhoneNumber1 = {
          FieldAlias: "Phone Number1",
          Fieldname: "PhoneNumber1",
          Customfield: null,
          index: PhoneNumber1,
        };
        saveAllContacts.push(ObjPhoneNumber1);
      }

      let PhoneNumber2 = parseInt(document.getElementById("PhoneNumber2")?.value);
      if (PhoneNumber2 >= 0) {
        let ObjPhoneNumber2 = {
          FieldAlias: "Phone Number2",
          Fieldname: "PhoneNumber2",
          Customfield: null,
          index: PhoneNumber2,
        };
        saveAllContacts.push(ObjPhoneNumber2);
      }

      let PhoneNumber3 = parseInt(document.getElementById("PhoneNumber3")?.value);
      if (PhoneNumber3 >= 0) {
        let ObjPhoneNumber3 = {
          FieldAlias: "Phone Number3",
          Fieldname: "PhoneNumber3",
          Customfield: null,
          index: PhoneNumber3,
        };
        saveAllContacts.push(ObjPhoneNumber3);
      }
      
      let ClientNames = document.getElementById("ClientName")?.value;
      let SdrNames = document.getElementById("SdrName")?.value;
      let EmailAccounts = document.getElementById("EmailAccount")?.value
      let PhoneNumbers1 = document.getElementById("PhoneNumber1")?.value
      
      if (PhoneNumbers1 === "") {
        toast.error(
          <div className="toastsize">
            Phone Number1 is required!
          </div>
        );
      }else if(SdrNames === ""){
        toast.error(
          <div className="toastsize">
            SDR name is required!
          </div>
        );
      }else if(EmailAccounts === ""){
        toast.error(
          <div className="toastsize">
            Email account is required!
          </div>
        );
      }else if(ClientNames === ""){
        toast.error(
          <div className="toastsize">
            Client name is required!
          </div>
        );
      }else {
        SetIsLoading(true);
        Fileupload.getBase64(files, (result) => {
          let rs = result;
          let splirs = rs.split("base64,");
          let _base64 = splirs[1];
          let myresult = {
            fields: "Coverimg",
            path: "./Content/UploadedFiles/UserSdrAssignments",
            file: _base64,
            imageName: filename,
            FileData: csvdata,
            ClientID: ClientID,
            UserID: UserID,
            Role:Role,
            CreatedBy: CUserID,
            CreatedDate: new Date(),
            saveAllContacts: saveAllContacts,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/sdrassignment/ImportCSVSdrAssignmentsData",
            method: "POST",
            data: myresult,
          }).then((Res) => {
            if (Res.data.StatusMessage == "SUCCESS") {
              toast.success(
                <div className="toastsize">SDR Assignment Imported Successfully.</div>
              );

              setOpencsv(true);
              SetMapAttrBox(true);
              document.getElementById("map_attribute").style.display = "block";
              SetTotalResult(Res.data.Total);
              SetTotalDuplicate(Res.data.TotalDuplication);
              SetTotalError(Res.data.TotalError);
              SetTotalSuccess(Res.data.TotalSuccess);
              SetTotalDisplayCount(true);
              setCsvdata([]);
              GettingSDRAssignmentList(UserID,Role)
              SetIsLoading(false);
            } else {
              toast.error(Res.data.Message);
              setOpencsv(true);
              SetMapAttrBox(true);
              document.getElementById("map_attribute").style.display = "block";
              SetTotalResult(Res.data.Total);
              SetTotalDuplicate(Res.data.TotalDuplication);
              SetTotalError(Res.data.TotalError);
              SetTotalSuccess(Res.data.TotalSuccess);

              setCsvdata([]);
              SetIsLoading(false);
            }
          });
        });
      }
    } catch (error) {
      // toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    }
  };

  const canclecsv = () => {
    resetcsv();
    setCsvdata([]);
    SetMapAttrBox(false);
    document.getElementById("map_attribute").style.display = "none";
    SetTotalDisplayCount(false);
    setOpencsv(false);
  };

  //sample
  const SampleFileExportdetails = () => {
    const ParamsObject ={
      Name: "SdrAssignment",
    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/sdrassignment/GetSamplePhoneNumberExport",
      method: "POST",
      data: ParamsObject,
    }).then((res) => {
      SetSampleExportData(res?.data);
    });
  };

  return (
    <div>
      {
        IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
      <div className="row">
        <div className="col padd-0"></div>
        <div className="col padright flexdisplayer pr-3">
          <div className="fulleriger d-flex align-items-center"> 
            
            <button
              onClick={AddBtn}
              type="submit"
              className="btn btn-primary btnColor m-btn m-btn--icon m-btn--air"
            >
                    <i class="la la-icon-font-size-13 la-plus"></i>
              <span> 
                <span>Add</span>
              </span>
            </button>
          </div>
          <div className="fulleriger d-flex align-items-center mx-1">
            <button
              onClick={importcsv}
              type="submit"
              className="btn btn-primary btnColor m-btn m-btn--icon m-btn--air"
            >
              <span>
              <i className="la la-icon-font-size-13 la-upload"></i>
                <span>Import</span>
              </span>
            </button>
          </div>
        </div>
      </div>

    {/* import csv */}
      {opencsv ? (
        <div
          className="border-bottom portletbody px-0 border border-bottom-0 mt-0"
          id="import_csv"
        >
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <h3 className="uploadheadingtitle">
                Import SDR Assignment Details
              </h3>
              <span class="m-alert alert-paragraph">
                <i class="la la-info-circle"></i> A sample file is available for
                download,
                <CSVDownloader
                 data={SampleExportData}
                  filename={"Sample"}
                  bom={true}
                >
                  <a> please click here</a>
                </CSVDownloader>
              </span>
              <div className="uplodfilesbox">
                <CSVReader
                  accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                  onDrop={handleOnDrop}
                  onError={handleOnError}
                  addRemoveButton
                  removeButtonColor="#659cef"
                  onRemoveFile={handleOnRemoveFile}
                  isReset={reset}
                >
                  <span>Select .CSV file</span>
                  <p>Add SDR assignment data from csv file to bulk upload.</p>
                </CSVReader>
              </div>
            </div>
            <div className="col-xl-8 offset-xl-2 pt-3">
              <div className="alignright">
                <a onClick={NumberMapCsv} className="btn btn-primary btnColor">
                  <i className="la la-upload"></i> Upload
                </a>
                <a onClick={canclecsv} className="btn btn-secondary">
                  <i className="la flaticon-cancel"></i> Cancel
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div
        className="portletbody p-0 my-0 bg-white"
        style={{ display: "none" }}
        id="map_attribute"
      >
        <div className="row col-xs-12 border-bottom mx-0 mb-3 py-2">
          <h4 className="headertitle xs-headertitle float-left pl-0 mb-0">
            Map attributes
          </h4>
        </div>
        <div className="row mx-0" id="divContactSelection">
          <div className="col-lg-6 form-group m-form__group row mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                >
                  <option selected="" value="ClientName">
                  Client Name
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  disabled={MapAttrBox}
                  id="ClientName"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ClientName == null
                      ? DropBoxData.findIndex(
                          (item) =>
                            item.toLowerCase() === "clientname" ||
                            item.toLowerCase() === "client name" ||
                            item.toLowerCase() === "client"
                        )
                      : ClientName
                  }
                >
                  <option value="">Not Mapped</option>
                  {DropBoxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-6 form-group m-form__group row mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                >
                  <option selected="" value="SdrName">
                  SDR Name
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  disabled={MapAttrBox}
                  id="SdrName"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    SdrName == null
                      ? DropBoxData.findIndex(
                          (item) =>
                            item.toLowerCase() === "sdrname" ||
                            item.toLowerCase() === "sdr name" ||
                            item.toLowerCase() === "sdr"
                        )
                      : SdrName
                  }
                >
                  <option value="">Not Mapped</option>
                  {DropBoxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-6 form-group m-form__group row mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                >
                  <option selected="" value="PhoneNumber1">
                  Phone Number1
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  disabled={MapAttrBox}
                  id="PhoneNumber1"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    PhoneNumber1 == null
                      ? DropBoxData.findIndex(
                          (item) =>
                            item.toLowerCase() === "phonenumber1" ||
                            item.toLowerCase() === "phone number1"
                        )
                      : PhoneNumber1
                  }
                >
                  <option value="">Not Mapped</option>
                  {DropBoxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-6 form-group m-form__group row mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                >
                  <option selected="" value="PhoneNumber2">
                  Phone Number2
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  disabled={MapAttrBox}
                  id="PhoneNumber2"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    PhoneNumber2 == null
                      ? DropBoxData.findIndex(
                          (item) =>
                            item.toLowerCase() === "phonenumber2" ||
                            item.toLowerCase() === "phone number2"
                        )
                      : PhoneNumber2
                  }
                >
                  <option value="">Not Mapped</option>
                  {DropBoxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-6 form-group m-form__group row mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                >
                  <option selected="" value="PhoneNumber3">
                  Phone Number3
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  disabled={MapAttrBox}
                  id="PhoneNumber3"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    PhoneNumber3 == null
                      ? DropBoxData.findIndex(
                          (item) =>
                            item.toLowerCase() === "phonenumber3" ||
                            item.toLowerCase() === "phone number3"
                        )
                      : PhoneNumber3
                  }
                >
                  <option value="">Not Mapped</option>
                  {DropBoxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-6 form-group m-form__group row mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                >
                  <option selected="" value="EmailAccount">
                  Email Account
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  disabled={MapAttrBox}
                  id="EmailAccount"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    EmailAccount == null
                      ? DropBoxData.findIndex(
                          (item) =>
                            item.toLowerCase() === "emailaccount" ||
                            item.toLowerCase() === "email account"
                        )
                      : EmailAccount
                  }
                >
                  <option value="">Not Mapped</option>
                  {DropBoxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-12 py-3 px-3 border-top">
            <button
              onClick={Savecsv}
              disabled={MapAttrBox}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Save
            </button>
          </div>
        </div>
      </div>

      {TotalDisplayCount ? (
        <div
          className="portletbody my-0 bg-white border-bottom-0 px-2 pt-4 pb-3"
          id=" "
        >
          <div className="row col-xs-12 mx-0">
            <h4 className="headertitle xs-headertitle float-left mb-0 p-0 pb-4">
              Results
            </h4>
          </div>

          <div className="col-xs-12 mx-0">
            <div className="row colfive mx-0">
              <div className="col-md-3 mb-3">
                <div className="boxcardcounter">
                  <div className="d-flex align-center">
                    <div className="contectboxes w-100">
                      <div className="line-title mb-2 text-center d-block">
                        <span className="box-main-title font-primary">
                          {TotalResult}
                        </span>
                      </div>
                      <div className="xs-headertitle box-inner-title">
                        <string className="clr-sky">Total</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 mb-3">
                <div className="boxcardcounter">
                  <div className="d-flex align-center">
                    <div className="contectboxes w-100">
                      <div className="line-title mb-2 text-center d-block">
                        <span className="box-main-title font-success">
                          {TotalSuccess}
                        </span>
                      </div>
                      <div className="xs-headertitle box-inner-title">
                        <string className="clr-sky">Total success</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 mb-3">
                <div className="boxcardcounter">
                  <div className="d-flex align-center">
                    <div className="contectboxes w-100">
                      <div className="line-title mb-2 text-center d-block">
                        <span className="box-main-title font-danger">
                          {TotalError}
                        </span>
                      </div>
                      <div className="xs-headertitle box-inner-title">
                        <string className="clr-sky">Total error</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 mb-3">
                <div className="boxcardcounter">
                  <div className="d-flex align-center">
                    <div className="contectboxes w-100">
                      <div className="line-title mb-2 text-center d-block">
                        <span className="box-main-title font-warning">
                          {TotalDuplicate}
                        </span>
                      </div>
                      <div className="xs-headertitle box-inner-title">
                        <string className="clr-sky">Total Duplicate</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* import csv */}
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option key={value} value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search {" "} 
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
            <StyleHeader isDataLoaded={isDataLoaded} />

          <Table className="table-ref"  aria-label="collapsible table">
            <TableHead>
              <TableRow>
              <TableCell onClick={() => { SortData("ClientName"); setSortedColumn("ClientName");}}>

                  Client Name
                  <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"ClientName"}/>
              </TableCell>
                <TableCell onClick={() => { SortData("ColdCallersName"); setSortedColumn("ColdCallersName");}}>
                    SDR Name
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"ColdCallersName"}/>

                </TableCell>
                {/* <TableCell onClick={() => { SortData("IsTeamLead"); setSortedColumn("IsTeamLead");}}>
                    SDR Manager
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"IsTeamLead"}/>
                </TableCell> */}
                <TableCell onClick={() => { SortData("SalesStrategist"); setSortedColumn("SalesStrategist");}}>
                    Sales Strategist
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"SalesStrategist"}/>
                </TableCell>
                <TableCell onClick={() => { SortData("PhoneNumber1"); setSortedColumn("PhoneNumber1");}}>
                    Phone Number 1
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"PhoneNumber1"}/>
                </TableCell>
                <TableCell onClick={() => { SortData("PhoneNumber2"); setSortedColumn("PhoneNumber2");}}>
                    Phone Number 2
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"PhoneNumber2"}/>
                </TableCell>
                <TableCell onClick={() => { SortData("PhoneNumber3"); setSortedColumn("PhoneNumber3");}}>
                    Phone Number 3
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"PhoneNumber3"}/>
                </TableCell>
                <TableCell onClick={() => { SortData("ReminderEmailAccounts"); setSortedColumn("ReminderEmailAccounts");}}>
                Email Account
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"ReminderEmailAccounts"}/>
                </TableCell>
               
                <TableCell>Action </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            { Rows.length == 0 ? <p className="text-center">No data in Available</p> :
            Rows?.map((row, index) => (
                <TableRow key={row._id}>
                <TableCell>{row?.ClientName}</TableCell>
                <TableCell>{row?.ColdCallersName}</TableCell>
                {/* <TableCell>{row?.IsTeamLead ? "Yes" : "No"}</TableCell> */}
                <TableCell>{row?.SalesStrategist}</TableCell>
                <TableCell>{row?.PhoneNumber1}</TableCell>
                <TableCell>{row?.PhoneNumber2}</TableCell>
                <TableCell>{row?.PhoneNumber3}</TableCell>
                <TableCell>{row?.ReminderEmailAccounts}</TableCell>
                <TableCell>
                  <a
                        title="Edit"
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                    <a
                      onClick={() => { DeleteBtn(row?._id)}}
                      className="btn-eyesicon"
                      title="Delete"
                    >
                      <i className="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      <Pagenations
         Sflag={Sflag}
         CountPage={CountPage}
         HandleChangePage={HandleChangePage}
         Rlen={Rlen}
         Page={Page}
         RowsPerPage={RowsPerPage}
         Flen={Flen}
      />
     
    </div>
    
  );
}
