import React, { useState, useEffect } from 'react';
import Axios from "axios";
import Popup from "reactjs-popup";
import Select from "react-select";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from "@material-ui/lab/Pagination";
import { toast } from "react-toastify";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon23 from "../../../images/loading.gif";
import CreatableSelect from 'react-select/creatable';
export default function AccountAllTable({ contactFlag, CCampaignID, onData ,sendDataToParent,Nextbutton,ButtonStatus}) {
  const [Flen, SetFlen] = useState(0);
  const [Sflag, SetSflag] = useState(false);
  const [Rlen, SetRlen] = useState(0);
  const [CountPage, SetCountPage] = useState(0);
  const [Rows, SetRows] = useState([]);
  const [Page, SetPage] = useState(1);
  const [RowsPerPage, SetRowsPerPage] = useState(15);
  const [Search, SetSearch] = useState("");
  const [SortField, SetSortField] = useState("Name");
  const [SortValue, SetSortValue] = useState(1);
  const [ClientID, SetClientID] = useState(0);
  const [UserID, SetUserID] = useState(0);
  const [Role, SetRole] = useState("");
  const [EditPopup, SetEditPopup] = useState(false);
  const [ContactSourceData, SetContactSourceData] = useState([]);
  const [ClientPOCData, SetClientPOCData] = useState([]);
  const [ContactOwnerData, SetContactOwnerData] = useState([]);
  const [ReasonUnqualifiedData, SetReasonUnqualifiedData] = useState([]);
  const [ContactTagData, SetContactTagData] = useState([]);
  const [ContactSalesDevlopMenData, SetContactSalesDevlopMenData] = useState([]);
  const [ContactCustomFieldData, SetContactCustomFieldData] = useState([]);
  const [ContactStatusData, SetContactStatusData] = useState([]);
  const [ContactSourceDefaultSelect, SetContactSourceDefaultSelect] = useState(null);
  const [ContactTagDefaultSelect, SetContactTagDefaultSelect] = useState([]);
  const [ClientPOCDefaultSelect, SetClientPOCDefaultSelect] = useState(null);
  const [ContactOwnerDefaultSelect, SetContactOwnerDefaultSelect] = useState(null);
  const [ReasonDefaultSelect, SetReasonDefaultSelect] = useState(null);
  const [IsDoNotCallContactData, SetIsDoNotCallContactData] = useState(null);
  const [contactoptions, Setcontactoptions] = useState([]);
  const [defaultTagArray, SetdefaultTagArray] = useState([]);
  const [TagArray, SetTagArray] = useState([]);
  const [errors, Seterrors] = useState({});
  const [Fields, SetFields] = useState({});
  const [Errors, SetErrors] = useState({});
  const [IsInValidWebsite, SetIsInValidWebsite] = useState(false);
  const [IsValidEmail, SetIsValidEmail] = useState(false);
  const [ProspectID, SetProspectID] = useState("")
  const [DeleteArray, SetDeleteArray] = React.useState([]);
  const [IsLoadingContact, SetIsLoadingContact] = React.useState(true);
  const [CUserID,SetCUserID] = React.useState(0)
  const [ContactSalesDevlopmentRepsSelect,SetContactSalesDevlopmentRepsSelect] = useState(null);
  const [prospectAllSelectData, setProspectAllSelectData] = React.useState([]);
  const [IsBtnUpdate, SetIsBtnUpdate] = React.useState(false);
  
  const [TableRowLength, SetTableRowLength] = React.useState(null)
  const [CheckState, SetCheckState] = React.useState(false);
  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetCampaignContact(Details.ClientID, Details.ParentUserID, Details.Role);
    ContactGetData(Details.ClientID, Details.ParentUserID)
  }, [Search, Page, RowsPerPage, CCampaignID, contactFlag]);

  const GetCampaignContact = async (CID, UID, URole) => {
    
    if(CCampaignID){
    SetIsLoadingContact(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortValue,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: CCampaignID
    };
    const GetConfigEmailAccount = await Axios({
      url: CommonConstants.MOL_APIURL + "/prospect_campaign/GetProspectCampaign",
      method: "POST",
      data: InputParameter,
    });


    if (GetConfigEmailAccount.data.StatusMessage == "SUCCESS") {

      SetRows(GetConfigEmailAccount.data.PageData);
      SetRlen(GetConfigEmailAccount.data.TotalCount);
      SetFlen(GetConfigEmailAccount.data.TotalCount);
      SetCountPage(GetConfigEmailAccount.data.PageCount);
      if(GetConfigEmailAccount.data.PageData.length>0){
        Nextbutton({...ButtonStatus,"thirdstep":true})
      }else{
        Nextbutton({...ButtonStatus,"thirdstep":false})
      }

    } else {

      SetIsLoadingContact(false)
    }
    SetIsLoadingContact(false)
  }
  }

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };


  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  // edit popup
  const EditBtn = (id) => {
    SetIsLoadingContact(true)
    SetEditPopup(true)
    SetProspectID(id)
    var InputParaMeter = {
      ProspectID: id
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/prospect_campaign/ProspectCampaignGetByID",
      method: "POST",
      data: InputParaMeter,
    }).then((result) => {

      let defualtoptionsvalue = []
      result.data.Data[0]?.Tagname.map((val) => {
        if (val.TagName == "" || val.ContactTagID == "") {
          return (0)
        } else {
          defualtoptionsvalue.push({ value: val.TagName, label: val.TagName, cid: val.ContactTagID })
        }
      })
      SetdefaultTagArray(defualtoptionsvalue)
      SetTagArray(result.data.Data[0]?.Tagname)
      document.getElementById("FirstName").value = result.data.Data[0].FirstName || "";
      document.getElementById("LastName").value = result.data.Data[0].LastName || "";
      document.getElementById("Email").value = result.data.Data[0]?.Email || "";
      document.getElementById("Title").value = result.data.Data[0]?.Title || "";
      document.getElementById("CompanyNameLong").value =
        result.data.Data[0]?.CompanyNameLong || "";
      document.getElementById("Company").value = result.data.Data[0]?.Company || "";
      document.getElementById("Website").value = result.data.Data[0]?.Website || "";
      document.getElementById("LinkedInURL").value =
        result.data.Data[0]?.LinkedInURL || "";
      document.getElementById("Industry").value =
        result.data.Data[0]?.Industry || "";
      document.getElementById("Address1").value =
        result.data.Data[0]?.Address1 || "";
      document.getElementById("Address2").value =
        result.data.Data[0]?.Address2 || "";
      document.getElementById("City").value = result.data.Data[0]?.City || "";
      document.getElementById("State").value = result.data.Data[0]?.State || "";
      document.getElementById("Zip").value = result.data.Data[0]?.Zip || "";
      document.getElementById("Country").value = result.data.Data[0]?.Country || "";
      document.getElementById("ContactCategory").value =
        result.data.Data[0]?.ContactCategory || "";
      document.getElementById("AccountCategory").value =
        result.data.Data[0]?.AccountCategory || "";
      document.getElementById("MobilePhone").value =
        result.data.Data[0]?.MobilePhone || "";
      document.getElementById("DirectPhone").value =
        result.data.Data[0]?.DirectPhone || "";
      document.getElementById("DirectPhoneExt").value =
        result.data.Data[0]?.DirectPhoneExt || "";
      document.getElementById("CompanyPhone").value =
        result.data.Data[0]?.CompanyPhone || "";
      document.getElementById("OtherPhone1").value =
        result.data.Data[0]?.OtherPhone1 || "";
      document.getElementById("OtherPhone2").value =
        result.data.Data[0]?.OtherPhone2 || "";
      document.getElementById("ExternalAccountID").value =
        result.data.Data[0]?.ExternalAccountID || "";
      document.getElementById("ExternalContactID").value =
        result.data.Data[0]?.ExternalContactID || "";
      document.getElementById("ExternalField1").value =
        result.data.Data[0]?.ExternalField1 || "";
      document.getElementById("ExternalField2").value =
        result.data.Data[0]?.ExternalField2 || "";
      document.getElementById("ExternalField3").value =
        result.data.Data[0]?.ExternalField3 || "";
      document.getElementById("IsDoNotCallContact").value =
        result.data.Data[0]?.IsDoNotCallContact || "";
        document.getElementById("CompanyRevenue").value =
        result.data.Data[0]?.Accountname?.CompanyRevenue == null ? "" : result.data.Data[0]?.Accountname?.CompanyRevenue || "";

        document.getElementById("CompanyEmployeeCount").value =
        result.data.Data[0]?.Accountname?.CompanyEmployeeCount == null ? "" : result.data.Data[0]?.Accountname?.CompanyEmployeeCount || "";

        document.getElementById("SalesRep").value =
        result.data.Data[0]?.SalesRep || "";
        document.getElementById("ZoomID").value =
        result.data.Data[0]?.ZoomID || "";
        document.getElementById("BestPhone").value =
        result.data.Data[0]?.BestPhone || "";
        document.getElementById("BestPhoneStatus").value =
        result.data.Data[0]?.BestPhoneStatus || "";
        document.getElementById("Phone").value =
        result.data.Data[0]?.Phone1 || "";
        document.getElementById("PhoneStatus").value =
        result.data.Data[0]?.Phone1Status || "";
        document.getElementById("Phone2").value =
        result.data.Data[0]?.Phone2 || "";
        document.getElementById("Phone2Status").value =
        result.data.Data[0]?.Phone2Status || "";
        document.getElementById("Phone3").value =
        result.data.Data[0]?.Phone3 || "";
        document.getElementById("Phone3Status").value =
        result.data.Data[0]?.Phone3Status || "";
        document.getElementById("Phone4").value =
        result.data.Data[0]?.Phone4 || "";
        document.getElementById("Phone4Status").value =
        result.data.Data[0]?.Phone4Status || "";
        document.getElementById("PRLLeadID").value =
        result.data.Data[0]?.PRLLeadID || "";
        SetContactSourceDefaultSelect(result.data.Data[0]?.ContactSourceID);
        SetReasonDefaultSelect(result.data.Data[0]?.ReasonUnqualifiedID);
        SetClientPOCDefaultSelect(result.data.Data[0]?.ClientPOCID);
        SetContactOwnerDefaultSelect(result.data.Data[0]?.ContactOwnerID);
        SetContactSalesDevlopmentRepsSelect(result.data.Data[0]?.ColdCallersID)
        SetIsLoadingContact(false)
    });
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CampaignID: CCampaignID,
        Search: SearchedVal
      };

      const CampaignContactList = Axios({
        url: CommonConstants.MOL_APIURL + "/prospect_campaign/GetProspectCampaign",
        method: "POST",
        data: InputParameter,
      });
      CampaignContactList.then((Result) => {

        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CampaignID: CCampaignID,
        Search: SearchedVal
      };

      const CampaignContactList = Axios({
        url: CommonConstants.MOL_APIURL + "/prospect_campaign/GetProspectCampaign",
        method: "POST",
        data: InputParameter,
      });
      CampaignContactList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    }
  };

  // Contact info update data
  const ContactGetData = (CID, UID) => {
    SetIsLoadingContact(true)
    var str_in = {
      ClientID: CID,
      UserID: UID
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactData",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
      // // multiple contact sele
      let contactoptions = result.data?.ContactTagData.map((val) => {
        return { value: val.Tag, label: val.Tag, cid: val._id };
      });

      Setcontactoptions(contactoptions)
      SetContactSourceData(result.data?.ContactSourceData || [])
      SetClientPOCData(result.data?.ClientPOCData  || [])
      SetReasonUnqualifiedData(result.data?.ReasonUnqualifiedData || [])
      SetContactTagData(result.data?.ContactTagData || [])
      SetContactOwnerData(result.data?.ContactOwnerData || [])
      SetContactSalesDevlopMenData(result.data?.SalesReplyDevlopmentData || [])
      SetContactCustomFieldData(result.data?.ContactCustomFieldData || [])
      SetContactStatusData(result.data?.ProspectStatusData || [])
    });
    SetIsLoadingContact(false)
  }

  // handle dropdown change contact source
  const HandleDropdownChangeContactSource = (e) => {
    SetContactSourceDefaultSelect(e.target.ContactSourceDefaultSelect)

  }


  // handle dropdown change contact owner
  const HandleDropdownChangeContactOwner = (e) => {
    SetContactOwnerDefaultSelect(e.target.ContactOwnerDefaultSelect)

  }
  // handle dropdown change client
  const HandleDropdownChangeClientPOC = (e) => {
    SetClientPOCDefaultSelect(e.target.ClientPOCDefaultSelect)
  }
  // handle dropdown change contac reason
  const HandleDropdownChangeReasonUnqualified = (e) => {
    SetReasonDefaultSelect(e.target.ReasonDefaultSelect)
  }
  // checkbox
  const HandleChangeCheckIsDoNotCall = (e) => {
    const { checked } = e.target;
    SetIsDoNotCallContactData(checked)
  };
  // handle change
  const h2Change = (e) => {
    SetdefaultTagArray(e)
  };
       // handle dropdown change contact source
       const HandleDropdownChangeSalesDevlopmentReps = (e) => {
        SetContactSalesDevlopmentRepsSelect(e.target.ContactSalesDevlopmentRepsSelect)
      }
  const FromValidation = async () => {
    let formIsValid = true;
    let errors = {};

    var FirstName = document.getElementById("FirstName").value.trim();
    var Email = document.getElementById("Email").value.trim();
    var Website = document.getElementById("Website").value.trim();
    let IsExistWebsite = await CheckWebSite(Website);
    let IsExistEmail = await EmailValidationCheck(Email);


    if (FirstName == "") {
      formIsValid = false;
      toast.error("Please enter first name")
      SetIsLoadingContact(false)
    }

    if (Website == "" && FirstName != "") {
      formIsValid = false;
      toast.error("Please enter Website")
      SetIsLoadingContact(false)
    }
    if (Email == "" && Website.length < 0 && FirstName != "") {
      formIsValid = false;
      toast.error("Please enter email")
      SetIsLoadingContact(false)
    }
    if (IsExistWebsite == true) {
      formIsValid = false;
      errors["Website"] = "Invalid website!";
      toast.error("Please enter First name, Last name and Company OR First name and Email")
      SetIsLoadingContact(false)
    }
    if (IsExistEmail == true) {
      formIsValid = false;

      toast.error("Invalid email!")
      SetIsLoadingContact(false)
    }

    let MobilePhone = document.getElementById("MobilePhone").value.trim(); 
    // Check if the value is not numeric or is negative
     if (MobilePhone !== "" && !/^[0-9]+$/.test(MobilePhone)) {
     formIsValid=false;
     errors["MobilePhone"] = "Invalid MobilePhone!";
     
     } else {
   
     errors["MobilePhone"] = null; // Reset the error if the value is valid or empty
     }
   
     let OtherPhone1 = document.getElementById("OtherPhone1").value.trim(); 
     // Check if the value is not numeric or is negative
      if (OtherPhone1 !== "" && !/^[0-9]+$/.test(OtherPhone1)) {
      formIsValid=false;
      errors["OtherPhone1"] = "Invalid OtherPhone1!";
      
      } else {
      errors["OtherPhone1"] = null; // Reset the error if the value is valid or empty
      }
   
      let OtherPhone2 = document.getElementById("OtherPhone2").value.trim(); 
      // Check if the value is not numeric or is negative
       if (OtherPhone2 !== "" && !/^[0-9]+$/.test(OtherPhone2)) {
       formIsValid=false;
       errors["OtherPhone2"] = "Invalid OtherPhone2!";
       
       } else {
       errors["OtherPhone2"] = null; // Reset the error if the value is valid or empty
       }
   
       let BestPhone = document.getElementById("BestPhone").value.trim(); 
       // Check if the value is not numeric or is negative
        if (BestPhone !== "" && !/^[0-9]+$/.test(BestPhone)) {
        formIsValid=false;
        errors["BestPhone"] = "Invalid BestPhone!";
        
        } else {
        errors["BestPhone"] = null; // Reset the error if the value is valid or empty
        }
   
   
        let Phone = document.getElementById("Phone").value.trim(); 
        // Check if the value is not numeric or is negative
         if (Phone !== "" && !/^[0-9]+$/.test(Phone)) {
         formIsValid=false;
         errors["Phone"] = "Invalid Phone!";
         
         } else {
         errors["Phone"] = null; // Reset the error if the value is valid or empty
         }
   
         let Phone2 = document.getElementById("Phone2").value.trim(); 
         // Check if the value is not numeric or is negative
          if (Phone2 !== "" && !/^[0-9]+$/.test(Phone2)) {
          formIsValid=false;
          errors["Phone2"] = "Invalid Phone2!";
          
          } else {
   
          errors["Phone2"] = null; // Reset the error if the value is valid or empty
          }
   
          let Phone3 = document.getElementById("Phone3").value.trim(); 
          // Check if the value is not numeric or is negative
           if (Phone3 !== "" && !/^[0-9]+$/.test(Phone3)) {
           formIsValid=false;
           errors["Phone3"] = "Invalid Phone3!";
           
           } else {
   
           errors["Phone3"] = null; // Reset the error if the value is valid or empty
           }
   
           let Phone4 = document.getElementById("Phone4").value.trim(); 
           // Check if the value is not numeric or is negative
            if (Phone4 !== "" && !/^[0-9]+$/.test(Phone4)) {
            formIsValid=false;
            errors["Phone4"] = "Invalid Phone4!";
            
            } else {
   
            errors["Phone4"] = null; // Reset the error if the value is valid or empty
            }
   
            let DirectPhoneExt = document.getElementById("DirectPhoneExt").value.trim(); 
            // Check if the value is not numeric or is negative
             if (DirectPhoneExt !== "" && !/^[0-9]+$/.test(DirectPhoneExt)) {
             formIsValid=false;
             errors["DirectPhoneExt"] = "Invalid DirectPhoneExt!";
             
             } else {  
   
             errors["DirectPhoneExt"] = null; // Reset the error if the value is valid or empty
             }
   
             let CompanyPhone = document.getElementById("CompanyPhone").value.trim(); 
             // Check if the value is not numeric or is negative
              if (CompanyPhone !== "" && !/^[0-9]+$/.test(CompanyPhone)) {
              formIsValid=false;
              errors["CompanyPhone"] = "Invalid CompanyPhone!";
              
              } else {
   
              errors["CompanyPhone"] = null; // Reset the error if the value is valid or empty
              }
   
              let DirectPhone = document.getElementById("DirectPhone").value.trim(); 
              // Check if the value is not numeric or is negative
               if (DirectPhone !== "" && !/^[0-9]+$/.test(DirectPhone)) {
               formIsValid=false;
               errors["DirectPhone"] = "Invalid DirectPhone!";
               
               } else {
   
               errors["DirectPhone"] = null; // Reset the error if the value is valid or empty
               }
   
               let ZoomID = document.getElementById("ZoomID").value.trim(); 
     // Check if the value is negative
     if (ZoomID !== "" && parseInt(ZoomID) < 0) {
       formIsValid=false;
       errors["ZoomID"] = "Invalid ZoomID!";
       
     } else {
   
       errors["ZoomID"] = null; // Reset the error if the value is valid or empty
   
     }

    Seterrors(errors)
    // this.setState({ errors: errors });
    return formIsValid;
  }

  
  const handleChangePhoneValidation = (fieldName) => {
    const value = document.getElementById(fieldName).value.trim();
    let updatedErrors = { ...errors }; // Copy the current state of errors

    // Check if the value is not numeric or is negative
    if (value !== "" && !/^[0-9]+$/.test(value)) {
      updatedErrors[fieldName] = "Invalid " + fieldName + "!";
    } else {
      updatedErrors[fieldName] = null; // Reset the error if the value is valid or empty
    }

    // Update the state with the new errors
    Seterrors(updatedErrors);
  };

const hadleChangeZoomID =(fieldName) => {
  const value = document.getElementById(fieldName).value.trim();
  let updatedErrors = { ...errors }; // Copy the current state of errors

  // Check if the value is negative
  if (value !== "" && parseInt(value) < 0) {
    updatedErrors[fieldName] = "Invalid " + fieldName + "!";
  } else {
    updatedErrors[fieldName] = null; // Reset the error if the value is valid or empty

  }
   // Update the state with the new errors
   Seterrors(updatedErrors);
}
  // check website name
  const CheckWebSite = async (Email) => {
    let Errors = {};
    var regex = new RegExp(
      "^(?!(https?://)?www\\.)" + // Ensure it does not start with https://www.
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );

    if (!regex.test(Email) && Email != "") {

      Errors["Website"] = "Invalid website";
      Seterrors(Errors)
      SetIsInValidWebsite(true)

      return true
    } else {

      return false;
    }
  }
  // update data
  const ContactUpdate = async () => {
    var FormValidationFlag = await FromValidation();
    if (FormValidationFlag === true) {
      SetIsBtnUpdate(true)
      SetIsLoadingContact(true)
      var FinalTagArray = []
      defaultTagArray.map((item) => {
        FinalTagArray.push({
          ContactTagID: item.cid,
          TagName: item.label
        })
      })
      var FirstName = document.getElementById("FirstName").value;
      var LastName = document.getElementById("LastName").value;
      var Email = document.getElementById("Email").value;
      var Title = document.getElementById("Title").value;
      var CompanyNameLong = document.getElementById("CompanyNameLong").value;
      var Company = document.getElementById("Company").value;
      var Website = document.getElementById("Website").value.trim();
      var LinkedInURL = document.getElementById("LinkedInURL").value;
      var Industry = document.getElementById("Industry").value;
      var Address1 = document.getElementById("Address1").value;
      var Address2 = document.getElementById("Address2").value;
      var City = document.getElementById("City").value;
      var State = document.getElementById("State").value;
      var Zip = document.getElementById("Zip").value;
      var Country = document.getElementById("Country").value;
      var ContactCategory = document.getElementById("ContactCategory").value;
      var AccountCategory = document.getElementById("AccountCategory").value;
      var MobilePhone = document.getElementById("MobilePhone").value;
      var DirectPhone = document.getElementById("DirectPhone").value;
      var DirectPhoneExt = document.getElementById("DirectPhoneExt").value;
      var CompanyPhone = document.getElementById("CompanyPhone").value;
      var SalesRep = document.getElementById("SalesRep").value;
      var OtherPhone1 = document.getElementById("OtherPhone1").value;
      var OtherPhone2 = document.getElementById("OtherPhone2").value;
      var ExternalAccountID = document.getElementById("ExternalAccountID").value;
      var ExternalContactID = document.getElementById("ExternalContactID").value;
      var ExternalField1 = document.getElementById("ExternalField1").value;
      var ExternalField2 = document.getElementById("ExternalField2").value;
      var ExternalField3 = document.getElementById("ExternalField3").value;
      var ContactSourceID = document.getElementById("ContactSourceID").value;
      var ReasonUnqualifiedID = document.getElementById(
        "ReasonUnqualifiedID"
      ).value;
      var ClientPOCID = document.getElementById("ClientPOCID").value;
      var ContactOwnerID = document.getElementById("ContactOwnerID").value;
      var IsDoNotCallContact =
        document.getElementById("IsDoNotCallContact").checked;
      var ColdCallerID = document.getElementById("ColdCallersID").value;
      var CompanyRevenue = document.getElementById("CompanyRevenue").value.trim();
      var CompanyEmployeeCount = document.getElementById("CompanyEmployeeCount").value.trim();
      var ZoomID = document.getElementById("ZoomID").value;
    var BestPhone = document.getElementById("BestPhone").value;
    var BestPhoneStatus = document.getElementById("BestPhoneStatus").value;
    var Phone = document.getElementById("Phone").value;
    var PhoneStatus = document.getElementById("PhoneStatus").value;
    var Phone2 = document.getElementById("Phone2").value;
    var Phone2Status = document.getElementById("Phone2Status").value;
    var Phone3 = document.getElementById("Phone3").value;
    var Phone3Status = document.getElementById("Phone3Status").value;
    var Phone4 = document.getElementById("Phone4").value;
    var Phone4Status = document.getElementById("Phone4Status").value;
    var PRLLeadID = document.getElementById("PRLLeadID").value;
    var CompanyEmployeeCountDecimal = document.getElementById(
      "CompanyEmployeeCount"
    ).value.trim();
          // Check if the trimmed value is not empty and is a valid number
          if (CompanyEmployeeCount !== "" && !isNaN(CompanyEmployeeCount)) {
            // If the value is a decimal, assign 0
            CompanyEmployeeCount = CompanyEmployeeCount.includes(".") ? 0 : parseFloat(CompanyEmployeeCount);
      } else {
            // If the value is empty or not a valid number, assign 0
            CompanyEmployeeCount = 0;
      }

    
      let revenueParts = CompanyRevenue.split("."); // Split the string into integer and decimal parts
      
      if (revenueParts.length === 2) { // Ensure there's a decimal part
          let decimalPart = revenueParts[1]; // Get the decimal part
          if (decimalPart.length >= 3) { // Ensure there are at least three digits after the decimal point
              let thirdDigit = parseInt(decimalPart[2]); // Get the third digit after the decimal point
              let secondDigit = parseInt(decimalPart[1]); // Get the second digit after the decimal point
      
              if (thirdDigit >= 5) { // If the third digit is greater than or equal to 5
                  secondDigit += 1; // Increment the second digit
              }
      
              // Reconstruct the revenue string with the adjusted second digit
              CompanyRevenue = revenueParts[0] + "." + decimalPart[0] + secondDigit;
          }
      }
      var  trimEmail;
      if(Email == "" || undefined){
        trimEmail = Email
      }else{
        trimEmail = Email.trim()
      }

      var ContactUpdateData = {
        _id: ProspectID,
        FirstName: FirstName,
        LastName: LastName,
        Email: trimEmail,
        Title: Title,
        CompanyNameLong: CompanyNameLong,
        Company: Company,
        Website: Website,
        LinkedInURL: LinkedInURL,
        Industry: Industry,
        Address1: Address1,
        Address2: Address2,
        City: City,
        State: State,
        Zip: Zip,
        Country: Country,
        ContactCategory: ContactCategory,
        AccountCategory: AccountCategory,
        MobilePhone: MobilePhone,
        DirectPhone: DirectPhone,
        DirectPhoneExt: DirectPhoneExt,
        CompanyPhone: CompanyPhone,
        CompanyRevenue: CompanyRevenue,
        CompanyEmployeeCount:CompanyEmployeeCount,
        SalesRep: SalesRep,
        OtherPhone1: OtherPhone1,
        OtherPhone2: OtherPhone2,
        ExternalAccountID: ExternalAccountID,
        ExternalContactID: ExternalContactID,
        ExternalField1: ExternalField1,
        ExternalField2: ExternalField2,
        ExternalField3: ExternalField3,
        ContactSourceID: ContactSourceID,
        ReasonUnqualifiedID: ReasonUnqualifiedID,
        ClientPOCID: ClientPOCID,
        ContactOwnerID: ContactOwnerID,
        Tagname: FinalTagArray,
        IsDoNotCallContact: IsDoNotCallContact,
        LastUpdatedBy: CUserID,
        LastUpdatedDate: new Date(),
        ClientID: ClientID,
        ColdCallerID:ColdCallerID,
        UserID:UserID,
        ZoomID :ZoomID,
        BestPhone :BestPhone,
        BestPhoneStatus :BestPhoneStatus,
        Phone :Phone,
        PhoneStatus :PhoneStatus,
        Phone2 :Phone2,
        Phone2Status :Phone2Status,
        Phone3 :Phone3,
        Phone3Status :Phone3Status,
        Phone4 :Phone4,
        Phone4Status :Phone4Status,
        PRLLeadID :PRLLeadID,
        CompanyEmployeeCountDecimal:CompanyEmployeeCountDecimal
      };
      // SetIsLoadingContact(false)
      // SetIsBtnUpdate(false)

      Axios({
        url: CommonConstants.MOL_APIURL + "/prospect_campaign/ProspectCampaignUpdate",
        method: "POST",
        data: ContactUpdateData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success("Contact update successfully.", "Update  contact ");
          SetIsBtnUpdate(false)
          SetEditPopup(false)
          GetCampaignContact(ClientID,UserID,Role)
          SetIsLoadingContact(false)
        } else {
          toast.error(res.data.Message);
          SetIsLoadingContact(false)
          SetIsBtnUpdate(false)
        }
      });
    }
    else {
      SetIsLoadingContact(false)
      SetIsBtnUpdate(false)
    }


  }

  // Handle Change
  const HandleChange = () => {
    var Website = document.getElementById("Website").value.trim();
    if (Website != "") {
      Errors.Website = null;
    } else {
      Errors.Website = null;
    }
  }

  // cheack email valid
  const EmailValidationCheck = async (Email) => {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let Errors = {};
    if (!regexp.test(Email) && Email != "") {
      Errors["Email"] = "invalid email.";
      SetIsValidEmail(true)
      Seterrors(Errors)
      return true;
    } else {
      SetIsValidEmail(false)

      return false;
    }
  };

  // when check box update data
  const CheckBoxClick = (e, row) => {
    // ShowAndHideDomain();
    if (e.target.checked) {
      var temArray = DeleteArray;
      temArray.push(row);
      SetDeleteArray(temArray)
      onData(DeleteArray)
    } else if (!e.target.checked) {
      var temArray = DeleteArray;
      DeleteArray.splice(row, 1)
      // SetDeleteArray(temArray)
      onData(DeleteArray)
    }

  };

  const SelectCheckHandler = async () => {
    try {
      // document.getElementById("hideloding23").style.display = "block"
      
      // Your other logic here...
      document.getElementById("main-check").checked = true
    

      const Data = {
        ClientID: ClientID,
        UserID: UserID,
        CampaignID: CCampaignID,
      };
  
      const result = await Axios.post(
        CommonConstants.MOL_APIURL + "/prospect_campaign/GetProspectCampaignSelectAll",
        Data
      );
      // Update UI elements
      // document.getElementById("UnSelect").style.display = "block";
      // document.getElementById("Select-All").style.display = "none";
      // document.getElementById("hideloding23").style.display = "none"
      setProspectAllSelectData(result.data?.PageData);
      sendDataToParent(result.data?.PageData)
    } catch (error) {

      // document.getElementById("hideloding23").style.display = "none"
    }
  };
    // all check handler
const CheckHandler = (e) => {
  var countss
  if (e.target.checked) {
    // Select all rows
    const selectedRows = Rows.filter((val) => !prospectAllSelectData.some((item) => item._id === val._id));
    const updatedArray = [...prospectAllSelectData, ...selectedRows];
    countss == selectedRows
    setProspectAllSelectData(updatedArray);
    sendDataToParent(updatedArray)
  
  } else {
    // Select all rows
    const selectedRows = prospectAllSelectData.filter((val) => !Rows.some((item) => item._id === val._id));
    setProspectAllSelectData(selectedRows);
    sendDataToParent(selectedRows)
  }
};
  // all check handler
  const UnSelectCheckHandler = () => {
    // document.getElementById("Select-All").style.display = "block";
    // document.getElementById("UnSelect").style.display = "none";
    document.getElementById("main-check").checked = false;
    setProspectAllSelectData([])
    sendDataToParent([])
  };
  const ProspectCheckHandler = (e, row) => {

    if (e.target.checked) {

      setProspectAllSelectData(prevData => {
        if (!prevData.find(item => item._id === row._id)) {
          const updatedData = [...prevData, row];
          updateLengthAndDisplay(updatedData);
          sendDataToParent(updatedData)
          return updatedData;
        }
        return prevData;
      });

    } else {
      setProspectAllSelectData(prevData => {
        const updatedData = prevData.filter(item => item._id !== row._id);
        sendDataToParent(updatedData)
        updateLengthAndDisplay(updatedData);
        return updatedData;
      });

    }
  };

  const updateLengthAndDisplay = (updatedData) => {
    const updatedLength = updatedData.length;
    var selectcount = TableRowLength

    if ( updatedLength == selectcount) {
      document.getElementById("main-check").checked = true;
      // document.getElementById("Select-All").style.display = "none";
    } else {
      document.getElementById("main-check").checked = false;
      // document.getElementById("Select-All").style.display = "block";
      // document.getElementById("UnSelect").style.display = "none";
    }
  };


  return (
    <div>
      {
        IsLoadingContact ? <div id="hideloding23" className="loding-display">
          <img src={loadingicon23} />
        </div> : null
      }
      <Popup open={EditPopup}>

        <div>
          <div className="modal-black"></div>
          <div className="filterPopup largerPopup">
            <div className="paddingboxTerms">
              <div className="modal-header py-4 px-3">
                <div className="w-100 d-flex px-3">
                  <h5 className="mb-0">Edit Contacts</h5>
                  <button className="close" onClick={() => SetEditPopup(false)}>
                    <span
                      aria-hidden="true"
                      className="la la-remove"
                    ></span>
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className="modal-body">
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div class="form-group m-form__group row">
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">First Name</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" data-val="true" data-val-required="Please enter first name" id="FirstName" name="FirstName" placeholder="First name" type="text" />
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="FirstName" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Last Name</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="LastName" name="LastName" placeholder="Last name" type="text" />
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="LastName" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row">
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Email</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="Email" name="Email" placeholder="Email" type="text" />
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Email" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Title</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="Title" name="Title" placeholder="Title" type="text" />
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Title" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row">
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Company</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="Company" name="Company" placeholder="Company" type="text" />
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Company" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Company Name Long</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="CompanyNameLong" name="CompanyNameLong" placeholder="Company name long" type="text" />
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="CompanyNameLong" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row">
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Website</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input"
                              value={Fields["Website"]}
                              onChange={HandleChange.bind(this)}
                              data-val="true" data-val-regex="Invalid website" data-val-regex-pattern="^(?!www|https|http	)[A-Za-z0-9_-]+\.+[A-Za-z0-9.\/%&amp;=\?_:;-]+$" data-val-required="Please enter website." id="Website" name="Website" placeholder="Website" type="text" />
                            <span style={{ color: "red" }}>
                              {errors["Website"]}
                            </span>
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Website" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Company Phone</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" data-val="true" data-val-regex="Invalid company phone" data-val-regex-pattern="^[0-9]*$" id="CompanyPhone" name="CompanyPhone" placeholder="Company Phone" type="text" onBlur={() => handleChangePhoneValidation("CompanyPhone")} />
                            <span style={{ color: "red" }}>
                        {errors["CompanyPhone"]}
                      </span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row">
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Direct Phone</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" data-val="true" data-val-regex="Invalid direct phone" data-val-regex-pattern="^[0-9]*$" id="DirectPhone" name="DirectPhone" placeholder="Direct Phone" type="text" onBlur={() => handleChangePhoneValidation("DirectPhone")}/>
                            <span style={{ color: "red" }}>
                        {errors["DirectPhone"]}
                      </span>
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Direct Phone Ext</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" data-val="true" data-val-regex="Invalid direct phone ext" data-val-regex-pattern="^[0-9]*$" id="DirectPhoneExt" name="DirectPhoneExt" placeholder="Direct Phone Ext" type="text" onBlur={() => handleChangePhoneValidation("DirectPhoneExt")}/>
                            <span style={{ color: "red" }}>
                        {errors["DirectPhoneExt"]}
                      </span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row">
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">LinkedIn URL</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="LinkedInURL" name="LinkedInURL" placeholder="Linkedin url" type="text" />
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="LinkedInURL" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Mobile Phone</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" data-val="true" data-val-regex="Invalid mobile phone" data-val-regex-pattern="^[0-9]*$" id="MobilePhone" name="MobilePhone" placeholder="Mobile Phone" type="text" onBlur={() => handleChangePhoneValidation("MobilePhone")} />
                            <span style={{ color: "red" }}>
                        {errors["MobilePhone"]}
                      </span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row">

                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Industry</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="Industry" name="Industry" placeholder="Industry" type="text" />
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Industry" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Address 1</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="Address1" name="Address1" placeholder="Address 1" type="text" />
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Address1" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row">

                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Address 2</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="Address2" name="Address2" placeholder="Address 2" type="text" />
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Address2" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">City</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="City" name="City" placeholder="City" type="text" />
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="City" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row">

                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">State</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="State" name="State" placeholder="State" type="text" />
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="State" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Zip</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="Zip" name="Zip" placeholder="Zip" type="text" />
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Zip" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row">

                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Country</label>
                          <div class="col-lg-7">

                            <input class="form-control m-input" data-val="true" data-val-remote="Country Is Global Country Blacklisted." data-val-remote-additionalfields="*.Country,*.UserID,*.CampaignID,*.PageName" data-val-remote-url="/Utility/IsProspectCountryExistsAndBlacklist" id="Country" name="Country" placeholder="Country" type="text" />
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="Country" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Contact Category</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="ContactCategory" name="ContactCategory" placeholder="Contact category" type="text" />
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="ContactCategory" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row">

                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Account Category</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="AccountCategory" name="AccountCategory" placeholder="Account category" type="text" />
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="AccountCategory" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Contact Source</label>
                          <div class="col-lg-7">
                            <select class="form-control  m-input" data-val="true" data-val-number="The field ContactSourceID must be a number." value={ContactSourceDefaultSelect}
                              onChange={(e) =>
                                HandleDropdownChangeContactSource(e)
                              } id="ContactSourceID" name="ContactSourceID">
                              {ContactSourceData.map((value) => (
                                <option value={value._id}>
                                  {value.Name}
                                </option>
                              ))}
                            </select>
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="ContactSourceID" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row removePadding">

                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Contact Owner</label>
                          <div class="col-lg-7">
                            <select class="form-control  m-input" data-val="true" value={ContactOwnerDefaultSelect}
                              onChange={(e) =>

                                HandleDropdownChangeContactOwner(e)
                              } data-val-number="The field ContactOwnerID must be a number." id="ContactOwnerID" name="ContactOwnerID">
                              {ContactOwnerData.map((value) => (
                                <option value={value._id}>
                                  {value.Name}
                                </option>
                              ))}

                            </select>
                            <span class="field-validation-valid errorMessageValidate" data-valmsg-for="ContactOwnerID" data-valmsg-replace="true"></span>
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Client POC</label>
                          <div class="col-lg-7">
                            <select class="form-control  m-input" data-val="true" data-val-number="The field ClientPOCID must be a number." id="ClientPOCID" name="ClientPOCID" value={ClientPOCDefaultSelect}
                              onChange={(e) => HandleDropdownChangeClientPOC(e)}>
                              {ClientPOCData.map((value) => (
                                <option value={value._id}>
                                  {value.Name}
                                </option>
                              ))}

                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row removePadding">

                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Reason Unqualified</label>
                          <div class="col-lg-7">
                            <select class="form-control  m-input" data-val="true" data-val-number="The field ReasonUnqualifiedID must be a number." id="ReasonUnqualifiedID" name="ReasonUnqualifiedID" value={ReasonDefaultSelect}
                              onChange={(e) =>
                                HandleDropdownChangeReasonUnqualified(e)
                              }>
                              {ReasonUnqualifiedData.map((value) => (
                                <option value={value._id}>
                                  {value.Reason}
                                </option>
                              ))}

                            </select>
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Company Revenue</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" data-val="true" data-val-number="The field CompanyRevenue must be a number." id="CompanyRevenue" name="CompanyRevenue" placeholder="Company Revenue" type="number" />
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Employee Count</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" data-val="true" data-val-number="The field CompanyEmployeeCount must be a number." id="CompanyEmployeeCount" min="0" name="CompanyEmployeeCount" placeholder="Employee Count" type="number" />
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Sales Development Reps</label>
                          <div class="col-lg-7">
                            <select class="form-control  m-input" data-val="true" data-val-number="The field ColdCallersID must be a number." id="ColdCallersID" name="ColdCallersID"
                                value={ContactSalesDevlopmentRepsSelect}
                                onChange={(e) =>
                          HandleDropdownChangeSalesDevlopmentReps(e)
                                }
                            >
                                    {ContactSalesDevlopMenData?.map((value) => (
                              <option value={value?._id}>
                              {value?.ColdCallersName} {value?.LastName}
                              </option>
                              ))} 

                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">SalesRep</label>
                          <div class="col-lg-7">

                            <input class="form-control m-input" id="SalesRep" name="SalesRep" placeholder="Sales Rep" type="text" />
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Tag</label>
                          <div class="col-lg-7">

                            <div className="col-lg-7">
                              <CreatableSelect
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                options={contactoptions}
                                isMulti
                                onChange={(e) => h2Change(e)}
                                value={defaultTagArray === null ? (null) : defaultTagArray  }
                              />
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Other Phone 1</label>
                          <div class="col-lg-7">
                          <input class="form-control m-input" id="OtherPhone1" name="OtherPhone1" placeholder="Other Phone 1" type="text"  onBlur={() => handleChangePhoneValidation("OtherPhone1")}/>
                                <span style={{ color: "red" }}>
                        {errors["OtherPhone1"]}
                      </span>
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">Other Phone 2</label>
                          <div class="col-lg-7">
                          <input class="form-control m-input" id="OtherPhone2" name="OtherPhone2" placeholder="Other Phone 2" type="text"  onBlur={() => handleChangePhoneValidation("OtherPhone2")}/>
                                <span style={{ color: "red" }}>
                        {errors["OtherPhone2"]}
                      </span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">External Account ID</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="ExternalAccountID" name="ExternalAccountID" placeholder="External Account ID" type="text" />
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">External Contact ID</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="ExternalContactID" name="ExternalContactID" placeholder="External Contact ID" type="text" />
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">External Field 1</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="ExternalField1" name="ExternalField1" placeholder="External Field 1" type="text" />
                          </div>
                        </div>
                        <div class="col-lg-6 row">
                          <label class="col-lg-5 col-form-label">External Field 2</label>
                          <div class="col-lg-7">
                            <input class="form-control m-input" id="ExternalField2" name="ExternalField2" placeholder="External Field 2" type="text" />
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">External Field 3</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="ExternalField3" name="ExternalField3" placeholder="External Field 3" type="text" />
                            </div>
                        </div>
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Zoom ID :</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="ZoomID" name="ZoomID" placeholder="Zoom ID" type="text" onBlur={() => hadleChangeZoomID("ZoomID")}/>
                                <span style={{ color: "red" }}>
                        {errors["ZoomID"]}
                      </span>
                            </div>
                        </div>
                    </div>
               
                    <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Best Phone :</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="BestPhone" name="BestPhone" placeholder="Best Phone" type="text" onBlur={() => handleChangePhoneValidation("BestPhone")}/>
                                <span style={{ color: "red" }}>
                        {errors["BestPhone"]}
                      </span>
                            </div>
                        </div>

          
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Best Phone Status:</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="BestPhoneStatus" name="BestPhoneStatus" placeholder="Best Phone Status" type="text" />
                            </div>
                        </div>

                    </div>
                    <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Phone :</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="Phone" name="Phone" placeholder="Phone" type="text" onBlur={() => handleChangePhoneValidation("Phone")}/>
                                <span style={{ color: "red" }}>
                        {errors["Phone"]}
                      </span>
                            </div>
                        </div>

          
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Phone Status:</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="PhoneStatus" name="PhoneStatus" placeholder="PhoneStatus" type="text" />
                            </div>
                        </div>

                    </div>
                    <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Phone2 :</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="Phone2" name="Phone2" placeholder="Phone2" type="text" onBlur={() => handleChangePhoneValidation("Phone2")}/>
                                <span style={{ color: "red" }}>
                        {errors["Phone2"]}
                      </span>
                            </div>
                        </div>

 
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Phone2 Status:</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="Phone2Status" name="Phone2Status" placeholder="Phone2Status" type="text" />
                            </div>
                        </div>

                    </div>
                    <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Phone3 :</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="Phone3" name="Phone3" placeholder="Phone3" type="text" onBlur={() => handleChangePhoneValidation("Phone3")}/>
                                <span style={{ color: "red" }}>
                        {errors["Phone3"]}
                      </span>
                            </div>
                        </div>

      
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Phone3 Status:</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="Phone3Status" name="Phone3Status" placeholder="Phone3Status" type="text" />
                            </div>
                        </div>

                    </div>
                    <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Phone4 :</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="Phone4" name="Phone4" placeholder="Phone4" type="text" onBlur={() => handleChangePhoneValidation("Phone4")}/>
                                <span style={{ color: "red" }}>
                        {errors["Phone4"]}
                      </span>
                            </div>
                        </div>

                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">Phone4 Status:</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="Phone4Status" name="Phone4Status" placeholder="Phone4Status" type="text" />
                            </div>
                        </div>

                    </div>
                    <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                            <label class="col-lg-5 col-form-label">External ID :</label>
                            <div class="col-lg-7">
                                <input class="form-control m-input" id="PRLLeadID" name="PRLLeadID" placeholder="External ID" type="text" />
                            </div>
                        </div>

                    </div>
                      <div class="form-group m-form__group row removePadding">
                        <div class="col-lg-6 row">
                          <div class="col-lg-12">
                            <div class="m-checkbox-inline">
                              <label class="m-checkbox ">
                                <input data-val="true" data-val-required="The IsDoNotCallContact field is required." id="IsDoNotCallContact" name="IsDoNotCallContact" type="checkbox" onChange={(e) => HandleChangeCheckIsDoNotCall(e)}

                                  defaultChecked={IsDoNotCallContactData} /> Do Not Call Contact
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-top modal-content text-right">
                  <div className="row">
                    <div className="col-lg-12 pull-right">
                      <a
                        id="backtolist"
                        className="btn btn-secondary mr-2"
                        onClick={() => SetEditPopup(false)}
                      >
                        cancel
                      </a>
                      <button
                        id="submit"
                        disabled={IsBtnUpdate}
                        onClick={(e) => ContactUpdate(e)}
                        className="btn btn-primary btn-lightgreen mr-1"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Popup>
      <div className="row padt-25">
      <div className="col-sm-12 col-md-6"></div>
        <div className="col-sm-12 col-md-6">
          {/* <div className="listing-li float-right padb-15">
            <ul className="mt-0">
              <li>
                <a
                  id="UnSelect"
                  defaultValue={CheckState}
                  onClick={(e) => UnSelectCheckHandler(e)}
                  style={{ display: "none", color:"black", paddingLeft:"15px", paddingRight:"15px"}}
            
                  className="mt-2 px-2"
                >
                  Unselect All
                </a>
              </li>

              <li>
                <a
                  id="Select-All"
                  className="mr-5"
                  defaultValue={CheckState}
                  onClick={(e) => SelectCheckHandler(e)}
                  style={{color:"black", paddingLeft:"15px", paddingRight:"15px"}}
                >
                  Select All
                </a>
              </li>
        
            </ul>
          </div> */}
        </div>
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries</label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" onKeyPress={RequestSearch} /></label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell> <input type="checkbox" id="main-check" onChange={(e) => CheckHandler(e)}
                /></TableCell>
                <TableCell>  <a
                  onClick={() => {
                    SortData("Name");
                  }}
                >Name</a></TableCell>
                <TableCell><a
                  onClick={() => {
                    SortData("Title");
                  }}
                >Title</a></TableCell>
                <TableCell><a
                  onClick={() => {
                    SortData("Company");
                  }}
                >Company</a></TableCell>
                <TableCell><a
                  onClick={() => {
                    SortData("Industry");
                  }}
                >Industry</a></TableCell>
                <TableCell><a
                  onClick={() => {
                    SortData("Email");
                  }}
                >Email</a></TableCell>
                <TableCell><a
                  onClick={() => {
                    SortData("Name");
                  }}
                >Last Send Date</a></TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length == 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (Rows?.map((row) => (
                <TableRow key={row.name} row={row} >
                  <TableCell><input type="checkbox"
                      onChange={(e) => ProspectCheckHandler(e, row)}
                    
                    checked={prospectAllSelectData.some(item => item._id === row?._id)} 

                   /></TableCell>
                  <TableCell>{row.Name}</TableCell>
                  <TableCell>{row.Title}</TableCell>
                  <TableCell>{row.Company}</TableCell>
                  <TableCell>{row.Industry}</TableCell>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>{row.LastSendDate}</TableCell>
                  <TableCell><a
                    onClick={() => {
                      EditBtn(row?.ProspectID)
                    }}
                    className="btn-eyesicon"
                  >
                    <i className="la flaticon-edit-1 edit-icon"></i>
                  </a></TableCell>
                </TableRow>
              ))
              )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="row">
        <div className="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div className="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            page={Page}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </div>
  );
}
