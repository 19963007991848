import React,  { useEffect }from 'react';
import Axios from "axios";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
const moment = require("moment");

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Pagination from "@material-ui/lab/Pagination";
import Paper from '@material-ui/core/Paper';
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";
import { error } from 'jquery';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../StickyHeader/StickyHeader";

toast.configure();



export default function CurrentClientsTable({
  AccountTypeForTab,
  AccountTypeName,
}) {
  const [data, setdata] = React.useState([]);
  const [flen, setflen] = React.useState(0);
  const [sflag, setsflag] = React.useState(false);
  const [rlen, setrlen] = React.useState(0);
  const [countpage, setCountPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [sortField, setsortField] = React.useState("CreatedDate");
  const [sortedBy, setsortedBy] = React.useState(-1);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role, setRole] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [UserEmail, SetUserEmail] = React.useState("");
  const [ShowPopupImport, SetShowPopupImport] = React.useState(false);
  const [BtnDisabled,SetBtnDisabled] = React.useState(false)
  const [Errors,SetErrors] = React.useState({})
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsLoading,SetIsLoading] = React.useState(true)
  const [sortedColumn, setSortedColumn] = React.useState(null);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

    useEffect(() => {
      var Details = GetUserDetails();
      if (Details != null) {
        setClientID(Details.ClientID);
        setUserID(Details.ParentUserID);
        SetCUserID(Details.ChildUserID);
        setRole(Details.Role);
        SetUserEmail(Details.Username);
      }
    }, [UserEmail]);
    
    useEffect(() => {
      var MDetails = GetUserDetails();
    ClientContactGet(MDetails.ParentUserID);
    }, [search, page, RowsPerPage]);

    const ClientContactGet = (UID) => {
      var str_in = {
        Page: page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: sortField,
        SortBy: sortedBy,
        Search: search,
        Type: "User",
        UserID:UID,
        AccountType: AccountTypeForTab,
     
      };
  
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ClientContactGet",
        method: "POST",
        data: str_in,
      });
      rows1.then((result) => {

        setdata(result.data?.PageData);
        setRows(result.data?.PageData);
        setrlen(result.data?.TotalCount);
        setflen(result.data?.TotalCount);
        setCountPage(result.data?.PageCount);
        SetIsLoading(false)
        setIsDataLoaded(true);


      }).catch(() =>{
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false)
        setIsDataLoaded(true);

      });
    };

    const ViewBtn = (id, emailid) => {
      history.push({
        pathname: "/viewcontacts",
        state: { data: id, EmailData: emailid },
      });
    };

      // contact btn
  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a contact.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        SetIsLoading(true)
        var data = {
          _id: id,
          IsDeleted: true,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          CampaignID:null,
          ClientID:ClientID,
          UserID:UserID
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Contact  deleted successfully.",
                "success"
              );
              ClientContactGet(UserID);
              SetIsLoading(false)
            } else {
            }
          }
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

    // change page
    const HandleChangePage = (Event, NewPage) => {
      if (NewPage == page) {
        setPage(NewPage);
      } else {
        setPage(NewPage);
        SetIsLoading(true)
      }
    };

      //get sort field data
  const SortData = (Field) => {
    var searchedVal = document.getElementById("search").value;
    var SerchBox;
    if (searchedVal == "") {
      SetIsLoading(true)
      SerchBox = false;
      setsflag(false);
      var SortField = Field;
      var SortBy;
      if (sortedBy == 1) {
        SortBy = -1;
        setsortedBy(-1);
      } else {
        SortBy = 1;
        setsortedBy(1);
      }
      setsortField(Field);
      var InputParameter = {
        Page: page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Search:searchedVal,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        UserID: UserID,
        Role: Role,
        AccountType: AccountTypeForTab,
      };
      const ContactGetList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ClientContactGet",
        method: "POST",
        data: InputParameter,
      });
      ContactGetList.then((Result) => {
        setRows(Result.data.PageData);
        setrlen(Result.data.TotalCount);
        setflen(Result.data.TotalCount);
        setCountPage(Result.data.PageCount);
        SetIsLoading(false)
        
      });
    } else {
      SetIsLoading(true)
      SerchBox = true;
      setsflag(true);
      var SortField = Field;
      var SortBy;
      if (sortedBy == 1) {
        SortBy = -1;
        setsortedBy(-1);
      } else {
        SortBy = 1;
        setsortedBy(1);
      }
      setsortField(Field);
      var InputParameter = {
        Page: page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Search:searchedVal,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        UserID: UserID,
        AccountType: AccountTypeForTab,
      };

      const ContactGetList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ClientContactGet",
        method: "POST",
        data: InputParameter,
      });
      ContactGetList.then((Result) => {
        setRows(Result.data.PageData);
        setrlen(Result.data.TotalCount);
        setflen(Result.data.TotalCount);
        setCountPage(Result.data.PageCount);
        SetIsLoading(false)

      });
    }
  };
    // changes row selected
    const ChangeRowSelected = (event) => {
      SetRowsPerPage(event.target.value);
      setPage(1);
      SetIsLoading(true)
    };

      // search details
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var searchedVal = document.getElementById("search").value;
      SetIsLoading(true)
      setSearch(searchedVal);
      // var str_in = {
      //   Page: page,
      //   RowsPerPage: RowsPerPage,
      //   Sort: true,
      //   Field: sortField,
      //   SortBy: sortedBy,
      //   Search: searchedVal,
      //   Type: "User",
      //   UserID:UserID,
      //   AccountType: AccountTypeForTab,
     
      // };
  
      // const rows1 = Axios({
      //   url: CommonConstants.MOL_APIURL + "/contacts/ClientContactGet",
      //   method: "POST",
      //   data: str_in,
      // });
      // rows1.then((result) => {

      //   setdata(result.data?.PageData);
      //   setRows(result.data?.PageData);
      //   setrlen(result.data?.TotalCount);
      //   setflen(result.data?.TotalCount);
      //   setCountPage(result.data?.PageCount);
      //   SetIsLoading(false)

      // }).catch(() =>{
      //   toast.error("An error occurred. Please try again.");
      //   SetIsLoading(false)
      // });


    }
  };

  // form validation
  const FormValidation = async () => {
    let formIsValid = true;
    let Errors = {};
    const email = document.getElementById("Username").value.trim();
  
    if (email === "") {
      formIsValid = false;
      Errors["Username"] = "Please enter an email address";
    } else {
      // Regular expression for a simple email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
      if (!emailRegex.test(email)) {
        formIsValid = false;
        Errors["Username"] = "Please enter a valid email address";
      }
    }
  
    SetErrors(Errors);
    return formIsValid;
  };
  

    // Exports csv for contacts
    const ExportsToContacts = async() => {
      SetBtnDisabled(true)
      var ExportEmails = document.getElementById("Username").value;
      var Final_flag = await FormValidation();
      if(Final_flag == true){
        var ExportData = {
          UserID: UserID,
          Email: ExportEmails,
          AccountTypeID:null,
          AccountType: AccountTypeName,
          CreatedDate: new Date(),
          CreatedBy: CUserID,
          IsDeleted:false,
          LastUpdatedDate:null,
          LastUpdatedBy:null,
          InProcess:false,
          IsComplete:false,
          InProcessDate:null,
          IsCompleteDate:null,
          Page:"",
          ClientID:ClientID,
          CampaignID:null,
          DynamicQuery:""
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/AllClientContactExport",
          method: "POST",
          data: ExportData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className='toastsize'>
              Contact <br />
              You will receive the exported data at the email address you provided.
            </div>
            );
            SetShowPopupImport(false)
            SetBtnDisabled(false)
          } else {
    
            toast.error("An error occurred. Please try again.");
            SetBtnDisabled(false)
  
          }
        });
      }else{
 
        SetBtnDisabled(false)
      }
  
    };
  return (
    <div>
        {
          IsLoading === true ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : null
        }
           <div className="row align-items-center">
              <div className="col border-bottom"> 
              <h4 className="headertitle xs-headertitle  float-left px-0 ">{AccountTypeName}</h4>
                <div className="listing-li float-right padb-15">
                  <ul>
                 
                    <li>
                      <a
                       onClick={() => {
                                    SetShowPopupImport(true);
                                  }}
                        className="btn btngroup m-btn"
                      >
                        <i class="la la-icon-font-size-13 la-download"></i>
                        <span>Export</span>
                      </a>
                    </li>
                    <Popup open={ShowPopupImport}>
              
              <div>
                <div className="modal-black"></div>
                <div className="filterPopup largerPopup">
                  <div className="paddingboxTerms">
                    <div className="modal-header py-4 px-3">
                      <div className="w-100 d-flex px-3">
                        <h5 className="mb-0">Email</h5>
                        <button className="close"        onClick={() => {
                                    SetShowPopupImport(false);
                                    SetErrors({})
                                  }}>
                          <span
                            aria-hidden="true"
                            class="la la-remove"
                          ></span>
                        </button>
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="modal-body">
                        <div className="col-sm-6 mb-5 px-0">
                          <div class="row mb-3">
                            <label class="col-lg-5 col-form-label text-left">
                              Email
                            </label>
                            <div class="col-lg-7">
                              <input
                                class="form-control m-input"
                                id="Username"
                                data-val="true"
                                data-val-remote="Title already exist."
                                data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                data-val-required="Please enter Email "
                                name="Username"
                                defaultValue={UserEmail}
                                type="text"
                              />
                                     <span style={{ color: "red" }}>
                        {Errors["Username"]}
                      </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-top modal-content text-right">
                        <div class="row">
                          <div class="col-lg-12 pull-right">
                            <a
                              id="backtolist"
                              class="btn btn-secondary mr-2"
                              onClick={() => {
                                    SetShowPopupImport(false);
                                    SetErrors({})
                                  }}
                            >
                              cancel
                            </a>
                            <button
                              id="submit"
                              disabled={BtnDisabled}
                              onClick={() => ExportsToContacts()}
                              class="btn btn-primary btn-lightgreen mr-1"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
          </Popup>
                  </ul>
                </div>
              </div>
            </div>
          <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      
      <div className="table-bordered">
    <TableContainer component={Paper}>
    <StyleHeader isDataLoaded={isDataLoaded} />
    <Table className="table-ref"  aria-label="collapsible table">        <TableHead>
          <TableRow> 
            <TableCell>Action</TableCell>
            <TableCell><a onClick={() => {
                      SortData("CreatedDate");
                      setSortedColumn("ClientName"); 
                    }} >Client Name</a> 
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "ClientName" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "ClientName"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("FirstName");
                      setSortedColumn("ContactName"); 

                    }}>Contact Name</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "ContactName" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "ContactName"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("FirstName");
                      setSortedColumn("AccountName"); 

                    }}>Account Name</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "AccountName" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "AccountName"  ? "active" : null} />
                          </span></TableCell>
            <TableCell className='wrap'><a onClick={() => {
                      SortData("Title");
                      setSortedColumn("Title"); 

                    }}>Title</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "Title" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "Title"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("Company");
                      setSortedColumn("Company"); 

                    }}>Company</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "Company" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "Company"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("Email");
                      setSortedColumn("Email"); 

                    }}>Email</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "Email" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "Email"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("Website");
                      setSortedColumn("Website"); 

                    }}>Website</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "Website" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "Website"  ? "active" : null} />
                          </span></TableCell>
            <TableCell className='wrap'><a onClick={() => {
                      SortData("LinkedInURL");
                      setSortedColumn("LinkedInURL"); 

                    }}>LinkedIn URL</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "LinkedInURL" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "LinkedInURL"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("Industry");
                      setSortedColumn("Industry");

                    }}>Industry</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "Industry" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "Industry"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("Address1");
                      setSortedColumn("Address1");

                    }}>Address 1</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "Address1" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "Address1"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("Address2");
                      setSortedColumn("Address2");

                    }}> Address 2</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "Address2" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "Address2"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("City");
                      setSortedColumn("City");

                    }}>City</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "City" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "City"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("State");
                      setSortedColumn("State");

                    }}>State</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "State" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "State"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("Zip");
                      setSortedColumn("Zip");

                    }}>Zip</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "Zip" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "Zip"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("Country");
                      setSortedColumn("Country");

                    }}>Country</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "Country" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "Country"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("ContactCategory");
                      setSortedColumn("ContactCategory");

                    }}>Contact Category</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "ContactCategory" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "ContactCategory"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("AccountCategory");
                      setSortedColumn("AccountCategory");

                    }}>Account Category</a>                      <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "AccountCategory" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "AccountCategory"  ? "active" : null} />
                          </span>	</TableCell>
            <TableCell><a onClick={() => {
                      SortData("ValidationScore");
                      setSortedColumn("ValidationScore");

                    }}>Validation Score</a>                      <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "ValidationScore" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "ValidationScore"  ? "active" : null} />
                          </span>	</TableCell>
            <TableCell><a onClick={() => {
                      SortData("ListName");
                      setSortedColumn("ListName");

                    }}>List Name</a>                      <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "ListName" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "ListName"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("LastValidationDate");
                      setSortedColumn("LastValidationDate");
                    }}>Last Validation Date</a>                      <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "LastValidationDate" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "LastValidationDate"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("Deliveries");
                      setSortedColumn("Deliveries");
                    }}>Deliveries</a>                      <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "Deliveries" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "Deliveries"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("Opens");
                      setSortedColumn("Opens");
                    }}>Opens</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "Opens" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "Opens"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("Replies");
                      setSortedColumn("Replies");
                    }}>Replies</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "Replies" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "Replies"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("SoftBounces");
                      setSortedColumn("SoftBounces");
                    }}>Soft Bounces</a></TableCell>
            <TableCell><a onClick={() => {
                      SortData("HardBounces");
                      setSortedColumn("HardBounces");
                    }}>Hard Bounces</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "SoftBounces" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "SoftBounces"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("FirstName");
                      setSortedColumn("ContactStatus");
                    }}>Contact Status</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "ContactStatus" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "ContactStatus"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("FirstName");
                      setSortedColumn("ContactSource");
                    }}>Contact Source</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "ContactSource" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "ContactSource"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("FirstName");
                      setSortedColumn("ContactOwner");
                    }}>Contact Owner</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "ContactOwner" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "ContactOwner"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("FirstName");
                      setSortedColumn("ClientPOC");
                    }}>Client POC</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "ClientPOC" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "ClientPOC"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("FirstName");
                      setSortedColumn("ReasonUnqualifed");
                    }}>Reason Unqualified</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "ReasonUnqualifed" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "ReasonUnqualifed"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("FirstName");
                      setSortedColumn("AccountType");
                    }}>Account Type</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "AccountType" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "AccountType"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("DirectPhone");
                      setSortedColumn("DirectPhone");
                    }}>Direct Phone</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "DirectPhone" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "DirectPhone"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("CompanyPhone");
                      setSortedColumn("CompanyPhone");
                    }}>Company Phone</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "CompanyPhone" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "CompanyPhone"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("CompanyRevenue");
                      setSortedColumn("CompanyRevenue");
                    }}>Company Revenue</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "CompanyRevenue" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "CompanyRevenue"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("EmployeeCount");
                      setSortedColumn("EmployeeCount");
                    }}>Employee Count</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "EmployeeCount" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "EmployeeCount"  ? "active" : null} />
                          </span></TableCell>
            <TableCell><a onClick={() => {
                      SortData("MobilePhone");
                      setSortedColumn("MobilePhone");
                    }}>Mobile Phone</a>
                                          <span className="shorting">
                         <ArrowUpward className={sortedBy === 1 && sortedColumn === "MobilePhone" ? "active" : null} />
                          <ArrowDownward  className={sortedBy === -1 && sortedColumn === "MobilePhone"  ? "active" : null} />
                          </span></TableCell>
            <TableCell>Action</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((value1) => (
            <>

            <TableRow>
            <TableCell>
                    <a
                      onClick={() => {
                        ViewBtn(value1?._id, value1.Email);
                      }}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-eye edit-icon"></i>
                    </a>
                    <a
                      onClick={() => {
                        DeleteBtn(value1?._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
            <TableCell>{value1?.ClientName}</TableCell>
            <TableCell><a onClick={() => {
                        ViewBtn(value1?._id, value1.Email);
                      }}><u>{value1?.FirstName} {" "} {value1?.LastName}</u></a></TableCell>
            <TableCell>{value1?.AccountName}</TableCell>
            <TableCell className='wrap'>{value1?.Title}</TableCell>
            <TableCell>{value1?.Company}</TableCell>
            <TableCell>{value1?.Email}</TableCell>
            <TableCell><a    href={`http://${value1?.Website}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: "underline",
                                  color: "inherit",
                                }}><u>{value1?.Website}</u></a></TableCell>
            <TableCell className='wrap'>{value1?.LinkedInURL}</TableCell>
            <TableCell>{value1?.Industry}</TableCell>
            <TableCell>{value1?.Address1}</TableCell>
            <TableCell>{value1?.Address2}</TableCell>
            <TableCell>{value1?.City}</TableCell>
            <TableCell>{value1?.State}</TableCell>
            <TableCell>{value1?.Zip}</TableCell>
            <TableCell>{value1?.Country}</TableCell>
            <TableCell>{value1?.ContactCategory}</TableCell>
            <TableCell>{value1?.AccountCategory}</TableCell>
            <TableCell>{value1?.ValidationScore}</TableCell>
            <TableCell>{value1?.ListName}</TableCell>
            <TableCell>  {value1?.LastValidationDate == null ? "" : moment(
                            new Date(value1?.LastValidationDate).toDateString()
                          ).format("MM/DD/YYYY") }</TableCell>
            <TableCell>{value1?.Deliveries}</TableCell>
            <TableCell>{value1?.Opens}</TableCell>
            <TableCell>{value1?.Replies}</TableCell>
            <TableCell>{value1?.SoftBounces}</TableCell>
            <TableCell>{value1?.HardBounces}</TableCell>
            <TableCell>{value1?.ContactStatus}</TableCell>
            <TableCell>{value1?.ContactSource && value1?.ContactSource.length > 0 ? value1.ContactSource[0] : ""}</TableCell>
            <TableCell>{value1?.ContactOwner && value1?.ContactOwner.length > 0 ? value1.ContactOwner[0] : ""}</TableCell>
            <TableCell>{value1?.ClientPOC && value1?.ClientPOC.length > 0 ? value1.ClientPOC[0] : ""}</TableCell>
            <TableCell>{value1?.ReasonUnqualifed && value1?.ReasonUnqualifed.length > 0 ? value1.ReasonUnqualifed[0] : ""}</TableCell>
            <TableCell>{value1?.AccountType && value1?.AccountType.length > 0 ? value1.AccountType[0] : ""}</TableCell>
            <TableCell>{value1?.DirectPhone}</TableCell>
            <TableCell>{value1?.CompanyPhone}</TableCell>
            <TableCell>{value1?.CompanyRevenue}</TableCell>
            <TableCell>{value1?.EmployeeCount}</TableCell>    
            <TableCell>{value1?.MobilePhone}</TableCell>
            <TableCell>
                    <a
                      onClick={() => {
                        ViewBtn(value1?._id, value1.Email);
                      }}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-eye edit-icon"></i>
                    </a>
                    <a
                      onClick={() => {
                        DeleteBtn(value1?._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
            </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
          {sflag ? (
            <div class="row pb-2">
              <Pagination
                component="div"
                count={countpage}
                onChange={HandleChangePage}
                showFirstButton
                showLastButton
              />
              <div class="col dataTables_info">
                <p>
                  Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
                  {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
                  entries (filtered from {flen} total entries)
                </p>
              </div>
            </div>
          ) : (
            <div class="row pb-2">
              <div class="col dataTables_info">
                <p>
                  Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
                  {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
                  entries
                </p>
              </div>
              <div class="col pageright">
                <Pagination
                  component="div"
                  count={countpage}
                  onChange={HandleChangePage}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
          </div>
  );
}
