import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import Axios from "axios";
import Popup from "reactjs-popup";
import ReactApexChart from "react-apexcharts";
const moment = require("moment");
import { userActions } from "../../_actions";
import { history } from "../../_helpers";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import ClientMeetings from "../../_components/user/ClientMeetings/ClientMeetings";
import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import $ from "jquery";

charts(FusionCharts);

class ClientMeetingsPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);
    const date13 = new Date(moment().subtract(14, 'day'));
    // Extract the year, month, and day from the Date object
    const year13 = date13.getFullYear();
    const month13 = String(date13.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
    const day13 = String(date13.getDate()).padStart(2, '0');
   const date =  new Date(moment());
   const year = date.getFullYear();
   const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
   const day = String(date.getDate()).padStart(2, '0');

    this.state = {
      Pager: {},
      UserEmail: Details.Username,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      FromDate: `${year13}-${month13}-${day13}`,
      ToDate: `${year}-${month}-${day}`,
      TotalCount: 0,
      TotalCountQuarter: 0,
      TotalCountSeven: 0,
      TotalCountOne: 0,
      ClientIDs: "",
      Close: true,
      Fields: {},
      Errors: {},
      Isvalid: false,
      OpenExportModel: false,
      IsButtonDisabled: false,
    };
    this.Analyze = this.Analyze.bind(this);
    this.ExportsToClientMeeting = this.ExportsToClientMeeting.bind(this);
    this.AddClientMeeting = this.AddClientMeeting.bind(this);
    this.daterefect = React.createRef();
    this.daterefect2 = React.createRef();
  }
  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    document.title = "Client KPIs | SalesHive";
  // Get today's date
  var today = new Date();

  // Subtract 14 days from today's date
  var twoWeeksBefore = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000);

  // Set the value of the "fromdate" variable to two weeks before
  var fromdate = twoWeeksBefore;

  // Convert to UTC date components
  const utcYear = fromdate.getUTCFullYear();
  const utcMonth = String(fromdate.getUTCMonth() + 1).padStart(2, "0");
  const utcDay = String(fromdate.getUTCDate()).padStart(2, "0");

  // Format the date
  const formattedDate = `${utcMonth}/${utcDay}/${utcYear}`;

  // Set the value of the "from-date" input field
  document.getElementById("from-date").value = formattedDate;

  const $datepickerElement = $(this.daterefect.current);
  $datepickerElement.datetimepicker({
    format: "mm/dd/yyyy",
    autoclose: true,
    container: "#app",
    todayBtn: true,
    todayHighlight: true,
    minView: "2",
  });
  document.getElementById("to-date").value = new Date().toLocaleDateString(
    "en-US",
    { month: "2-digit", day: "2-digit", year: "numeric" }
  );
  const $datepickerElement2 = $(this.daterefect2.current);
  $datepickerElement2.datetimepicker({
    format: "mm/dd/yyyy",
    autoclose: true,
    container: "#app",
    todayBtn: true,
    todayHighlight: true,
    minView: "2",
  });

  var FromDate = moment
    .utc(document.getElementById("from-date").value)
    .format("YYYY-MM-DD");
  var ToDate = moment
    .utc(document.getElementById("to-date").value)
    .format("YYYY-MM-DD");
  this.setState({ FromDate: FromDate, ToDate: ToDate });
    this.GetChartmeeting(FromDate,ToDate);
    this.GetClientMeetingCount(FromDate,ToDate);
    this.GetClientList();
  }

  Analyze() {
    var FromDate = moment.utc(document.getElementById("from-date").value).format("YYYY-MM-DD");
  var ToDate = moment.utc(document.getElementById("to-date").value).format("YYYY-MM-DD");
  if (moment(FromDate, "YYYY-MM-DD").isAfter(moment(ToDate, "YYYY-MM-DD"))) {
    toast.error("Please select an appropriate date range");
  } else {

    this.setState({ FromDate: FromDate, ToDate: ToDate });
    this.GetChartmeeting(FromDate,ToDate,true);
    this.GetClientMeetingCount(FromDate,ToDate,true);
  }
  }

  async GetClientList() {
    var ObjData = {
      Role: "Admin",
      ChildUserID: this.state.CUserID,
      ParentUserID: this.state.UserID,
    };
    const rows1 = await Axios({
      url: CommonConstants.MOL_APIURL + "/client/GetClientListForTopDropDown",
      method: "POST",
      data: ObjData,
    });
    if (rows1.data.StatusMessage == "SUCCESS") {
      // ;
      if (rows1.data.Data.length > 0) {
        var ClientData = rows1.data.Data;
        var Ids = [];
        ClientData.map((Client) => {
          Ids.push(Client.ClientID);
        });
        var CIDs = Ids;
        // console.log(CIDs)
        //setClientDropdown(result.data.Data)
        this.setState({ ClientIDs: CIDs });
      }
    }
  }

  GetChartmeeting(FromDates,ToDates,CheckList) {
    var Details = GetUserDetails();
    var str_in = {
      UserID: Details.ParentUserID,
      Role: Details.Role,
      ToDate: ToDates,
      FromDate: FromDates,
      // CheckedSearch: CheckList,
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/clientmeeting/GetChartSource",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
      var dataSource = {
        chart: {
          //caption: "Meeting Source",
          //subcaption: "For a net-worth of $1M",
          subCaption: " ",
          formatnumberscale: "0",
          showLegend: "1",
          showLabels: "0",
          showValues: "1",
          valuePosition: "inside",
          numbersuffix: "%",
          plothighlighteffect: "false",
          legendcaptionbold: "1",
          legendcaptionfontsize: "15",
          legendCaptionFontColor: "#000",
          showPercentValues: "0",
          showPercentInToolTip: "0",
          enableSlicing: "1",
          legendPosition: "bottom",
          // legendCaption: "Meeting Status: ",
          legendBgColor: "#FFF",
          legendBorderColor: "#ffF",
          legendShadow: "0",
          legendItemFontSize: "14",
          legendWidth: "300",
        },
        data: result.data.data,
      };
      this.setState({
        sourcedata: dataSource,
      });
    });
  }

  // Whole form validation
  async FormValidation() {
    let Errors = {};
    let FormIsValid = true;
    let Email = document.getElementById("UsernameEmail").value.trim();
    let IsExist = await this.CheckClientInvite(Email);

    if (Email == "") {
      FormIsValid = false;
      Errors["UsernameEmail"] = "Please enter email";
    }
    if (IsExist == true) {
      FormIsValid = false;
      Errors["UsernameEmail"] = "invalid email.";
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // find exits meeting owner Email
  async CheckClientInvite(Email) {
    let regexp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    let Errors = {};
    if (!regexp.test(Email) && Email != "") {
      Errors["UsernameEmail"] = "invalid email.";
      this.setState({ Errors: Errors });
      return true;
    } else {
      // this.setState({ IsExist: false });
      return false;
    }
  }

  // handle change
  HandleChange(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    var Email = document.getElementById("UsernameEmail").value.trim();
    if (Email != "") {
      this.state.Errors.UsernameEmail = null;
      this.CheckClientInvite(Email);
    } else {
      this.state.Errors.UsernameEmail = null;
    }
  }

  //Close model box
  CloseModelBox() {
    let Errors = {};
    this.setState({ OpenExportModel: false });
    this.setState({ Errors: Errors });
  }

  // Exports csv for Client Meeting
  async ExportsToClientMeeting(e) {
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    this.setState({ IsButtonDisabled: true });
    var Final_flag = await this.FormValidation();
    if (Final_flag == true) {
      var ExportEmails = document.getElementById("UsernameEmail").value;
      var ExportData = {
        UserID: this.state.UserID,
        ClientID: this.state.ClientID,
        Email: ExportEmails,
        CreatedDate: new Date(),
        CreatedBy: this.state.CUserID,
        IncludeOldClient: false,
        ClientIDs: this.state.ClientIDs,
        ToDate: this.state.ToDate,
        FromDate: this.state.FromDate
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/clientmeeting/AllClientExportAdd",
        method: "POST",
        data: ExportData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          this.setState({ IsButtonDisabled: false });
          this.CloseModelBox();
          toast.success(
            <div className="toastsize">
              Meeting <br /> You will get file soon on Submitted Email.
            </div>,
            "For Exports Client Meeting "
          );
        } else {
          toast.error(res.data.Message);
          this.setState({ IsButtonDisabled: false });
        }
      });
    } else {
      // document.getElementById("submit").disabled = false;
      this.setState({ IsButtonDisabled: false });
    }
  }

  GetClientMeetingCount(FromDates,ToDates,CheckList) {
    var str_in = {
      UserID: this.state.UserID,
      ToDate: ToDates,
      FromDate: FromDates,
      CheckedSearch: CheckList,
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/clientmeeting/TotalCountBaseOnDate",
      method: "POST",
      data: str_in,
    });
    rows.then((Result) => {
      this.setState({ TotalCount: Result.data.DataCount });
      this.setState({ TotalCountQuarter: Result.data.DataCount30 });
      this.setState({ TotalCountSeven: Result.data.DataCount7 });
      this.setState({ TotalCountOne: Result.data.DataCount1 });
    });
  }

  //Add function
  AddClientMeeting() {
    history.push({
      pathname: "/addbookedmeeting",
      state: { Pagename: "AllClientMeeting", BackPage: "/clientmeetings" },
    });
  }

  //Reload page while delete perform
  UpdateFromChild = (value) => {
    if (value == true) {
      this.componentDidMount();
    }
  };

  UpdateFromChildOldclient = (value) => {
    this.GetChartmeeting(this.state.FromDate,this.state.ToDate,value);
    this.GetClientMeetingCount(this.state.FromDate,this.state.ToDate,value);
  };

  render() {
    return (
      <>
        <div className="adminmain">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="headertitle lg-headertitle  float-left pl-0 py-4">
                  Client Meetings
                </h4>
              </div>
              <div className="col">
                <div className="btn-group datepicker-hed">
                  <div className="col-lg-6 timedatepikker date-input">
                    <input
                      className="form-control m-input"
                      type="text"
                      id="from-date"
                      ref={this.daterefect}
                    />
                  </div>
                  <div className="col-lg-6 timedatepikker date-input">
                    <input
                      className="form-control m-input"
                      type="text"
                      id="to-date"
                      ref={this.daterefect2}
                    />
                  </div>
                  <button
                    onClick={this.Analyze}
                    id="aCompanyState"
                    class="btn btn-primary btnColor m-btn brrb72"
                    autocomplete="off"
                  >
                    <i class="la flaticon-diagram"></i>
                    <span> Analyze</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="w-100 whiterow  border-0">
              <div className="row px-2 py-3">
                <div className="col">
                  <div className="bg-light-sky boxcardcounter align-center">
                    <div class="line-title d-block text-center">
                      <span class="clr-sky xs-headertitle box-main-title">
                        {this.state.TotalCount}
                      </span>
                    </div>
                    <p class="clr-sky xs-headertitle">Total Meetings</p>
                  </div>
                </div>
                <div className="col">
                  <div className="bg-light-sky boxcardcounter align-center">
                    <div class="line-title d-block text-center">
                      <span class="clr-sky xs-headertitle box-main-title">
                        {this.state.TotalCountQuarter}
                      </span>
                    </div>
                    <p class="clr-sky xs-headertitle">Total Meetings Quarter</p>
                  </div>
                </div>
                <div className="col">
                  <div className="bg-light-sky boxcardcounter align-center">
                    <div class="line-title d-block text-center">
                      <span class="clr-sky xs-headertitle box-main-title">
                        {this.state.TotalCountSeven}
                      </span>
                    </div>
                    <p class="clr-sky xs-headertitle">Total Meetings Last 7 Days</p>
                  </div>
                </div>
                <div className="col">
                  <div className="bg-light-sky boxcardcounter align-center">
                    <div class="line-title d-block text-center">
                      <span class="clr-sky xs-headertitle box-main-title">
                        {this.state.TotalCountOne}
                      </span>
                    </div>
                    <p class="clr-sky xs-headertitle">Total Meetings Last Day</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row pb-4">
              <div className="col-xl-2"></div>
              <div className="col-xl-8">
                <h5 className="smheadtext">Meeting Source</h5>
                <ReactFusioncharts
                  key={JSON.stringify(this.state.sourcedata)}
                  type="pie3d"
                  width="100%"
                  height="300"
                  dataFormat="JSON"
                  dataSource={this.state.sourcedata}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="listing-li float-right padb-15 pr-4">
                  <ul>
                    <li>
                      <a
                        className="btn btngroup m-btn m-btn--custom"
                        onClick={() => {
                          this.setState({ OpenExportModel: true });
                        }}
                      >
                        <i class="la la-icon-font-size-13 la-download"></i>
                        <span>Export</span>
                      </a>

                      <Popup open={this.state.OpenExportModel}>
                        <div>
                          <div className="modal-black"></div>
                          <div className="filterPopup largerPopup">
                            <div className="paddingboxTerms">
                              <div className="modal-header py-4 px-3">
                                <div className="w-100 d-flex px-3">
                                  <h5 className="mb-0">Email</h5>
                                  <button
                                    className="close"
                                    onClick={() => {
                                      this.CloseModelBox();
                                    }}
                                  >
                                    <span
                                      aria-hidden="true"
                                      class="la la-remove"
                                    ></span>
                                  </button>
                                </div>
                              </div>
                              <div className="p-3">
                                <div className="modal-body">
                                  <div className="col-sm-8 mb-5 px-0">
                                    <div class="row mb-3">
                                      <label class="col-lg-5 col-form-label text-left">
                                        Email
                                      </label>
                                      <div class="col-lg-7">
                                        <input
                                          class="form-control m-input"
                                          id="UsernameEmail"
                                          data-val="true"
                                          data-val-remote="Title already exist."
                                          data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                          data-val-required="Please enter Email "
                                          name="UsernameEmail"
                                          defaultValue={this.state.UserEmail}
                                          onBlur={this.HandleChange.bind(
                                            this,
                                            "UsernameEmail"
                                          )}
                                          type="text"
                                        />
                                        <span style={{ color: "red" }}>
                                          {this.state.Errors["UsernameEmail"]}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="border-top modal-content text-right">
                                  <div class="row">
                                    <div class="col-lg-12 pull-right">
                                      <button
                                        id="backtolist"
                                        class="btn btn-secondary mr-2"
                                        onClick={() => {
                                          this.CloseModelBox();
                                        }}
                                      >
                                        cancel
                                      </button>
                                      <button
                                        id="submit"
                                        onClick={this.ExportsToClientMeeting}
                                        disabled={this.state.IsButtonDisabled}
                                        class="btn btn-primary btn-lightgreen mr-1"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Popup>
                    </li>
                    <li>
                      <a
                        onClick={this.AddClientMeeting}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-plus la-icon-font-size-13 la-add"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="paddcols">
              <div className="row">
                <div className="col">
                  <ClientMeetings updateFromChild={this.UpdateFromChild} FromDates={this.state.FromDate} ToDates={this.state.ToDate} updateFromChildOldClient={this.UpdateFromChildOldclient} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedClientMeetingsPage = connect(
  mapState,
  actionCreators
)(ClientMeetingsPage);
export { connectedClientMeetingsPage as ClientMeetingsPage };
