import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
toast.configure();
const moment = require("moment");

import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import AddArticle from "../../_components/user/KnowledgeBaseArticle/AddKnowledgeBaseArticle";

class AddKnowledgeBaseArticlePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ClientID: null,
      UserID: null,
      CUserID: null,
      StartDate: null,
      EndDate: null,
      exportdata: {
        Start: moment(new Date().setMonth(new Date().getMonth() - 3)).format(
          "YYYY/MM/DD"
        ),
        End: moment(new Date()).format("YYYY/MM/DD"),
      },
      pageData: null,
    };
  }
  render() {
    return (
      <>   
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="lg-headertitle py-3 float-left">Add Knowledge Base Article</h4>
              </div>
              <div className="col padright">
                
              </div>
            </div>  

          <div className="listblock">
             <AddArticle />
          </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddKnowledgeBaseArticlePage = connect(mapState, actionCreators)(AddKnowledgeBaseArticlePage);
export { connectedAddKnowledgeBaseArticlePage as AddKnowledgeBaseArticlePage };
