import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { toast } from "react-toastify";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";

import "react-toastify/dist/ReactToastify.css";

import ArticleLists from "../../_components/user/ArticleLists/ArticleLists";
import { history } from "../../_helpers";

toast.configure();
class ArticleListsPage extends React.Component {
  openAddArticlePage() {
    history.push("/addarticle");
  }
  render() {
    var obj = {};
    if(this.props.location.state){
      obj.searchText = this.props.location.state.searchText != undefined ? this.props.location.state.searchText : '';
      obj.categoryId = this.props.location.state.categoryId != undefined ? this.props.location.state.categoryId : ''; 
    }
    return (
      <>   
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="lg-headertitle pt-3">Articles</h4>
                {/* <p>Articles & Resources for the SDR - Cold Calling Specialist Role</p>  */}
              </div>
              <div className="col padright py-1 px-3">
              <div className="listing-li float-right padb-15">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" onClick={this.openAddArticlePage} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                      <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul> 
                </div>
              </div>
            </div>  

          <div className="listblock">
            <ArticleLists searchParam={obj} />
          </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedArticleListsPage = connect(mapState, actionCreators)(ArticleListsPage);
export { connectedArticleListsPage as ArticleListsPage };
