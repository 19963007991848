import React, { useState, useEffect } from 'react';
import Axios from "axios";
import CreatableSelect from 'react-select/creatable';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";
import { CommonConstants } from "../../../_constants/common.constants";

const Help = () => {

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const [UserRoleOptionsList, setUserRoleOptionsList] = useState([]);
  const [CategoryIssueOptionsList, setCategoryIssueOptionsList] = useState([]);
  const [PriorityOptionsList, setPriorityOptionsList] = useState([]);

  const [Submittedby, setSubmittedby] = useState('');
  const [UserRoleOptions, setUserRoleOptions] = useState('');
  const [Email, setEmail] = useState('');
  const [ClientName, setClientName] = useState('');
  const [CategoryIssueOptions, setCategoryIssueOptions] = useState('');
  const [CampaignName, setCampaignName] = useState('');
  const [Description, setDescription] = useState('');
  const [PriorityOptions, setPriorityOptions] = useState('');
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = `Help | SalesHive`;
    var UserRoleOptionsData = [
      { Tag: 'Sales Strategist', _id: '1' },
      { Tag: 'Responder', _id: '2' },
      { Tag: 'Sales Development Representative', _id: '3' },
      { Tag: 'Admin', _id: '4' },
      { Tag: 'User per Client', _id: '5' },
    ];
    const UserRoleOptionsList = UserRoleOptionsData.map((val) => {
      return { value: val.Tag, label: val.Tag, cid: val._id };
    });
    setUserRoleOptionsList(UserRoleOptionsList);

    var CategoryIssueOptionsData = [
      { Tag: 'KPIs', _id: '1' },
      { Tag: 'Accounts', _id: '2' },
      { Tag: 'Contacts', _id: '3' },
      { Tag: 'Meetings', _id: '4' },
      { Tag: 'Campaigns', _id: '5' },
      { Tag: 'Cold Calling', _id: '6' },
      { Tag: 'Lists', _id: '7' },
      { Tag: 'Variables', _id: '8' },
      { Tag: 'History', _id: '9' },
      { Tag: 'Strategy', _id: '10' },
      { Tag: 'Tools', _id: '11' },
      { Tag: 'Settings', _id: '12' },
      { Tag: 'Admin', _id: '13' },
      { Tag: 'Other', _id: '14' },
    ];
    
    const CategoryIssueOptionsList = CategoryIssueOptionsData.map((val) => {
      return { value: val.Tag, label: val.Tag, cid: val._id };
    });
    setCategoryIssueOptionsList(CategoryIssueOptionsList);

    var PriorityOptionsData = [
      { Tag: 'Highest', _id: '1' },
      { Tag: 'High', _id: '2' },
      { Tag: 'Medium', _id: '3' },
      { Tag: 'Low', _id: '4' },
      { Tag: 'Lowest', _id: '5' },
    ];
    const PriorityOptionsList = PriorityOptionsData.map((val) => {
      return { value: val.Tag, label: val.Tag, cid: val._id };
    });
    setPriorityOptionsList(PriorityOptionsList);
  }, []);

  const handleChangeUserRoleOptions = (selectedOption) => {
    setUserRoleOptions(selectedOption.value);
  };

  const handleChangeCategoryIssueOptions = (selectedOption) => {
    setCategoryIssueOptions(selectedOption.value);
  };

  const handleChangePriorityOptions = (selectedOption) => {
    setPriorityOptions(selectedOption.value);
  };
  
  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const FromValidation = () => {
    let formIsValid = true;
    
    if (Submittedby == "") {
      formIsValid = false;
      toast.error("Please enter your name");
      return formIsValid;
    }

    // if (UserRoleOptions == "") {
    //   formIsValid = false;
    //   toast.error("Please select user role");
    //   return formIsValid;
    // }

    if (Email == "") {
      formIsValid = false;
      toast.error("Please enter email");
      return formIsValid;
    }
    else{
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      formIsValid = regex.test(Email);
      if(!formIsValid){
        toast.error("Please enter valid email");
        return formIsValid;
      }
    }

    if (ClientName == "") {
      formIsValid = false;
      toast.error("Please enter client name");
      return formIsValid;
    }

    if (CategoryIssueOptions == "") {
      formIsValid = false;
      toast.error("Please select issue category");
      return formIsValid;
    }

    if (Description == "") {
      formIsValid = false;
      toast.error("Please enter issue description");
      return formIsValid;
    }

    if (PriorityOptions == "") {
      formIsValid = false;
      toast.error("Please select priority");
      return formIsValid;
    }
    return formIsValid;
  }

  const SubmitForm = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      var FormValidationFlag = await FromValidation();
      if(FormValidationFlag === true){
        const formData = new FormData();
        formData.append('SubmittedBy', Submittedby);
        // formData.append('UserRole', UserRoleOptions);
        formData.append('Email', Email);
        formData.append('ClientName', ClientName);
        formData.append('CategoryOfIssue', CategoryIssueOptions);
        formData.append('Campaign', CampaignName);
        formData.append('DescriptionOfIssue', Description);
        formData.append('Priority', PriorityOptions);

        files.forEach((file) => {
          formData.append('Attachments', file); // 'files' is the key for multiple files
        });

        const response = await Axios.post(CommonConstants.MOL_APIURL+'/help_setting/SubmitHelpRequest', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if(response.data.StatusMessage == "SUCCESS"){
          setIsLoading(false);
          setSubmittedby('');
          setUserRoleOptions(null);
          setEmail('');
          setClientName('');
          setCategoryIssueOptions(null);
          setCampaignName('');
          setDescription('');
          setPriorityOptions(null);
          setFiles([]);
          toast.success(response.data.Message);
        }
        else if(response.data.StatusMessage == "ERROR"){
          setIsLoading(false);
          toast.error(response.data.Message);
        }
      }
      else{
        setIsLoading(false);
      }
    }
    catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  }

  return (
    <>
      {
        isLoading === true ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : null
      }
      <div>
        <div class="bg-white p-3">
          <form onSubmit={SubmitForm}>
            <div className="row">
              <div class="col-lg-12 boxsinput_group pt-0">
                <div class="row max-cols">
                  <label class="col-lg-12 view-lable-meet pt-0">Submitted By?</label>
                  <div class="col-lg-12 dropdown-custom">
                    {/* <CreatableSelect
                              closeMenuOnSelect={true}
                              hideSelectedOptions={false}
                              options={SubmittedOptions}
                              id="SubmittedOptions"  
                            /> */}
                    <input
                      class="form-control m-input"
                      data-val="true"
                      data-val-required="Enter your name"
                      id="Submittedby"
                      name="Submittedby"
                      placeholder="Enter your name"
                      type="text"
                      value={Submittedby}
                      onChange={(e) => setSubmittedby(e.target.value)}
                    />
                    {/* <span style={{ color: "red" }}>
                            {this.state.Errors["Name"]}
                          </span> */}
                  </div>
                </div>
              </div>
              <div class="col-lg-12 boxsinput_group">
                <div class="row max-cols">
                  {/* <label class="col-lg-12 view-lable-meet">User Role?</label> */}
                  <label class="col-lg-12 view-lable-meet">Email</label>
                  <div class="col-lg-12 dropdown-custom">
                    {/* <CreatableSelect
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      options={UserRoleOptionsList}
                      id="UserRoleOptions"
                      // value={UserRoleOptions}
                      onChange={handleChangeUserRoleOptions}
                    /> */}
                    <input
                      class="form-control m-input"
                      data-val="true"
                      data-val-required="Enter your email"
                      id="Email"
                      name="Email"
                      placeholder="Enter your email"
                      type="text"
                      value={Email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {/* <span className='label-notes'>What is your role in the platform?</span> */}
                  </div>
                </div>
              </div>

              <div class="col-lg-12 boxsinput_group">
                <div class="row max-cols">
                  <label class="col-lg-12 view-lable-meet">Client Name?</label>
                  <div class="col-lg-12">
                    <input
                      class="form-control m-input"
                      data-val="true"
                      data-val-required="Write something"
                      id="ClientName"
                      name="ClientName"
                      placeholder="Write something"
                      type="text"
                      value={ClientName}
                      onChange={(e) => setClientName(e.target.value)}
                    />
                    <span className='label-notes'>Type your client's name. if multiple, type all names. if none, type "N/A".</span>
                    {/* <span style={{ color: "red" }}>
                            {this.state.Errors["Name"]}
                          </span> */}
                  </div>
                </div>
              </div>


              <div class="col-lg-12 boxsinput_group">
                <div class="row max-cols">
                  <label class="col-lg-12 view-lable-meet">Category of the issue?</label>
                  <div class="col-lg-12 dropdown-custom">
                    <CreatableSelect
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      options={CategoryIssueOptionsList}
                      id="CategoryIssueOptions"
                      // value={CategoryIssueOptionsList}
                      onChange={handleChangeCategoryIssueOptions}
                    />
                    <span className='label-notes'>Which section / category of the platform is your issue located in?</span>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 boxsinput_group">
                <div class="row max-cols">
                  <label class="col-lg-12 view-lable-meet">Campaign? (Optional)</label>
                  <div class="col-lg-12">
                    <input
                      class="form-control m-input"
                      data-val="true"
                      data-val-required="Write something"
                      id="CampaignName"
                      name="CampaignName"
                      placeholder="Write something"
                      type="text"
                      value={CampaignName}
                      onChange={(e) => setCampaignName(e.target.value)}
                    />
                    <span className='label-notes'>If issue is with  a campaign, please include campagin name.</span>
                    {/* <span style={{ color: "red" }}>
                            {this.state.Errors["Name"]}
                          </span> */}
                  </div>
                </div>
              </div>

              <div class="col-lg-12 boxsinput_group">
                <div class="row max-cols">
                  <label class="col-lg-12 view-lable-meet">Description of the issue?</label>
                  <div class="col-lg-12">
                    <textarea style={{ height: '100px' }}
                      class="form-control m-area"
                      data-val="true"
                      data-val-required="Write something"
                      id="Description"
                      name="Description"
                      placeholder="Write something"
                      type="text"
                      value={Description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <span className='label-notes'>please give a detailed description of your issue.</span>
                    {/* <span style={{ color: "red" }}>
                            {this.state.Errors["Name"]}
                          </span> */}
                  </div>
                </div>
              </div>

              <div class="col-lg-12 boxsinput_group">
                <div class="row max-cols">
                  <label class="col-lg-12 view-lable-meet">Attachment?</label>
                  <div class="col-lg-12">
                    <Button className='upload-btn'
                      component="label"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                      startIcon={<CloudUploadOutlined />}
                    >
                      Upload File
                      <VisuallyHiddenInput
                        type="file"
                        // onChange={(event) => console.log(event.target.files)}
                        onChange={handleFileChange}
                        multiple
                      />
                    </Button>
                    <span className='label-notes'>Upload screenshot(s) or video(s) of the issue. If you can see it, you can record it.</span>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 boxsinput_group">
                <div class="row max-cols">
                  <label class="col-lg-12 view-lable-meet">Priority?</label>
                  <div class="col-lg-12 dropdown-custom">
                    <CreatableSelect
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      options={PriorityOptionsList}
                      id="PriorityOptions"
                      menuPlacement="top"
                      // value={PriorityOptions}
                      onChange={handleChangePriorityOptions}
                    />
                    <span className='label-notes'>Cannot do job = Higher priority / Inconvenience = Medium priority / Non time sensitive = Low priority.</span>
                  </div>
                </div>
              </div>

            </div>
            <div className="row border-top pt-3 pb-2">
              <div className="col-lg-12">
                <div className="pull-right">
                  <a
                    href="javascript:void(0);"
                    className="btn btn-secondary"
                    value="Save"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                  <button
                    id="submit"
                    href="javascript:void(0);"
                    className="btn btn-primary btn-lightgreen mr-1"
                    value="Save"
                    // onClick={SubmitForm}
                    // disabled={state.IsBtnDisabled}
                  >
                    <i className="la la-save"></i> Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Help;