import React, { useState, useEffect } from 'react';
import Axios from "axios";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';

import { CommonConstants } from "../../../_constants/common.constants";

const ArticleCategories = (searchParam) => {
    const [CategoryId, SetCategoryId] = React.useState("");
    const [Page, SetPage] = React.useState(1);
    const [RowsPerPage, SetRowsPerPage] = React.useState(8);
    const [Search, SetSearch] = React.useState("");
    const [SortField, SetSortField] = React.useState("CreatedDate");
    const [SortedBy, SetSortedBy] = React.useState(-1);
    const [IsLoading, SetIsLoading] = React.useState(false);
    const [ArticleList, setArticleList] = useState([]);
    const [TotalCount, setTotalCount] = useState(0);
    const [TotalPage, setTotalPage] = useState(0);

    useEffect(() => {
        document.title = `Article List | SalesHive`;
        var catId = '', srchText = '';
        if (searchParam.searchParam.categoryId) {
            catId = searchParam.searchParam.categoryId;
            SetCategoryId(catId)
        }
        if (searchParam.searchParam.searchText) {
            srchText = searchParam.searchParam.searchText;
            SetSearch(srchText);
        }
        getArticles(catId, srchText, Page);
    }, [searchParam, Page]);

    const getArticles = async (CategoryId, Search, page) => {
        SetIsLoading(true);
        try {
            let InputParameter = {
                categoryId: CategoryId,
                page: page,
                rowsPerPage: RowsPerPage,
                sort: true,
                sortField: SortField,
                sortBy: SortedBy,
                search: Search,
            };
            const response = await Axios({
                url: CommonConstants.MOL_APIURL + "/knowledge_base/GetAllArticles",
                method: "POST",
                data: InputParameter,
            });
            if (response.data && response.data.StatusMessage == "SUCCESS") {
                const articleData = response.data.Data;
                setArticleList(articleData);
                setTotalCount(response.data.TotalArticles);
                setTotalPage(response.data.TotalPages);
            }
            else {
                toast.error(response.data.Message);
            }
            SetIsLoading(false);
        }
        catch (error) {
            SetIsLoading(false);
            toast.error(error.message);
        }
    }

    const handlePageChange = (event, value) => {
        SetPage(value);
    };

    return (
        <>
            {
                IsLoading === true ? <div id="hideloding" className="loding-display">
                    <img src={loadingicon} />
                </div> : null
            }
            <div>
                <div class="mt-3 max-widthcar blog-cardbox">
                    {ArticleList.length === 0 ? (
                        <p className="text-center">No data available in table</p>
                    ) : (
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                {ArticleList.map((row) => {
                                    // Truncate text to only show limited sentences
                                    const titleSentences = row.ArticleTitle.slice(0, 50) + (row.ArticleTitle.length > 50 ? '...' : '');
                                    const sentences = row.TextField.slice(0, 150) + (row.TextField.length > 150 ? '...' : '');
                                    return (
                                        <Grid item xs={6} md={3} key={row._id}>
                                            <Card>
                                                <video
                                                    width="100%"
                                                    height="140px"
                                                    autoPlay
                                                    loop
                                                    muted
                                                    // poster="https://assets.codepen.io/6093409/river.jpg"
                                                >
                                                    <source
                                                        src={row.VideoUrl}
                                                        // src="https://assets.codepen.io/6093409/river.mp4"
                                                        type="video/mp4"
                                                    />
                                                </video>
                                                {/* Video or iframe */}
                                                {/* <iframe
                                                    width="100%"
                                                    height="140px"
                                                    src={row.VideoUrl}
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe> */}
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        {/* {row.ArticleTitle} */}
                                                        {titleSentences}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                        {/* Display truncated sentences */}
                                                        <div dangerouslySetInnerHTML={{ __html: sentences }} />
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    )}
                </div>
            </div>
            <div className="row mt-3">
                <div className="col dataTables_info">
                    <p>
                        Showing {(Page - 1) * RowsPerPage + 1} to{" "}
                        {Math.min(Page * RowsPerPage, TotalCount)} of {TotalCount} entries
                    </p>
                </div>
                <div className="col pageright">
                    <Pagination
                        count={TotalPage}
                        page={Page}
                        onChange={handlePageChange}
                        showFirstButton
                        showLastButton
                        color="primary"
                    />
                </div>
            </div>
        </>
    );
};

export default ArticleCategories;