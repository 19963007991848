import React, { useEffect } from "react";
import Axios from "axios";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LastdaysTabing from "../../../_components/user/Kpis/LastdaysTabing";

import BiggerThanStateTable from "./BiggerThanStateTable";
import BiggerThanGenericTable from "./BiggerThanGenericTable";
import FasterThanStateTable from "./FasterThanStateTable";
import FasterThanGenericTable from "./FasterThanGenericTable";
import MoreExcitingThanStateTable from "./MoreExcitingThanStateTable";
import MoreExcitingThanGenericTable from "./MoreExcitingThanGenericTable";
import MascotCheerStateTable from "./MascotCheerStateTable";
import MascotCheerGenericTable from "./MascotCheerGenericTable";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { GetClientDetails } from "../../../_helpers/Utility";
import { Tooltip } from "@mui/material";

const Fileupload = require("../../../_helpers/fileupload");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [ExportBiggerThanState, setExportBiggerThanState] = React.useState([]);
  const [ExportBiggerThanGeneric, setExportBiggerThanGeneric] = React.useState(
    []
  );
  const [ExportFasterThanState, setExportFasterThanState] = React.useState([]);
  const [ExportFasterThanGeneric, setExportFasterThanGeneric] = React.useState(
    []
  );
  const [ExportMoreExcitingThanState, setMoreExcitingThanState] =
    React.useState([]);
  const [ExportMoreExcitingThanGeneric, setMoreExcitingThanGeneric] =
    React.useState([]);
  const [MascotCheerState, setMascotCheerState] = React.useState([]);
  const [MascotCheerGeneric, setMascotCheerGeneric] = React.useState([]);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Error, SetError] = React.useState([]);
  const [EditID, SetEditID] = React.useState(0);
  const [EditData, SetEditData] = React.useState([]);
  const [Values, SetValues] = React.useState("");
  const [City, SetCity] = React.useState("");
  const [State, SetState] = React.useState("");
  const [Errors, SetErrors] = React.useState({});
  const [BtnDisabled, SetBtnDisabled] = React.useState(false);
  const [ClientName, SetClientName] = React.useState("");
  const [UpdateKey, SetUpdateKey] = React.useState(0);
  const [OpenCsv, SetOpenCsv] = React.useState(false);
  const [TotalResult, SetTotalResult] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalSuccess, SetTotalSuccess] = React.useState(0);
  const [TotalDisplayCount, SetTotalDisplayCount] = React.useState(false);

  const [Files, SetFiles] = React.useState([]);
  const [Reset, SetReset] = React.useState(false);
  const [filename, Setfilename] = React.useState("");
  const [CsvData, SetCsvData] = React.useState([]);
  const [DropBoxData, SetDropBoxData] = React.useState([]);
  const [MapCsvs, SetMapCsvs] = React.useState(false);

  const [FieldID, SetFieldID] = React.useState(0);
  const [SaveImportBtn, SetSaveImportBtn] = React.useState(false);
  const [ValuesDisabled, SetValuesDisabled] = React.useState(false);
  const [CityDisabled, SetCityDisabled] = React.useState(false);
  const [StateDisabled, SetStateDisabled] = React.useState(false);
  const [ExtraFlag, SetExtraFlag] = React.useState(false);
  const [PutImportStateMsg, SetPutImportStateMsg] = React.useState("");
  const [PutImportStateDiv, SetPutImportStateDiv] = React.useState("");

  const [OpenDeleteCsv, SetOpenDeleteCsv] = React.useState(false);
  const [MapBulkCsvs, SetMapBulkCsvs] = React.useState(false);
  const [PutDeleteStateMsg, SetPutDeleteStateMsg] = React.useState("");
  const [PutDeleteStateDiv, SetPutDeleteStateDiv] = React.useState("");
  const [trigger, setTrigger] = React.useState(false);
  const [CUserID,SetCUserID] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue);
    
    if(TotalDisplayCount){

      SetTotalDisplayCount(false);
    }

  };
  useEffect(() => {
    document.title = "Smart Variables | SalesHive";
    var ResearchPageUserInfo = GetUserDetails();
    if (ResearchPageUserInfo != null) {
      SetClientID(ResearchPageUserInfo.ClientID);
      SetUserID(ResearchPageUserInfo.ParentUserID);
      SetCUserID(ResearchPageUserInfo.ChildUserID);
    }
    GetClientDetails(ResearchPageUserInfo.ClientID).then((result) => {
      let ClientNames = result[0]?.Name;
      filename;
      SetClientName(ClientNames);
      filename;
    });
    const exportFunctions = {
      0: ExportCSVBiggerThanState,
      1: ExportCSVBiggerThanGeneric,
      2: ExportCSVFasterThanState,
      3: ExportCSVFasterThanGeneric,
      4: ExportCSVMoreExcitingThanState,
      5: ExportCSVMoreExcitingThanGeneric,
      6: ExportCSVMascotCheerState,
      7: ExportCSVMascotCheerGeneric
    };
    
    if (exportFunctions.hasOwnProperty(value)) {
      exportFunctions[value](ResearchPageUserInfo.ParentUserID);
    }
    
    // ExportCSVBiggerThanState(ResearchPageUserInfo.ParentUserID);
    // ExportCSVBiggerThanGeneric(ResearchPageUserInfo.ParentUserID);
    // ExportCSVFasterThanState(ResearchPageUserInfo.ParentUserID);
    // ExportCSVFasterThanGeneric(ResearchPageUserInfo.ParentUserID);
    // ExportCSVMoreExcitingThanState(ResearchPageUserInfo.ParentUserID);
    // ExportCSVMoreExcitingThanGeneric(ResearchPageUserInfo.ParentUserID);
    // ExportCSVMascotCheerState(ResearchPageUserInfo.ParentUserID);
    // ExportCSVMascotCheerGeneric(ResearchPageUserInfo.ParentUserID);
    if (value == 0) {
      CancelAdd();
    } else if (value == 1) {
      CancelBiggerThanGenericAdd();
      CloseAllImport();
    } else if (value == 2) {
      CancelFasterThanState();
      CloseAllImport();
    } else if (value == 3) {
      CancelFasterThanGeneric();
      CloseAllImport();
    } else if (value == 4) {
      CancelMoreExcitingThanState();
      CloseAllImport();
    } else if (value == 5) {
      CancelMoreExcitingThanGeneric();
      CloseAllImport();
    } else if (value == 6) {
      CancelMascotCheerState();
      CloseAllImport();
    } else if (value == 7) {
      CancelMascotCheerGeneric();
      CloseAllImport();
    }
  }, [ClientID, Role, value,trigger]);

  //Clear state input data and error msg
  const ClearStateData = () => {
    SetValues("");
    SetCity("");
    SetState("");
    SetErrors({});
    SetBtnDisabled(false);
  };

  //State form validation and Handler
  const StateFormValidation = () => {
    const newErrors = {};

    if (Values.trim() === "") {
      newErrors.Value = "Please enter value";
    }

    if (City.trim() === "") {
      newErrors.City = "Please enter city";
    }

    if (State.trim() === "") {
      newErrors.State = "Please enter state";
    }

    SetErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const BiggerThanStateHandleBlur = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    const newErrors = { ...Errors };

    if (fieldName === "Value" && fieldValue.trim() === "") {
      newErrors.Value = "Please enter value";
    } else if (fieldName === "City" && fieldValue.trim() === "") {
      newErrors.City = "Please enter city";
    } else if (fieldName === "State" && fieldValue.trim() === "") {
      newErrors.State = "Please enter state";
    } else {
      delete newErrors[fieldName];
    }

    SetErrors(newErrors);
  };

  //Generic form validation and Handler
  const GenericFromValidation = () => {
    const newErrors = {};
    if (Values.trim() === "") {
      newErrors.Value = "Please enter value";
    }
    SetErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const GenericHandlerBlur = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    const newErrors = { ...Errors };
    if (fieldName === "Value" && fieldValue.trim() === "") {
      newErrors.Value = "Please enter value";
    } else {
      delete newErrors[fieldName];
    }
    SetErrors(newErrors);
  };

  //Export csv message
  const ExportCsvMsg = (tab) => {
    SetTotalDisplayCount(false)
    if (tab === 0) {
      toast.success(
        <div className="toastsize">
          BiggerThan - State
          <br />
          Data exported successfully.
        </div>
      );
    } else if (tab === 1) {
      toast.success(
        <div className="toastsize">
          BiggerThan - Generic
          <br />
          Data exported successfully.
        </div>
      );
    } else if (tab === 2) {
      toast.success(
        <div className="toastsize">
          FasterThan - State
          <br />
          Data exported successfully.
        </div>
      );
    } else if (tab === 3) {
      toast.success(
        <div className="toastsize">
          FasterThan - Generic
          <br />
          Data exported successfully.
        </div>
      );
    } else if (tab === 4) {
      toast.success(
        <div className="toastsize">
          MoreExcitingThan - State
          <br />
          Data exported successfully.
        </div>
      );
    } else if (tab === 5) {
      toast.success(
        <div className="toastsize">
          MoreExcitingThan - Generic
          <br />
          Data exported successfully.
        </div>
      );
    } else if (tab === 6) {
      toast.success(
        <div className="toastsize">
          MascotCheer - State
          <br />
          Data exported successfully.
        </div>
      );
    } else if (tab === 7) {
      toast.success(
        <div className="toastsize">
          MascotCheer - Generic
          <br />
          Data exported successfully.
        </div>
      );
    }
  };

  // For Bigger Than -state Tab
  const ShowAdd = () => {
    document.getElementById("AddBox").style.display = "block";
    ClearStateData();
    canclecsv();
    SetTotalDisplayCount(false)
    SetEditID(0)
  };
  const CancelAdd = () => {
    document.getElementById("AddBox").style.display = "none";
  };
  const ExportCSVBiggerThanState = (UID) => {
    const InputParameter = {
      TabName: "BiggerThan - State",
      FeatureFieldID: "1",
      UserID: UID,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableExport",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      setExportBiggerThanState(Result.data.Data);
    });
  };
  const SaveState = () => {
    SetBtnDisabled(true);
    if (StateFormValidation()) {
      let ValueField = document.getElementById("value").value;
      let StateField = document.getElementById("state").value;
      let CityField = document.getElementById("city").value;

      var InputParameter = {
        ClientID: ClientID,
        FeatureValue: ValueField,
        State: StateField,
        City: CityField,
        UserID: UserID,
        CreatedBy: CUserID,
        FeatureFieldID: "1",
        CreatedDate: new Date(),
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              BiggerThan - State
              <br />
              BiggerThan - State added successfully
            </div>
          );
          document.getElementById("value").value = "";
          document.getElementById("state").value = "";
          document.getElementById("city").value = "";
          SetUpdateKey(UpdateKey + 1);
          CancelAdd();
          UpdataChildComponent(true)

        }else if(res.data.StatusMessage == "DUPLICATE"){
          toast.error(
            <div className="toastsize">
              BiggerThan - State
              <br />
              BiggerThan - State Variable already exists.
            </div>
          );
          SetBtnDisabled(false);
        } else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);
        }
      });
    } else {
      SetBtnDisabled(false);
    }
  };
  const EditBtn = (ID) => {
    SetEditID(ID);
    document.getElementById("AddBox").style.display = "block";
    ClearStateData();
    canclecsv();
    SetTotalDisplayCount(false)
    // ShowAdd();
    var InputParameter = {
      ID: ID,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableGetByID",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      SetValues(
        Result.data.Data.FeatureValue === null
          ? ""
          : Result.data.Data.FeatureValue
      );
      SetState(Result.data.Data.State === null ? "" : Result.data.Data.State);
      SetCity(Result.data.Data.City === null ? "" : Result.data.Data.City);
      SetEditData(Result);
    });
  };
  const UpdateDetails = () => {
    SetBtnDisabled(true);
    
    if (StateFormValidation()) {
      let ValueField = document.getElementById("value").value;
      let StateField = document.getElementById("state").value;
      let CityField = document.getElementById("city").value;

      var InputParameter = {
        ID: EditID,
        FeatureValue: ValueField,
        State: StateField,
        City: CityField,
        LastUpdatedBy: CUserID,
        LastUpdatedDate: new Date(),
        FeatureFieldID: "1",
        UserID:UserID,
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableUpdate",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              BiggerThan - State
              <br />
              BiggerThan - State updated successfully
            </div>
          );
          document.getElementById("value").value = "";
          document.getElementById("state").value = "";
          document.getElementById("city").value = "";
          CancelAdd();
          SetUpdateKey(UpdateKey + 1);
          SetEditID(0);
          UpdataChildComponent(true)
        }else if(res.data.StatusMessage == "DUPLICATE"){
          toast.error(
            <div className="toastsize">
              BiggerThan - State
              <br />
              BiggerThan - State value already exist
            </div>
          );
          SetBtnDisabled(false);
        } else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);
        }
      });
    } else {
      SetBtnDisabled(false);
    }
  };
  //import csv
  const handleOnError = (err) => {};
  //handleremove
  const handleOnRemoveFile = (data) => {};
  //Reset
  const Resetcsv = () => {
    SetReset(!Reset);
  };
  //handle
  const handleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;
    SetFiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      SetReset(true);
      Resetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      Setfilename(csvfile);
      SetCsvData(data);
      SetDropBoxData(data[0].data);
    }
  };
  //import
  const importBiggerThanStatecsv = (FID) => {
    SetFieldID(FID);
    SetErrors({});
    SetReset(!Reset);
    SetOpenCsv(true);
    SetMapCsvs(false);
    SetOpenDeleteCsv(false);
    SetMapBulkCsvs(false);
    SetTotalDisplayCount(false);
    CloseAddMethods();
    if (FID == 1) {
      SetPutImportStateMsg("Import BiggerThan - State");
      SetPutImportStateDiv(" biggerthan - state");
    } else if (FID == 3) {
      SetPutImportStateMsg("Import FasterThan - State");
      SetPutImportStateDiv(" fasterthan - state");
    } else if (FID == 5) {
      SetPutImportStateMsg("Import MoreExcitingThan - State");
      SetPutImportStateDiv(" moreexcitingthan - state");
    } else if (FID == 7) {
      SetPutImportStateMsg("Import MascotCheer - State");
      SetPutImportStateDiv(" mascotcheer - state");
    } else if (FID == 2) {
      SetPutImportStateMsg("Import BiggerThan - Generic");
      SetPutImportStateDiv(" biggerthan - generic");
    } else if (FID == 4) {
      SetPutImportStateMsg("Import FasterThan - Generic");
      SetPutImportStateDiv(" fasterthan - generic");
    } else if (FID == 6) {
      SetPutImportStateMsg("Import MoreExcitingThan - Generic");
      SetPutImportStateDiv(" moreexcitingthan - generic");
    } else if (FID == 8) {
      SetPutImportStateMsg("Import MascotCheer - Generic");
      SetPutImportStateDiv(" mascotcheer - generic");
    }
  };

  const [selectedCity, setSelectedCity] = React.useState('not mapped');
  const [selectedState, setSelectedState] = React.useState('not mapped');
  const [selectedValue, setSelectedValue] = React.useState('not mapped');
  const onHandleChange = (event) => {
    const { value, id } = event.target;
    const valueindex=DropBoxData.indexOf(value)
    if (id === 'City') {
      setSelectedCity(value);
      SetCity(valueindex)
    } else if (id === 'State') {
      setSelectedState(value);
      SetState(valueindex)
    }else {
      setSelectedValue(value);
      SetValues(valueindex)
    }
  };
  
  
  
  // map csv
  const ImportDataMapCsv = () => {
    if (CsvData.length != 0) {
      DropBoxData.map((dddd, index) => {});
      SetMapCsvs(true);
      SetSaveImportBtn(false);
      const cityindex = DropBoxData.indexOf("City")
      const stateindex = DropBoxData.indexOf("State")
   

      setSelectedCity(DropBoxData.includes("City") ? "City" : "not mapped"); // Set default to "City" if it exists in columnHeaders
      setSelectedState(DropBoxData.includes("State") ? "State" : "not mapped"); // Set default to "State" if it exists in columnHeaders

      SetCity(cityindex)
      SetState(stateindex)
      Resetcsv();
      SetValuesDisabled(false);
      SetCityDisabled(false);
      SetStateDisabled(false);
    } else {
      if (ExtraFlag === true) {
        SetMapCsvs(false);
        SetTotalDisplayCount(false);
      } else {
        SetMapCsvs(false);
        SetTotalDisplayCount(false);
        if (FieldID == 1) {
          toast.error(
            <div className="toastsize">
              BiggerThan - State <br /> Please select file
            </div>
          );
        } else if (FieldID == 3) {
          toast.error(
            <div className="toastsize">
              FasterThan - State <br /> Please select file
            </div>
          );
        } else if (FieldID == 5) {
          toast.error(
            <div className="toastsize">
              MoreExcitingThan - State <br /> Please select file
            </div>
          );
        } else if (FieldID == 7) {
          toast.error(
            <div className="toastsize">
              MascotCheer - State <br /> Please select file
            </div>
          );
        } else if (FieldID == 2) {
          toast.error(
            <div className="toastsize">
              BiggerThan - Generic <br /> Please select file
            </div>
          );
        } else if (FieldID == 4) {
          toast.error(
            <div className="toastsize">
              FasterThan - Generic <br /> Please select file
            </div>
          );
        } else if (FieldID == 6) {
          toast.error(
            <div className="toastsize">
              MoreExcitingThan - Generic <br /> Please select file
            </div>
          );
        } else if (FieldID == 8) {
          toast.error(
            <div className="toastsize">
              MascotCheer - Generic <br /> Please select file
            </div>
          );
        }
      }
    }
  };
  //Cancle csv
  const canclecsv = () => {
    SetOpenCsv(false);
    SetMapCsvs(false);
    SetFieldID(0);
    SetExtraFlag(false);
    SetCsvData([]);
    SetTotalDisplayCount(false);
    SetOpenDeleteCsv(false);
    SetMapBulkCsvs(false);
    setSelectedCity("not mapped");
    setSelectedState("not mapped");
    setSelectedValue("not mapped")
  };
  //mapcsv
  const Savecsv = () => {
    SetSaveImportBtn(true);
    if (FieldID == 0) {
      canclecsv();
      SetSaveImportBtn(false);
    } else {
      if (CsvData.length != 0) {
        var values = document.getElementById("Values").value;
        
        if (FieldID == 1 || FieldID == 3 || FieldID == 5 || FieldID == 7) {
          var city = document.getElementById("City").value;
          var state = document.getElementById("State").value;
          // city=City
          // state=State
          // values=Values
          
          if (
            values != "not mapped" &&
            city != "not mapped" &&
            state != "not mapped" && Values !== "" && City !==-1 && State !==-1
          ) {
            Fileupload.getBase64(Files, (result) => {
              var rs = result;
              var splirs = rs.split("base64,");
              var _base64 = splirs[1];
              var myresult = {
                fields: "Coverimg",
                Path: "./Content/UploadedFiles/SmartVariable",
                File: _base64,
                ImageName: filename,
                FileData: CsvData,
                ClientID: ClientID,
                UserID: UserID,
                CreatedDate: new Date(),
                FeatureFieldID: FieldID,
                Value: Values,
                City: City,
                State: State,
              };
              console.log(myresult,"myresult")
              Axios({
                url:
                  CommonConstants.MOL_APIURL +
                  "/smart_variable/SmartVariableImportCSV",
                method: "POST",
                data: myresult,
              }).then((Res) => {
                setSelectedCity('not mapped')
                setSelectedState('not mapped')
                setSelectedValue('not mapped')
                SetMapCsvs(false);
                SetExtraFlag(true);
                SetCsvData([]);
                SetSaveImportBtn(false);
                SetValuesDisabled(true);
                SetCityDisabled(true);
                SetStateDisabled(true);
                SetTotalDisplayCount(true);
                SetTotalResult(Res.data.Total);
                SetTotalDuplicate(Res.data.TotalDuplication);
                SetTotalError(Res.data.TotalError);
                SetTotalSuccess(Res.data.TotalSuccess);
                SetUpdateKey(UpdateKey + 1);

                if (Res.data.StatusMessage == "SUCCESS") {
                  ExportCSVBiggerThanState(UserID)
             
                  if (FieldID == 1) {
              
                    toast.success(
                      <div className="toastsize">
                        BiggerThan - State <br />
                        BiggerThan - State added successfully.
                      </div>
                    );
                    setSelectedCity("not mapped");
    setSelectedState("not mapped");
    setSelectedValue("not mapped")
                  } else if (FieldID == 3) {
           
                    toast.success(
                      <div className="toastsize">
                        FasterThan - State <br />
                        FasterThan - State added successfully.
                      </div>
                    );
                    setSelectedCity("not mapped");
    setSelectedState("not mapped");
    setSelectedValue("not mapped")
                  } else if (FieldID == 5) {
                   
                    toast.success(
                      <div className="toastsize">
                        MoreExcitingThan - State <br />
                        MoreExcitingThan - State added successfully.
                      </div>
                    );
                    setSelectedCity("not mapped");
    setSelectedState("not mapped");
    setSelectedValue("not mapped")
                  } else if (FieldID == 7) {
               
                    toast.success(
                      <div className="toastsize">
                        MascotCheer - State <br />
                        MascotCheer - State added successfully.
                      </div>
                    );
                    setSelectedCity("not mapped");
    setSelectedState("not mapped");
    setSelectedValue("not mapped")
                  }
                } else {
                  toast.error(Res.data.Message);
                  SetSaveImportBtn(false);
                  setSelectedCity("not mapped");
    setSelectedState("not mapped");
    setSelectedValue("not mapped")
                }
              });
            });
          } else {
            if (FieldID == 1) {
              if (values == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    BiggerThan - State <br /> Value is required!
                  </div>
                );
              } else if (city == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    BiggerThan - State <br /> City is required!
                  </div>
                );
              } else if (state == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    BiggerThan - State <br /> State is required!
                  </div>
                );
              }
            } else if (FieldID == 3) {
              if (values == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    FasterThan - State <br /> Value is required!
                  </div>
                );
              } else if (city == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    FasterThan - State <br /> City is required!
                  </div>
                );
              } else if (state == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    FasterThan - State <br /> State is required!
                  </div>
                );
              }
            } else if (FieldID == 5) {
              if (values == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    MoreExcitingThan - State <br /> Value is required!
                  </div>
                );
              } else if (city == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    MoreExcitingThan - State <br /> City is required!
                  </div>
                );
              } else if (state == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    MoreExcitingThan - State <br /> State is required!
                  </div>
                );
              }
            } else if (FieldID == 7) {
              if (values == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    MascotCheer - State <br /> Value is required!
                  </div>
                );
              } else if (city == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    MascotCheer - State <br /> City is required!
                  </div>
                );
              } else if (state == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    MascotCheer - State <br /> State is required!
                  </div>
                );
              }
            }
          }
        } else {
          
          if (values != "not mapped") {
            Fileupload.getBase64(Files, (result) => {
              var rs = result;
              var splirs = rs.split("base64,");
              var _base64 = splirs[1];
              var myresult = {
                fields: "Coverimg",
                Path: "./Content/UploadedFiles/SmartVariable",
                File: _base64,
                ImageName: filename,
                FileData: CsvData,
                ClientID: ClientID,
                UserID: UserID,
                CreatedDate: new Date(),
                FeatureFieldID: FieldID,
                Value: Values,
                City: "",
                State: "",
              };
              Axios({
                url:
                  CommonConstants.MOL_APIURL +
                  "/smart_variable/SmartVariableImportCSV",
                method: "POST",
                data: myresult,
              }).then((Res) => {
                setTrigger(true)
                if (Res.data.StatusMessage == "SUCCESS") {
                  ExportCSVBiggerThanState(UserID)
                  if (FieldID == 1) {
                    toast.success(
                      <div className="toastsize">
                        BiggerThan - State <br />
                        BiggerThan - State added successfully.
                      </div>
                    );
                  } else if (FieldID == 3) {
                    toast.success(
                      <div className="toastsize">
                        FasterThan - State <br />
                        FasterThan - State added successfully.
                      </div>
                    );
                  } else if (FieldID == 5) {
                    toast.success(
                      <div className="toastsize">
                        MoreExcitingThan - State <br />
                        MoreExcitingThan - State added successfully.
                      </div>
                    );
                  } else if (FieldID == 7) {
                    toast.success(
                      <div className="toastsize">
                        MascotCheer - State <br />
                        MascotCheer - State added successfully.
                      </div>
                    );
                  }
                  SetExtraFlag(true);
                  SetCsvData([]);
                  SetSaveImportBtn(true);
                  SetValuesDisabled(true);
                  SetCityDisabled(true);
                  SetStateDisabled(true);
                  SetTotalDisplayCount(true);
                  SetTotalResult(Res.data.Total);
                  SetTotalDuplicate(Res.data.TotalDuplication);
                  SetTotalError(Res.data.TotalError);
                  SetTotalSuccess(Res.data.TotalSuccess);
                  SetUpdateKey(UpdateKey + 1);
       
                  // SetHotReload(!HotReload)
                } else {
                  toast.error(Res.data.Message);
                  SetSaveImportBtn(false);
                }
              });
            });
          } else {
            if (FieldID == 2) {
              toast.error(
                <div className="toastsize">
                  BiggerThan - Generic <br /> Value is required!
                </div>
              );
            } else if (FieldID == 4) {
              toast.error(
                <div className="toastsize">
                  FasterThan - Generic <br /> Value is required!
                </div>
              );
            } else if (FieldID == 6) {
              toast.error(
                <div className="toastsize">
                  MoreExcitingThan - Generic <br /> Value is required!
                </div>
              );
            } else if (FieldID == 8) {
              toast.error(
                <div className="toastsize">
                  MascotCheer - Generic <br /> Value is required!
                </div>
              );
            }
          }
        }
      } else {
      }
    }
    SetSaveImportBtn(false);
  };

  //CloseAllImport
  const CloseAllImport = () => {
    SetFieldID(0);
    SetErrors({});
    SetReset(!Reset);
    SetOpenCsv(false);
    SetMapCsvs(false);
    SetTotalDisplayCount(false);
    SetPutImportStateMsg("");
    SetPutImportStateDiv("");
    SetSaveImportBtn(false);
    SetValuesDisabled(false);
    SetCityDisabled(false);
    SetStateDisabled(false);
    SetExtraFlag(false);
    SetOpenDeleteCsv(false);
    SetMapBulkCsvs(false);
    SetPutDeleteStateMsg("");
    SetPutDeleteStateDiv("");
  };

  //Bulk Delete
  const importStateDeletecsv = (FID) => {
    SetFieldID(FID);
    SetErrors({});
    SetReset(!Reset);
    SetOpenDeleteCsv(true);
    SetMapBulkCsvs(false);
    SetOpenCsv(false);
    SetMapCsvs(false);
    SetTotalDisplayCount(false)
    CloseAddMethods();
    if (FID == 1) {
      SetPutDeleteStateMsg("Bulk Delete BiggerThan - State");
      SetPutDeleteStateDiv(" biggerthan - state");
    } else if (FID == 3) {
      SetPutDeleteStateMsg("Bulk Delete FasterThan - State");
      SetPutDeleteStateDiv(" fasterthan - state");
    } else if (FID == 5) {
      SetPutDeleteStateMsg("Bulk Delete MoreExcitingThan - State");
      SetPutDeleteStateDiv(" moreexcitingthan - state");
    } else if (FID == 7) {
      SetPutDeleteStateMsg("Bulk Delete MascotCheer - State");
      SetPutDeleteStateDiv(" mascotcheer - state");
    } else if (FID == 2) {
      SetPutDeleteStateMsg("Bulk Delete BiggerThan - Generic");
      SetPutDeleteStateDiv(" biggerthan - generic");
    } else if (FID == 4) {
      SetPutDeleteStateMsg("Bulk Delete FasterThan - Generic");
      SetPutDeleteStateDiv(" fasterthan - generic");
    } else if (FID == 6) {
      SetPutDeleteStateMsg("Bulk Delete MoreExcitingThan - Generic");
      SetPutDeleteStateDiv(" moreexcitingthan - generic");
    } else if (FID == 8) {
      SetPutDeleteStateMsg("Bulk Delete MascotCheer - Generic");
      SetPutDeleteStateDiv(" mascotcheer - generic");
    }
  };

  //Bulk delete import upload map
  const DeleteDataMapCsv = () => {
    if (CsvData.length != 0) {
      DropBoxData.map((dddd, index) => {});

      const cityindex = DropBoxData.indexOf("City")
      const stateindex = DropBoxData.indexOf("State")
   

      setSelectedCity(DropBoxData.includes("City") ? "City" : "not mapped"); // Set default to "City" if it exists in columnHeaders
      setSelectedState(DropBoxData.includes("State") ? "State" : "not mapped"); // Set default to "State" if it exists in columnHeaders

      SetCity(cityindex)
      SetState(stateindex)
      SetMapBulkCsvs(true);
      Resetcsv();
    } else {
      SetMapBulkCsvs(false);
      if (FieldID == 1) {
        toast.error(
          <div className="toastsize">
            BiggerThan - State <br /> Please select file
          </div>
        );
      } else if (FieldID == 3) {
        toast.error(
          <div className="toastsize">
            FasterThan - State <br /> Please select file
          </div>
        );
      } else if (FieldID == 5) {
        toast.error(
          <div className="toastsize">
            MoreExcitingThan - State <br /> Please select file
          </div>
        );
      } else if (FieldID == 7) {
        toast.error(
          <div className="toastsize">
            MascotCheer - State <br /> Please select file
          </div>
        );
      } else if (FieldID == 2) {
        toast.error(
          <div className="toastsize">
            BiggerThan - Generic <br /> Please select file
          </div>
        );
      } else if (FieldID == 4) {
        toast.error(
          <div className="toastsize">
            FasterThan - Generic <br /> Please select file
          </div>
        );
      } else if (FieldID == 6) {
        toast.error(
          <div className="toastsize">
            MoreExcitingThan - Generic <br /> Please select file
          </div>
        );
      } else if (FieldID == 8) {
        toast.error(
          <div className="toastsize">
            MascotCheer - Generic <br /> Please select file
          </div>
        );
      }
    }
  };

  //Save Bulk Delete csv
  const DeleteSavecsv = () => {
    // SetOpenDeleteCsv(true);
    SetSaveImportBtn(true);
    if (FieldID == 0) {
      canclecsv();
      SetOpenDeleteCsv(false);
    } else {
      if (CsvData.length != 0) {
        var values = document.getElementById("Values").value;

        if (FieldID == 1 || FieldID == 3 || FieldID == 5 || FieldID == 7) {
          var city = document.getElementById("City").value;
          var state = document.getElementById("State").value;
          if (
            values != "not mapped" &&
            city != "not mapped" &&
            state != "not mapped"
          ) {
            Fileupload.getBase64(Files, (result) => {
              var rs = result;
              var splirs = rs.split("base64,");
              var _base64 = splirs[1];
              var myresult = {
                fields: "Coverimg",
                Path: "./Content/UploadedFiles/SmartVariable",
                File: _base64,
                ImageName: filename,
                FileData: CsvData,
                ClientID: ClientID,
                UserID: UserID,
                CreatedDate: new Date(),
                FeatureFieldID: FieldID,
                Value: Values,
                City: City,
                State: State,
              };
              console.log(myresult,"myresult")
              Axios({
                url:
                  CommonConstants.MOL_APIURL +
                  "/smart_variable/SmartVariableBulkDel",
                method: "POST",
                data: myresult,
              }).then((Res) => {
                SetSaveImportBtn(false);
                filename;
                if (Res.data.StatusMessage == "SUCCESS") {
           
                  SetUpdateKey(UpdateKey + 1);
                
                  if (FieldID == 1) {
                    
                    toast.success(
                      <div className="toastsize">
                        BiggerThan - State <br />
                        BiggerThan - State deleted successfully.
                      </div>
                    );
                    setSelectedCity("not mapped");
                    setSelectedState("not mapped");
                    setSelectedValue("not mapped")
                  } else if (FieldID == 3) {
               
                    toast.success(
                      <div className="toastsize">
                        FasterThan - State <br />
                        FasterThan - State deleted successfully.
                      </div>
                    );
                    setSelectedCity("not mapped");
                    setSelectedState("not mapped");
                    setSelectedValue("not mapped")
                  } else if (FieldID == 5) {
        
                    toast.success(
                      <div className="toastsize">
                        MoreExcitingThan - State <br />
                        MoreExcitingThan - State deleted successfully.
                      </div>
                    );
                    setSelectedCity("not mapped");
                    setSelectedState("not mapped");
                    setSelectedValue("not mapped")
                  } else if (FieldID == 7) {
           
                    toast.success(
                      <div className="toastsize">
                        MascotCheer - State <br />
                        MascotCheer - State deleted successfully.
                      </div>
                    );
                    setSelectedCity("not mapped");
                    setSelectedState("not mapped");
                    setSelectedValue("not mapped")
                  }
                  SetCsvData([]);
                  canclecsv();
            
                  // SetListUpdated(!ListUpdate)
                  // SetHotReload(!HotReload)
                } else {
                  toast.error(Res.data.Message);
                  setSelectedCity("not mapped");
                  setSelectedState("not mapped");
                  setSelectedValue("not mapped")
                }
              });
            });
          } else {
            if (FieldID == 1) {
              if (values == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    BiggerThan - State <br /> Value is required!
                  </div>
                );
           
              } else if (city == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    BiggerThan - State <br /> City is required!
                  </div>
                );

              } else if (state == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    BiggerThan - State <br /> State is required!
                  </div>
                );

              }
            } else if (FieldID == 3) {
              if (values == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    FasterThan - State <br /> Value is required!
                  </div>
                );
            
              } else if (city == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    FasterThan - State <br /> City is required!
                  </div>
                );
        
              } else if (state == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    FasterThan - State <br /> State is required!
                  </div>
                );
   
              }
            } else if (FieldID == 5) {
              if (values == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    MoreExcitingThan - State <br /> Value is required!
                  </div>
                );
              } else if (city == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    MoreExcitingThan - State <br /> City is required!
                  </div>
                );
            
              } else if (state == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    MoreExcitingThan - State <br /> State is required!
                  </div>
                );
              }
            } else if (FieldID == 7) {
              if (values == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    MascotCheer - State <br /> Value is required!
                  </div>
                );
              } else if (city == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    MascotCheer - State <br /> City is required!
                  </div>
                );
              } else if (state == "not mapped") {
                toast.error(
                  <div className="toastsize">
                    MascotCheer - State <br /> State is required!
                  </div>
                );
              }
            }
          }
        } else {
          if (values != "not mapped") {
            Fileupload.getBase64(Files, (result) => {
              var rs = result;
              var splirs = rs.split("base64,");
              var _base64 = splirs[1];
              var myresult = {
                fields: "Coverimg",
                Path: "./Content/UploadedFiles/SmartVariable",
                File: _base64,
                ImageName: filename,
                FileData: CsvData,
                ClientID: ClientID,
                UserID: UserID,
                CreatedDate: new Date(),
                FeatureFieldID: FieldID,
                Value: Values,
                City: "",
                State: "",
              };
              Axios({
                url:
                  CommonConstants.MOL_APIURL +
                  "/smart_variable/SmartVariableBulkDel",
                method: "POST",
                data: myresult,
              }).then((Res) => {
                if (Res.data.StatusMessage == "SUCCESS") {
          
                  SetUpdateKey(UpdateKey + 1);
                  
                  if (FieldID == 1) {
   
                    toast.success(
                      <div className="toastsize">
                        BiggerThan - State <br />
                        BiggerThan - State deleted successfully.
                      </div>
                    );
                    setSelectedCity("not mapped");
                    setSelectedState("not mapped");
                    setSelectedValue("not mapped")
                  } else if (FieldID == 3) {
                    toast.success(
                      <div className="toastsize">
                        FasterThan - State <br />
                        FasterThan - State deleted successfully.
                      </div>
                    );
                    setSelectedCity("not mapped");
                    setSelectedState("not mapped");
                    setSelectedValue("not mapped")
                  } else if (FieldID == 5) {
                    toast.success(
                      <div className="toastsize">
                        MoreExcitingThan - State <br />
                        MoreExcitingThan - State deleted successfully.
                      </div>
                    );
                    setSelectedCity("not mapped");
                    setSelectedState("not mapped");
                    setSelectedValue("not mapped")
                  } else if (FieldID == 7) {
                    toast.success(
                      <div className="toastsize">
                        MascotCheer - State <br />
                        MascotCheer - State deleted successfully.
                      </div>
                    );
                    setSelectedCity("not mapped");
                    setSelectedState("not mapped");
                    setSelectedValue("not mapped")
                  }
                  SetCsvData([]);
                  canclecsv();
                  // SetListUpdated(!ListUpdate)
                  // SetHotReload(!HotReload)
                } else {
                  toast.error(Res.data.Message);
                  setSelectedCity("not mapped");
                  setSelectedState("not mapped");
                  setSelectedValue("not mapped")
                }
              });
            });
          } else {
            if (FieldID == 2) {
              toast.error(
                <div className="toastsize">
                  BiggerThan - Generic <br /> Value is required!
                </div>
              );
            } else if (FieldID == 4) {
              toast.error(
                <div className="toastsize">
                  FasterThan - Generic <br /> Value is required!
                </div>
              );
            } else if (FieldID == 6) {
              toast.error(
                <div className="toastsize">
                  MoreExcitingThan - Generic <br /> Value is required!
                </div>
              );
            } else if (FieldID == 8) {
              toast.error(
                <div className="toastsize">
                  MascotCheer - Generic <br /> Value is required!
                </div>
              );
            }
          }
        }
      } else {
      }
    }
    SetSaveImportBtn(false);
  };

  //close Add methods
  const CloseAddMethods = () => {
    if (value == 0) {
      CancelAdd();
    } else if (value == 1) {
      CancelBiggerThanGenericAdd();
      //  CloseAllImport();
    } else if (value == 2) {
      CancelFasterThanState();
      //  CloseAllImport();
    } else if (value == 3) {
      CancelFasterThanGeneric();
      //  CloseAllImport();
    } else if (value == 4) {
      CancelMoreExcitingThanState();
      //  CloseAllImport();
    } else if (value == 5) {
      CancelMoreExcitingThanGeneric();
      //  CloseAllImport();
    } else if (value == 6) {
      CancelMascotCheerState();
      //  CloseAllImport();
    } else if (value == 7) {
      CancelMascotCheerGeneric();
      //  CloseAllImport();
    }
  };

  //For BiggerThan - Generic
  const BiggerThanGenericAdd = () => {
    ClearStateData();
    document.getElementById("AddBox1").style.display = "block";
    canclecsv();
    SetEditID(0)
  };
  const CancelBiggerThanGenericAdd = () => {
    document.getElementById("AddBox1").style.display = "none";
  };
  const ExportCSVBiggerThanGeneric = (UID) => {
    const InputParameter = {
      TabName: "BiggerThan - Generic",
      FeatureFieldID: "2",
      UserID: UID,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableExport",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      setExportBiggerThanGeneric(Result.data.Data);
    });
  };
  const SaveGeneric = () => {
    SetBtnDisabled(true);
    if (GenericFromValidation()) {
      let ValueField = document.getElementById("value").value;

      var InputParameter = {
        ClientID: ClientID,
        FeatureValue: ValueField,
        UserID: UserID,
        CreatedBy: CUserID,
        CreatedDate: new Date(),
        FeatureFieldID: "2",
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              {" "}
              BiggerThan - Generic <br />
              BiggerThan - Generic added successfully.
            </div>
          );
          CancelBiggerThanGenericAdd();
          document.getElementById("value").value = "";
          SetUpdateKey(UpdateKey + 1);
        } else if(res.data.StatusMessage == "DUPLICATE"){
          toast.error(
            <div className="toastsize">
            BiggerThan - Generic
              <br />
              BiggerThan - Generic Variable already exists.
            </div>
          );
          SetBtnDisabled(false);
   
        }else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);
        }
      });
    } else {
      SetBtnDisabled(false);
    }
  };
  const EditBtnBiggerThanGeneric = (ID) => {
    SetEditID(ID);
    // BiggerThanGenericAdd();
    ClearStateData();
    document.getElementById("AddBox1").style.display = "block";
    canclecsv();
    var InputParameter = {
      ID: ID,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableGetByID",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      SetValues(Result.data.Data.FeatureValue);
    });
  };
  const UpdateBiggerThanGenericDetails = () => {
    SetBtnDisabled(true);
    if (GenericFromValidation()) {
      let ValueField = document.getElementById("value").value;

      var InputParameter = {
        ID:EditID,
        FeatureValue: ValueField,
        State: null,
        City: null,
        UserID:UserID,
        LastUpdatedBy: CUserID,
        LastUpdatedDate: new Date(),
        FeatureFieldID: "2",
      };  

      Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableUpdate",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              BiggerThan - Generic
              <br />
              BiggerThan - Generic updated successfully
            </div>
          );
          document.getElementById("value").value = "";
          SetUpdateKey(UpdateKey + 1);

          CancelBiggerThanGenericAdd();
          SetEditID(0);
        }else if(res.data.StatusMessage == "DUPLICATE"){
          toast.error(
            <div className="toastsize">
            BiggerThan - Generic
            <br />
            BiggerThan - Generic value already exist
            </div>
          );
          SetBtnDisabled(false);
        } else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);
        }
      });
    } else {
      SetBtnDisabled(false);
    }
  };

  // For FasterThan - State
  const ShowFasterThanState = () => {
    document.getElementById("AddBox2").style.display = "block";
    ClearStateData();
    canclecsv();
    SetEditID(0)
  };
  const CancelFasterThanState = () => {
    document.getElementById("AddBox2").style.display = "none";
  };
  const ExportCSVFasterThanState = (UID) => {
    const InputParameter = {
      TabName: "FasterThan - State",
      FeatureFieldID: "3",
      UserID: UID,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableExport",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      setExportFasterThanState(Result.data.Data);
    });
  };
  const SaveFasterThanState = () => {
    SetBtnDisabled(true);
    if (StateFormValidation()) {
      let ValueField = document.getElementById("value").value;
      let StateField = document.getElementById("state").value;
      let CityField = document.getElementById("city").value;

      var InputParameter = {
        ClientID: ClientID,
        FeatureValue: ValueField,
        State: StateField,
        City: CityField,
        UserID: UserID,
        CreatedBy: CUserID,
        FeatureFieldID: "3",
        CreatedDate: new Date(),
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              {" "}
              FasterThan - State <br />
              FasterThan - State added successfully.
            </div>
          );
          document.getElementById("value").value = "";
          document.getElementById("state").value = "";
          SetUpdateKey(UpdateKey + 1);
          document.getElementById("city").value = "";
          CancelFasterThanState()

        }else if(res.data.StatusMessage == "DUPLICATE"){
          toast.error(
            <div className="toastsize">
            FasterThan - State
              <br />
              FasterThan - State Variable already exists.
            </div>
          );
          SetBtnDisabled(false);
        } else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);

        }
      });
    } else {
      SetBtnDisabled(false);
    }
  };
  const EditBtnFasterThanState = (ID) => {
    SetEditID(ID);
    // ShowFasterThanState();
    document.getElementById("AddBox2").style.display = "block";
    ClearStateData();
    canclecsv();
    var InputParameter = {
      ID: ID,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableGetByID",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      SetValues(
        Result.data.Data.FeatureValue === null
          ? ""
          : Result.data.Data.FeatureValue
      );
      SetState(Result.data.Data.State === null ? "" : Result.data.Data.State);
      SetCity(Result.data.Data.City === null ? "" : Result.data.Data.City);
      SetEditData(Result);
    });
  };
  const FasterThanStateUpdateDetails = () => {
    SetBtnDisabled(true);
    if (StateFormValidation()) {
      let ValueField = document.getElementById("value").value;
      let StateField = document.getElementById("state").value;
      let CityField = document.getElementById("city").value;

      var InputParameter = {
        ID:EditID,
        FeatureValue: ValueField,
        State: StateField,
        City: CityField,
        LastUpdatedBy: CUserID,
        LastUpdatedDate: new Date(),
        FeatureFieldID: "3",
        UserID:UserID,
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableUpdate",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              FasterThan - State
              <br />
              FasterThan - State updated successfully.
            </div>
          );
          document.getElementById("value").value = "";
          document.getElementById("state").value = "";
          SetUpdateKey(UpdateKey + 1);
          document.getElementById("city").value = "";
          SetEditID(0);
          CancelFasterThanState();
        }else if(res.data.StatusMessage == "DUPLICATE"){
          toast.error(
            <div className="toastsize">
              FasterThan - State
              <br />
              FasterThan - State Generic value already exist
            </div>
          );
          SetBtnDisabled(false);
        } else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);
        }
      });
    } else {
      SetBtnDisabled(false);
    }
  };

  // For FasterThan - Generic
  const ShowFasterThanGeneric = () => {
    ClearStateData();
    document.getElementById("AddBox3").style.display = "block";
    canclecsv();
    SetEditID(0)
  };
  const CancelFasterThanGeneric = () => {
    document.getElementById("AddBox3").style.display = "none";
  };
  const ExportCSVFasterThanGeneric = (UID) => {
    const InputParameter = {
      TabName: "FasterThan - Generic",
      FeatureFieldID: "4",
      UserID: UID,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableExport",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      setExportFasterThanGeneric(Result.data.Data);
    });
  };
  const SaveFasterThanGeneric = () => {
    SetBtnDisabled(true);
    if (GenericFromValidation()) {
      let ValueField = document.getElementById("value").value;

      var InputParameter = {
        ClientID: ClientID,
        FeatureValue: ValueField,
        UserID: UserID,
        CreatedBy: CUserID,
        CreatedDate: new Date(),
        FeatureFieldID: "4",
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              {" "}
              FasterThan - Generic <br />
              FasterThan - Generic added successfully.
            </div>
          );
          document.getElementById("value").value = "";
          // document.getElementById("state").value = "";
          // document.getElementById("city").value = "";
          SetUpdateKey(UpdateKey + 1);
          CancelFasterThanGeneric();
     
        }else if(res.data.StatusMessage == "DUPLICATE"){
          toast.error(
            <div className="toastsize">
          FasterThan - Generic
              <br />
              FasterThan - State Variable already exists.
            </div>
          );
          SetBtnDisabled(false);

        }  else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);
        }
      });
    } else {
      SetBtnDisabled(false);
    }
  };
  const EditBtnFasterThanGeneric = (ID) => {
    SetEditID(ID);
    // ShowFasterThanGeneric();
    ClearStateData();
    document.getElementById("AddBox3").style.display = "block";
    canclecsv();
    var InputParameter = {
      ID: ID,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableGetByID",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      SetValues(Result.data.Data.FeatureValue);
    });
  };
  const FasterThanGenericUpdateDetails = () => {
    SetBtnDisabled(true);
    if (GenericFromValidation()) {
      let ValueField = document.getElementById("value").value;

      var InputParameter = {
        ID:EditID,
        FeatureValue: ValueField,
        State: null,
        City: null,
        LastUpdatedBy: CUserID,
        LastUpdatedDate: new Date(),
        FeatureFieldID: "4",
        UserID:UserID,
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableUpdate",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              FasterThan - Generic
              <br />
              FasterThan - Generic updated successfully.
            </div>
          );
          document.getElementById("value").value = "";
          SetEditID(0);
          CancelFasterThanGeneric();
          SetUpdateKey(UpdateKey + 1);
        }else if(res.data.StatusMessage == "DUPLICATE"){
          toast.error(
            <div className="toastsize">
              FasterThan - Generic
              <br />
              FasterThan - Generic value already exist
            </div>
          );
          SetBtnDisabled(false);
        }  else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);
        }
      });
    } else {
      SetBtnDisabled(false);
    }
  };

  // for MoreExcitingThan - State
  const ShowMoreExcitingThanState = () => {
    document.getElementById("AddBox4").style.display = "block";
    ClearStateData();
    canclecsv();
    SetEditID(0)
  };
  const CancelMoreExcitingThanState = () => {
    document.getElementById("AddBox4").style.display = "none";
  };
  const ExportCSVMoreExcitingThanState = (UID) => {
    const InputParameter = {
      TabName: "MoreExcitingThan - State",
      FeatureFieldID: "5",
      UserID: UID,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableExport",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      setMoreExcitingThanState(Result.data.Data);
    });
  };
  const MoreExcitingThanState = () => {
    SetBtnDisabled(true);
    if (StateFormValidation()) {
      let ValueField = document.getElementById("value").value;
      let StateField = document.getElementById("state").value;
      let CityField = document.getElementById("city").value;

      var InputParameter = {
        ClientID: ClientID,
        FeatureValue: ValueField,
        State: StateField,
        City: CityField,
        UserID: UserID,
        CreatedBy: CUserID,
        CreatedDate: new Date(),
        FeatureFieldID: "5",
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              {" "}
              MoreExcitingThan - State <br />
              MoreExcitingThan - State added successfully.
            </div>
          );
          document.getElementById("value").value = "";
          document.getElementById("state").value = "";
          document.getElementById("city").value = "";
          SetUpdateKey(UpdateKey + 1);
          CancelMoreExcitingThanState();
        }else if(res.data.StatusMessage == "DUPLICATE"){
          toast.error(
            <div className="toastsize">
         MoreExcitingThan - State
              <br />
              MoreExcitingThan - State Variable already exists.
            </div>
          );
          SetBtnDisabled(false);
        }  else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);
        }
      });
    } else {
      SetBtnDisabled(false);
    }
  };
  const EditBtnMoreExcitingThanState = (ID) => {
    SetEditID(ID);
    // ShowMoreExcitingThanState();
    document.getElementById("AddBox4").style.display = "block";
    ClearStateData();
    canclecsv();
    var InputParameter = {
      ID: ID,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableGetByID",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      SetValues(
        Result.data.Data.FeatureValue === null
          ? ""
          : Result.data.Data.FeatureValue
      );
      SetState(Result.data.Data.State === null ? "" : Result.data.Data.State);
      SetCity(Result.data.Data.City === null ? "" : Result.data.Data.City);
    });
  };
  const UpdateMoreExcitingThanStateDetails = () => {
    SetBtnDisabled(true);
    if (StateFormValidation()) {
      let ValueField = document.getElementById("value").value;
      let StateField = document.getElementById("state").value;
      let CityField = document.getElementById("city").value;

      var InputParameter = {
        ID:EditID,
        FeatureValue: ValueField,
        State: StateField,
        City: CityField,
        LastUpdatedBy: CUserID,
        LastUpdatedDate: new Date(),
        FeatureFieldID: "5",
        UserID:UserID,
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableUpdate",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              {" "}
              MoreExcitingThan - State <br />
              MoreExcitingThan - State updated successfully.
            </div>
          );
          document.getElementById("value").value = "";
          document.getElementById("state").value = "";
          document.getElementById("city").value = "";
          SetEditID(0);
          SetUpdateKey(UpdateKey + 1);

          CancelMoreExcitingThanState();
        }else if(res.data.StatusMessage == "DUPLICATE"){
          toast.error(
            <div className="toastsize">
              MoreExcitingThan - State 
              <br />
              MoreExcitingThan - State value already exist
            </div>
          );
          SetBtnDisabled(false);
           } else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);
        }
      });
    } else {
      SetBtnDisabled(false);
    }
  };

  // for MoreExcitingThan - Generic
  const ShowMoreExcitingThanGeneric = () => {
    ClearStateData();
    document.getElementById("AddBox5").style.display = "block";
    SetEditID(0)
  };
  const CancelMoreExcitingThanGeneric = () => {
    document.getElementById("AddBox5").style.display = "none";
  };
  const ExportCSVMoreExcitingThanGeneric = (UID) => {
    const InputParameter = {
      TabName: "MoreExcitingThan - Generic",
      FeatureFieldID: "6",
      UserID: UID,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableExport",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      setMoreExcitingThanGeneric(Result.data.Data);
    });
  };
  const MoreExcitingThanGeneric = () => {
    SetBtnDisabled(true);
    if (GenericFromValidation()) {
      let ValueField = document.getElementById("value").value;

      var InputParameter = {
        ClientID: ClientID,
        FeatureValue: ValueField,
        UserID: UserID,
        CreatedBy: CUserID,
        CreatedDate: new Date(),
        FeatureFieldID: "6",
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              {" "}
              MoreExcitingThan - Generic <br />
              MoreExcitingThan - Generic added successfully.
            </div>
          );
          CancelMoreExcitingThanGeneric();
          SetUpdateKey(UpdateKey + 1);
          document.getElementById("value").value = "";
        }else if(res.data.StatusMessage == "DUPLICATE"){
          toast.error(
            <div className="toastsize">
        MoreExcitingThan - Generic
              <br />
              MoreExcitingThan - Generic Variable already exists.
            </div>
          );
          SetBtnDisabled(false);

        }  else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);
        }
      });
    } else {
      SetBtnDisabled(false);
    }
  };
  const EditBtnMoreExcitingThanGeneric = (ID) => {
    SetEditID(ID);
    // ShowMoreExcitingThanGeneric();
    ClearStateData();
    document.getElementById("AddBox5").style.display = "block";
    var InputParameter = {
      ID: ID,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableGetByID",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      SetValues(Result.data.Data.FeatureValue);
    });
  };
  const UpdateMoreExcitingThanGenericDetails = () => {
    SetBtnDisabled(true);

    if (GenericFromValidation()) {
      let ValueField = document.getElementById("value").value;

      var InputParameter = {
        ID:EditID,
        FeatureValue: ValueField,
        State: null,
        City: null,
        LastUpdatedBy: CUserID,
        LastUpdatedDate: new Date(),
        FeatureFieldID: "6",
        UserID:UserID,
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableUpdate",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              {" "}
              MoreExcitingThan - Generic <br />
              MoreExcitingThan - Generic update successfully.
            </div>
          );
          document.getElementById("value").value = "";
          SetEditID(0);
          SetUpdateKey(UpdateKey + 1);
          CancelMoreExcitingThanGeneric();
        }else if(res.data.StatusMessage == "DUPLICATE"){
          toast.error(
            <div className="toastsize">
               MoreExcitingThan - Generic
              <br />
              MoreExcitingThan - Generic value already exist
            </div>
          );
          SetBtnDisabled(false);
           } else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);
        }
      });
    } else {
      SetBtnDisabled(false);
    }
  };

  // For  MascotCheer - State
  const ShowMascotCheerState = () => {
    SetEditID(0)
    ClearStateData();
    document.getElementById("AddBox6").style.display = "block";
  };
  const CancelMascotCheerState = () => {
    document.getElementById("AddBox6").style.display = "none";
  };
  const ExportCSVMascotCheerState = (UID) => {
    const InputParameter = {
      TabName: "MascotCheer - State",
      FeatureFieldID: "7",
      UserID: UID,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableExport",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      setMascotCheerState(Result.data.Data);
    });
  };
  const MascotCheerStateSave = () => {
    SetBtnDisabled(true);

    if (StateFormValidation()) {
      let ValueField = document.getElementById("value").value;
      let StateField = document.getElementById("state").value;
      let CityField = document.getElementById("city").value;

      var InputParameter = {
        ClientID: ClientID,
        FeatureValue: ValueField,
        State: StateField,
        City: CityField,
        UserID: UserID,
        CreatedBy: CUserID,
        CreatedDate: new Date(),
        FeatureFieldID: "7",
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              {" "}
              MascotCheer - State <br />
              MascotCheer - State added successfully.
            </div>
          );
          document.getElementById("value").value = "";
          document.getElementById("state").value = "";
          document.getElementById("city").value = "";
          SetUpdateKey(UpdateKey + 1);

          CancelMascotCheerState();
        }else if(res.data.StatusMessage == "DUPLICATE"){
          toast.error(
            <div className="toastsize">
        MascotCheer - State
              <br />
              MascotCheer - State Variable already exists.
            </div>
          );
          SetBtnDisabled(false);

        }  else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);
        }
      });
    } else {
      SetBtnDisabled(false);
    }
  };
  const EditBtnMascotCheerState = (ID) => {
    SetEditID(ID);
    // ShowMascotCheerState();
    ClearStateData();
    document.getElementById("AddBox6").style.display = "block";
    var InputParameter = {
      ID: ID,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableGetByID",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      SetValues(
        Result.data.Data.FeatureValue === null
          ? ""
          : Result.data.Data.FeatureValue
      );
      SetState(Result.data.Data.State === null ? "" : Result.data.Data.State);
      SetCity(Result.data.Data.City === null ? "" : Result.data.Data.City);
    });
  };
  const UpdateMascotCheerStateDetails = () => {
    SetBtnDisabled(true);

    if (StateFormValidation()) {
      let ValueField = document.getElementById("value").value;
      let StateField = document.getElementById("state").value;
      let CityField = document.getElementById("city").value;

      var InputParameter = {
        ID:EditID,
        FeatureValue: ValueField,
        State: StateField,
        City: CityField,
        LastUpdatedBy: CUserID,
        LastUpdatedDate: new Date(),
        FeatureFieldID: "7",
        UserID:UserID,
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableUpdate",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              MascotCheer - State <br />
              MascotCheer - State updated successfully.
            </div>
          );
          document.getElementById("value").value = "";
          document.getElementById("state").value = "";
          document.getElementById("city").value = "";

          SetEditID(0);
          SetUpdateKey(UpdateKey + 1);
          CancelMascotCheerState();
        }else if(res.data.StatusMessage == "DUPLICATE"){
          toast.error(
            <div className="toastsize">
              MascotCheer - State 
              <br />
              MascotCheer - State value already exist
            </div>
          );
          SetBtnDisabled(false);
           }  else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);
        }
      });
    } else {
      SetBtnDisabled(false);
    }
  };

  // For  MascotCheer - Generic
  const ShowMascotCheerGeneric = () => {
    SetEditID(0)
    ClearStateData();
    document.getElementById("AddBox7").style.display = "block";
  };
  const CancelMascotCheerGeneric = () => {
    document.getElementById("AddBox7").style.display = "none";
  };
  const ExportCSVMascotCheerGeneric = (UID) => {
    const InputParameter = {
      TabName: "MascotCheer - Generic",
      FeatureFieldID: "8",
      UserID: UID,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableExport",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      setMascotCheerGeneric(Result.data.Data);
    });
  };
  const MascotCheerGenericStateSave = () => {
    SetBtnDisabled(true);

    if (GenericFromValidation()) {
      let ValueField = document.getElementById("value").value;

      var InputParameter = {
        ClientID: ClientID,
        FeatureValue: ValueField,
        UserID: UserID,
        CreatedBy: CUserID,
        CreatedDate: new Date(),
        FeatureFieldID: "8",
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              {" "}
              MascotCheer - Generic <br />
              MascotCheer - Generic added successfully.
            </div>
          );
          document.getElementById("value").value = "";
          SetUpdateKey(UpdateKey + 1);
          CancelMascotCheerGeneric();
     
        }else if(res.data.StatusMessage == "DUPLICATE"){
          toast.error(
            <div className="toastsize">
        MascotCheer - Generic
              <br />
              MascotCheer - Generic Variable already exists.
            </div>
          );
          SetBtnDisabled(false);

        }  else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);
        }
      });
    } else {
      SetBtnDisabled(false);
    }
  };
  const EditMascotCheerGenericBtn = (ID) => {
    SetEditID(ID);
    // ShowMascotCheerGeneric();
    ClearStateData();
    document.getElementById("AddBox7").style.display = "block";
    var InputParameter = {
      ID: ID,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableGetByID",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      SetValues(Result.data.Data.FeatureValue);
    });
  };
  const UpdateMascotCheerGenericDetails = () => {
    SetBtnDisabled(true);

    if (GenericFromValidation()) {
      let ValueField = document.getElementById("value").value;

      var InputParameter = {
        ID:EditID,
        FeatureValue: ValueField,
        State: null,
        City: null,
        LastUpdatedBy: CUserID,
        LastUpdatedDate: new Date(),
        FeatureFieldID: "8",
        UserID:UserID,
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableUpdate",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              MascotCheer - Generic <br />
              MascotCheer - Generic updated successfully{" "}
            </div>
          );
          document.getElementById("value").value = "";
          SetEditID(0);
          CancelMascotCheerGeneric();
          SetUpdateKey(UpdateKey + 1);
        }else if(res.data.StatusMessage == "DUPLICATE"){
          toast.error(
            <div className="toastsize">
             MascotCheer - Generic 
              <br />
              MascotCheer - Generic value already exist
            </div>
          );
          SetBtnDisabled(false);
           }  else {
          toast.error(res.data.Message);
          SetBtnDisabled(false);
        }
      });
    } else {
      SetBtnDisabled(false);
    }
  };
  const UpdataChildComponent = (value) => {
    if(value == true){
      setTrigger(value)
    }
  }
  return (
    <div className="tabdes">
      <div>
        <AppBar position="static">
          <Tabs 
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="BiggerThan - State" {...a11yProps(0)} />
            <Tab label="BiggerThan - Generic" {...a11yProps(1)} />
            <Tab label="FasterThan - State" {...a11yProps(2)} />
            <Tab label="FasterThan - Generic" {...a11yProps(3)} />
            <Tab label="MoreExcitingThan - State" {...a11yProps(4)} />
            <Tab label="MoreExcitingThan - Generic" {...a11yProps(5)} />
            <Tab label="MascotCheer - State" {...a11yProps(6)} />
            <Tab label="MascotCheer - Generic" {...a11yProps(7)} />
          </Tabs>
        </AppBar>
        <div className="">
        {/* csv */}
        {OpenCsv ? (
          <div className="portletbody bg-white border-bottom-0" id="import_csv">
            <div className="row">
              <div class="col-xl-8 offset-xl-2 mt-3">
                <h3 class="uploadheadingtitle" id="ImportCSVState">
                  {PutImportStateMsg}
                </h3>
                <div className="uplodfilesbox">
                  <CSVReader
                    accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                    onDrop={handleOnDrop}
                    onError={handleOnError}
                    addRemoveButton
                    removeButtonColor="#659cef"
                    onRemoveFile={handleOnRemoveFile}
                    isReset={Reset}
                  >
                    <span>Select .CSV file</span>
                    <p>Add {PutImportStateDiv} data from csv file to import.</p>
                  </CSVReader>
                </div>
              </div>
              <div className="col-xl-8 offset-xl-2 ">
                <div class="alignright">
                  <a
                    onClick={ImportDataMapCsv}
                    class="btn btn-primary btnColor"
                  >
                    <i class="la la-upload"></i> Upload
                  </a>
                  <a onClick={canclecsv} class="btn btn-secondary">
                    <i class="la flaticon-cancel"></i> Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {MapCsvs ? (
          <div className="portletbody bg-white p-0 my-0" id="map_attribute">
            <div className="row col-xs-12 mx-0">
              <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
            </div>
            <div className="col-xs-12">
            <div className="row" id="divContactSelection">
              <div className="form-group m-form__group row col-lg-6 mx-0">
                <div className="col-lg-5 nopadding">
                  <div>
                    <select onChange={(e)=>onHandleChange(e)}
                      className="dbcontent form-control  m-input"
                      disabled="disabled"
                    >
                      <option selected="" value="ListImports">
                        Value
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 text-center">
                  <span className="fa fa-arrows-h fa-2x "></span>
                </div>
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                     onChange={(e)=>onHandleChange(e)}
                      id="Values"
                      className="csvcontent form-control  m-input"
                      autocomplete="off"
                      disabled={ValuesDisabled}
                      value={selectedValue == "not mapped" ? DropBoxData.indexOf(FieldID == 1 ? "BiggerThan - State" : selectedValue || FieldID == 5 ? "MoreExcitingThan - State" : selectedValue || FieldID == 3 ? "FasterThan - State" : selectedValue || FieldID == 7 ? "MascotCheer - State" : selectedValue) : selectedValue}
                      // {FirstName == null
                      //   ? DropboxData.indexOf("FirstName")
                      //    : FirstName}
                    >
                      <option value="not mapped">Not Mapped</option>
                      {
                          selectedCity !== 'not mapped' && selectedState !== 'not mapped'
                            ? DropBoxData
                                .filter((data) => data !== selectedCity && data !== selectedState)
                                .map((dbdata, index) => (
                                  <option key={index} value={dbdata}>
                                    {dbdata}
                                  </option>
                                ))
                            : selectedCity !== 'not mapped'
                            ? DropBoxData
                                .filter((data) => data !== selectedCity)
                                .map((dbdata, index) => (
                                  <option key={index} value={dbdata}>
                                    {dbdata}
                                  </option>
                                ))
                            : selectedState !== 'not mapped'
                            ? DropBoxData
                                .filter((data) => data !== selectedState)
                                .map((dbdata, index) => (
                                  <option key={index} value={dbdata}>
                                    {dbdata}
                                  </option>
                                ))
                            : DropBoxData.map((dbdata, index) => (
                                <option key={index} value={dbdata}>
                                  {dbdata}
                                </option>
                              ))
                        }

                      {/* {DropBoxData.map((dbdata, index) => (
                        <option value={index}>{dbdata}</option>
                      ))} */}
                    </select>
                  </div>
                </div>
              </div>
              {FieldID == 1 || FieldID == 3 || FieldID == 5 || FieldID == 7 ? (
                <>
                  <div className="form-group m-form__group row col-lg-6 mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                         onChange={(e)=>onHandleChange(e)}
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="City">
                            City
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                         onChange={(e)=>onHandleChange(e)}
                         id="City"
                         value={selectedCity == "not mapped" ? DropBoxData.indexOf("City") : selectedCity}
                          className="csvcontent form-control  m-input"
                          autoComplete="off"
                          disabled={CityDisabled}
                        >
                          <option value="not mapped">Not Mapped</option>
                          {
                                  selectedValue !== 'not mapped' && selectedState !== 'not mapped'
                                    ? DropBoxData
                                        .filter((data) => data !== selectedValue && data !== selectedState)
                                        .map((dbdata, index) => (
                                          <option key={index} value={dbdata}>
                                            {dbdata}
                                          </option>
                                        ))
                                    : selectedValue !== 'not mapped'
                                    ? DropBoxData
                                        .filter((data) => data !== selectedValue)
                                        .map((dbdata, index) => (
                                          <option key={index} value={dbdata}>
                                            {dbdata}
                                          </option>
                                        ))
                                    : selectedState !== 'not mapped'
                                    ? DropBoxData
                                        .filter((data) => data !== selectedState)
                                        .map((dbdata, index) => (
                                          <option key={index} value={dbdata}>
                                            {dbdata}
                                          </option>
                                        ))
                                    : DropBoxData.map((dbdata, index) => (
                                        <option key={index} value={dbdata}>
                                          {dbdata}
                                        </option>
                                      ))
                                }

                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group m-form__group row col-lg-6 mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                         onChange={(e)=>onHandleChange(e)}
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="State">
                            State
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          // value={State == null ? DropBoxData.indexOf("State") : State}
                          onChange={(e)=>onHandleChange(e)}
                          id="State"
                          value={selectedState == "not mapped" ? DropBoxData.indexOf("State") : selectedState}
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          disabled={StateDisabled}
                        >
                          <option value="not mapped">Not Mapped</option>
                          {
                                  selectedValue !== 'not mapped' && selectedCity !== 'not mapped'
                                    ? DropBoxData
                                        .filter((data) => data !== selectedValue && data !== selectedCity)
                                        .map((dbdata, index) => (
                                          <option key={index} value={dbdata}>
                                            {dbdata}
                                          </option>
                                        ))
                                    : selectedValue !== 'not mapped'
                                    ? DropBoxData
                                        .filter((data) => data !== selectedValue)
                                        .map((dbdata, index) => (
                                          <option key={index} value={dbdata}>
                                            {dbdata}
                                          </option>
                                        ))
                                    : selectedCity !== 'not mapped'
                                    ? DropBoxData
                                        .filter((data) => data !== selectedCity)
                                        .map((dbdata, index) => (
                                          <option key={index} value={dbdata}>
                                            {dbdata}
                                          </option>
                                        ))
                                    : DropBoxData.map((dbdata, index) => (
                                        <option key={index} value={dbdata}>
                                          {dbdata}
                                        </option>
                                      ))
                                }

                        </select>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
</div>
              <div className="col-xs-12 pb-3 px-3">
                <button
                  id="SaveImport"
                  disabled={SaveImportBtn}
                  onClick={() => {
                    Savecsv();
                  }}
                  className="btn btn-primary btn-lightgreen"
                >
                  <i className="la la-save"></i>Import
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {TotalDisplayCount === true ? (
          <div className="portletbody bg-white p-0 my-0 border-top-0 border-bottom-0 pb-3" id=" ">
            <div className="row col-xs-12 mx-0">
              <h4 className="headertitle xs-headertitle float-left pl-0">Results</h4>
            </div>

            <div className="col-xs-12">
              <div class="row colfive mx-0">
                <div class="col-md-3">
                  <div class="boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2 d-block text-center">
                          <span class="box-main-title font-primary">
                            {TotalResult}
                          </span>
                        </div>
                        <div class="box-inner-title xs-headertitle">
                          <string class="clr-sky">Total</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2 d-block text-center">
                          <span class="box-main-title font-success">
                            {TotalSuccess}
                          </span>
                        </div>
                        <div class="box-inner-title xs-headertitle">
                          <string class="clr-sky">Total success</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2 d-block text-center">
                          <span class="box-main-title font-danger">
                            {TotalError}
                          </span>
                        </div>
                        <div class="box-inner-title xs-headertitle">
                          <string class="clr-sky">Total error</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="boxcardcounter">
                    <div class="d-flex align-center">
                      <div class="contectboxes w-100">
                        <div class="line-title mb-2 d-block text-center">
                          <span class="box-main-title font-warning">
                            {TotalDuplicate}
                          </span>
                        </div>
                        <div class="box-inner-title xs-headertitle">
                          <string class="clr-sky">Total Duplicate</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {/* Bulk Delete for all tabs */}
        {OpenDeleteCsv ? (
          <div className="portletbody" id="import_csv">
            <div className="row">
              <div class="col-xl-8 offset-xl-2 mt-3">
                <h3 class="uploadheadingtitle" id="ImportCSVState">
                  {PutDeleteStateMsg}
                </h3>
                <div className="uplodfilesbox">
                  <CSVReader
                    accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                    onDrop={handleOnDrop}
                    onError={handleOnError}
                    addRemoveButton
                    removeButtonColor="#659cef"
                    onRemoveFile={handleOnRemoveFile}
                    isReset={Reset}
                  >
                    <span>Select .CSV file</span>
                    <p>
                      Add {PutDeleteStateDiv} data from csv file to bulk delete.
                    </p>
                  </CSVReader>
                </div>
              </div>
              <div className="col-xl-8 offset-xl-2 border-top mt-3">
                <div class="alignright">
                  <a
                    onClick={DeleteDataMapCsv}
                    class="btn btn-primary btnColor"
                  >
                    <i class="la la-upload"></i> Upload
                  </a>
                  <a onClick={canclecsv} class="btn btn-secondary">
                    <i class="la flaticon-cancel"></i> Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {MapBulkCsvs ? (
          <div className="portletbody portletshadow p-3 mt-5 mx-5" id="map_attribute">
            <div className="row col-xs-12">
              <h4 className="headertitle float-left">Map attributes</h4>
            </div>
            <div className="row">
              <div className="form-group m-form__group col-lg-6 row mx-0">
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                    onChange={(e)=>onHandleChange(e)}
                      className="dbcontent form-control  m-input"
                      disabled="disabled"
                    >
                      <option selected="" value="ListImports">
                        Value
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 text-center">
                  <span className="fa fa-arrows-h fa-2x "></span>
                </div>
                <div className="col-lg-5 nopadding">
                  <div>
                    <select
                    onChange={(e)=>onHandleChange(e)}
                      id="Values"
                      className="csvcontent form-control  m-input"
                      autocomplete="off"
                      value={selectedValue == "not mapped" ? DropBoxData.indexOf(FieldID == 1 ? "BiggerThan - State" : selectedValue || FieldID == 5 ? "MoreExcitingThan - State" : selectedValue || FieldID == 3 ? "FasterThan - State" : selectedValue || FieldID == 7 ? "MascotCheer - State" : selectedValue) : selectedValue}
                      // {FirstName == null
                      //   ? DropboxData.indexOf("FirstName")
                      //    : FirstName}
                    >
                      <option value="not mapped">Not Mapped</option>
                      {
                          selectedCity !== 'not mapped' && selectedState !== 'not mapped'
                            ? DropBoxData
                                .filter((data) => data !== selectedCity && data !== selectedState)
                                .map((dbdata, index) => (
                                  <option key={index} value={dbdata}>
                                    {dbdata}
                                  </option>
                                ))
                            : selectedCity !== 'not mapped'
                            ? DropBoxData
                                .filter((data) => data !== selectedCity)
                                .map((dbdata, index) => (
                                  <option key={index} value={dbdata}>
                                    {dbdata}
                                  </option>
                                ))
                            : selectedState !== 'not mapped'
                            ? DropBoxData
                                .filter((data) => data !== selectedState)
                                .map((dbdata, index) => (
                                  <option key={index} value={dbdata}>
                                    {dbdata}
                                  </option>
                                ))
                            : DropBoxData.map((dbdata, index) => (
                                <option key={index} value={dbdata}>
                                  {dbdata}
                                </option>
                              ))
                        }
                      {/* {DropBoxData.map((dbdata, index) => (
                        <option value={index}>{dbdata}</option>
                      ))} */}
                    </select>
                  </div>
                </div>
              </div>
              {FieldID == 1 || FieldID == 3 || FieldID == 5 || FieldID == 7 ? (
                <>
                  <div className="form-group m-form__group col-lg-6 row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                        onChange={(e)=>onHandleChange(e)}
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="City">
                            City
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                        onChange={(e)=>onHandleChange(e)}
                          id="City"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={selectedCity == "not mapped" ? DropBoxData.indexOf("City") : selectedCity}
                        >
                          <option value="not mapped">Not Mapped</option>
                          {
                                  selectedValue !== 'not mapped' && selectedState !== 'not mapped'
                                    ? DropBoxData
                                        .filter((data) => data !== selectedValue && data !== selectedState)
                                        .map((dbdata, index) => (
                                          <option key={index} value={dbdata}>
                                            {dbdata}
                                          </option>
                                        ))
                                    : selectedValue !== 'not mapped'
                                    ? DropBoxData
                                        .filter((data) => data !== selectedValue)
                                        .map((dbdata, index) => (
                                          <option key={index} value={dbdata}>
                                            {dbdata}
                                          </option>
                                        ))
                                    : selectedState !== 'not mapped'
                                    ? DropBoxData
                                        .filter((data) => data !== selectedState)
                                        .map((dbdata, index) => (
                                          <option key={index} value={dbdata}>
                                            {dbdata}
                                          </option>
                                        ))
                                    : DropBoxData.map((dbdata, index) => (
                                        <option key={index} value={dbdata}>
                                          {dbdata}
                                        </option>
                                      ))
                                }

                          {/* {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))} */}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group m-form__group col-lg-6 row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                        onChange={(e)=>onHandleChange(e)}
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="State">
                            State
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                        onChange={(e)=>onHandleChange(e)}
                          id="State"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={selectedState == "not mapped" ? DropBoxData.indexOf("State") : selectedState}

                        >
                          <option value="not mapped">Not Mapped</option>
                          {
                                  selectedValue !== 'not mapped' && selectedCity !== 'not mapped'
                                    ? DropBoxData
                                        .filter((data) => data !== selectedValue && data !== selectedCity)
                                        .map((dbdata, index) => (
                                          <option key={index} value={dbdata}>
                                            {dbdata}
                                          </option>
                                        ))
                                    : selectedValue !== 'not mapped'
                                    ? DropBoxData
                                        .filter((data) => data !== selectedValue)
                                        .map((dbdata, index) => (
                                          <option key={index} value={dbdata}>
                                            {dbdata}
                                          </option>
                                        ))
                                    : selectedCity !== 'not mapped'
                                    ? DropBoxData
                                        .filter((data) => data !== selectedCity)
                                        .map((dbdata, index) => (
                                          <option key={index} value={dbdata}>
                                            {dbdata}
                                          </option>
                                        ))
                                    : DropBoxData.map((dbdata, index) => (
                                        <option key={index} value={dbdata}>
                                          {dbdata}
                                        </option>
                                      ))
                                }
                          {/* {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))} */}
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="col-xs-12 border-top pt-4 px-4 mt-2">
                <button
                  id="SaveImport"
                  disabled={SaveImportBtn}
                  onClick={() => {
                    DeleteSavecsv();
                  }}
                  className="btn btn-danger btn-lightgreen"
                >
                  <i className="la la-save"></i>Delete
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
        <TabPanel value={value} className="tagpaddnone bg-white" index={0}>
          <div className="tabmainerinner p-0">
            <div className="row borderbtnm align-items-center mx-0">
              <div className="col padd-0">
                <h4 className="headertitlebd xs-headertitle">BiggerThan - State</h4>
              </div>
              <div className="col">
                <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>
                    <li> <Tooltip title="Add">
                      <button
                        onClick={ShowAdd}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                      </button></Tooltip>
                    </li>
                    <li><Tooltip title="Import">
                      <button
                        onClick={() => {
                          importBiggerThanStatecsv(1);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-upload"></i>
                      </button></Tooltip>
                    </li>
                    <li><Tooltip title="Delete">
                      <button
                        onClick={() => {
                          importStateDeletecsv(1);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la flaticon-delete-1"></i>
                      </button></Tooltip>
                    </li>
                    <Tooltip title="Export"><li className="export-li">
                      <CSVDownloader
                        className="px-0"
                        data={ExportBiggerThanState}
                        filename={`${ClientName}-BiggerThan_-_State`}
                        bom={true}
                      >
                        <button
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          onClick={() => {
                            ExportCsvMsg(0);
                          }}
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                        </button>
                      </CSVDownloader>
                    </li></Tooltip>
                  </ul>
                </div>
              </div>
            </div>

            <div className="px-0 py-2" id="AddBox">
              <div className="row borderbtnm pt-2 pb-3 mx-0">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 potential_text"> Value </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="value"
                        type="text"
                        name="Value"
                        value={Values}
                        onBlur={BiggerThanStateHandleBlur}
                        onChange={(e) => SetValues(e.target.value)}
                      />

                      {Errors.Value && (
                        <span style={{ color: "red" }}>{Errors.Value}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 potential_text"> City </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="city"
                        type="text"
                        name="City"
                        value={City}
                        onBlur={BiggerThanStateHandleBlur}
                        onChange={(e) => SetCity(e.target.value)}
                      />

                      {Errors.City && (
                        <span style={{ color: "red" }}>{Errors.City}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 potential_text"> State </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="state"
                        type="text"
                        name="State"
                        value={State}
                        onBlur={BiggerThanStateHandleBlur}
                        onChange={(e) => SetState(e.target.value)}
                      />

                      {Errors.State && (
                        <span style={{ color: "red" }}>{Errors.State}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row borderbtnm pb-3 mx-0">
                <div className="col-xl-12">
                  <div class="alignright">
                    {EditID == 0 ? (
                      <button
                        disabled={BtnDisabled}
                        class="btn btn-primary btnColor"
                        onClick={SaveState}
                      >
                        <i class="la la-save"></i> Save
                      </button>
                    ) : (
                      <button
                        class="btn btn-primary btnColor"
                        disabled={BtnDisabled}
                        onClick={UpdateDetails}
                      >
                        <i class="la la-save"></i> Save
                      </button>
                    )}

                    <button class="btn btn-secondary" onClick={CancelAdd}>
                      <i class="la flaticon-cancel"></i> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-0">
              <div className="col">
                <div className="table-bordered border-none">
                  <BiggerThanStateTable key={UpdateKey} EditID={EditBtn} updata={UpdataChildComponent}/>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={1}>
          <div className="tabmainerinner p-0">
            <div className="row borderbtnm mx-0">
              <div className="col padd-0">
                <h4 className="headertitlebd xs-headertitle">BiggerThan - Generic</h4>
              </div>
              <div className="col">
                <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>
                    <li><Tooltip title="Add">
                      <button 
                        onClick={() => {
                          BiggerThanGenericAdd(2);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                      </button></Tooltip>
                    </li>
                    <li><Tooltip title="Import">
                      <button
                        onClick={() => {
                          importBiggerThanStatecsv(2);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-upload"></i>
                      </button></Tooltip>
                    </li>

                    <li><Tooltip title="Delete">
                      <button
                        onClick={() => {
                          importStateDeletecsv(2);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la flaticon-delete-1"></i>
                      </button></Tooltip>
                    </li>
                    <Tooltip title="Export"><li>
                      <CSVDownloader
                        className="px-0"
                        data={ExportBiggerThanGeneric}
                        filename={`${ClientName}-BiggerThan_-_Generic`}
                        bom={true}
                      >
                        <button
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          onClick={() => {
                            ExportCsvMsg(1);
                          }}
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                        </button>
                      </CSVDownloader>
                    </li></Tooltip>
                  </ul>
                </div>
              </div>
            </div>

            <div className="px-0 py-2" id="AddBox1">
              <div className="row borderbtnm pt-2 pb-3 mx-0">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 potential_text"> Value </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="value"
                        type="text"
                        name="Value"
                        value={Values}
                        onBlur={GenericHandlerBlur}
                        onChange={(e) => SetValues(e.target.value)}
                      />

                      {Errors.Value && (
                        <span style={{ color: "red" }}>{Errors.Value}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row borderbtnm pb-3 mx-0">
                <div className="col-xl-12">
                  <div class="alignright">
                    {EditID == 0 ? (
                      <button
                        class="btn btn-primary btnColor"
                        disabled={BtnDisabled}
                        onClick={SaveGeneric}
                      >
                        <i class="la la-save"></i> Save
                      </button>
                    ) : (
                      <button
                        class="btn btn-primary btnColor"
                        disabled={BtnDisabled}
                        onClick={UpdateBiggerThanGenericDetails}
                      >
                        <i class="la la-save"></i> Save
                      </button>
                    )}
                    <button
                      class="btn btn-secondary"
                      onClick={CancelBiggerThanGenericAdd}
                    >
                      <i class="la flaticon-cancel"></i> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-0">
              <div className="col">
                <div className="table-bordered border-none">
                  <BiggerThanGenericTable
                    key={UpdateKey}
                    EditBtnBiggerThanGeneric={EditBtnBiggerThanGeneric}
                    updata={UpdataChildComponent}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={2}>
          <div className="tabmainerinner p-0">
            <div className="row borderbtnm mx-0">
              <div className="col padd-0">
                <h4 className="headertitlebd xs-headertitle">FasterThan - State</h4>
              </div>
              <div className="col">
                <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>
                    <li><Tooltip title="Add">
                      <button
                        onClick={ShowFasterThanState}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                      </button></Tooltip>
                    </li>
                    <li><Tooltip title="Import">
                      <button
                        onClick={() => {
                          importBiggerThanStatecsv(3);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-upload"></i>
                      </button></Tooltip>
                    </li>
                    <li><Tooltip title="Delete">
                      <button 
                        onClick={() => {
                          importStateDeletecsv(3);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la flaticon-delete-1"></i>
                      </button></Tooltip>
                    </li>
                    <Tooltip title="Export"><li>
                      <CSVDownloader
                        className="px-0"
                        data={ExportFasterThanState}
                        filename={`${ClientName}-FasterThan_-_State`}
                        bom={true}
                      >
                        <button
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          onClick={() => {
                            ExportCsvMsg(2);
                          }}
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                        </button>
                      </CSVDownloader>
                    </li></Tooltip>
                  </ul>
                </div>
              </div>
            </div>

            <div className="px-0 py-2" id="AddBox2">
              <div className="row borderbtnm pt-2 pb-3 mx-0">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 potential_text"> Value </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="value"
                        type="text"
                        name="Value"
                        value={Values}
                        onBlur={BiggerThanStateHandleBlur}
                        onChange={(e) => SetValues(e.target.value)}
                      />

                      {Errors.Value && (
                        <span style={{ color: "red" }}>{Errors.Value}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 potential_text"> City </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="city"
                        type="text"
                        name="City"
                        value={City}
                        onBlur={BiggerThanStateHandleBlur}
                        onChange={(e) => SetCity(e.target.value)}
                      />

                      {Errors.City && (
                        <span style={{ color: "red" }}>{Errors.City}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 potential_text"> State </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="state"
                        type="text"
                        name="State"
                        value={State}
                        onBlur={BiggerThanStateHandleBlur}
                        onChange={(e) => SetState(e.target.value)}
                      />

                      {Errors.State && (
                        <span style={{ color: "red" }}>{Errors.State}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row borderbtnm pb-3 mx-0">
                <div className="col-xl-12">
                  <div class="alignright">
                    {EditID == 0 ? (
                      <button
                        class="btn btn-primary btnColor"
                        onClick={SaveFasterThanState}
                        disabled={BtnDisabled}
                      >
                        <i class="la la-save"></i> Save
                      </button>
                    ) : (
                      <button
                        class="btn btn-primary btnColor"
                        onClick={FasterThanStateUpdateDetails}
                        disabled={BtnDisabled}
                      >
                        <i class="la la-save"></i> Save
                      </button>
                    )}

                    <button
                      onClick={CancelFasterThanState}
                      class="btn btn-secondary"
                    >
                      <i class="la flaticon-cancel"></i> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-0">
              <div className="col ">
                <div className="table-bordered border-none">
                  <FasterThanStateTable
                    key={UpdateKey}
                    EditBtnFasterThanState={EditBtnFasterThanState}
                    updata={UpdataChildComponent}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={3}>
          <div className="tabmainerinner p-0">
            <div className="row borderbtnm mx-0">
              <div className="col padd-0">
                <h4 className="headertitlebd xs-headertitle">FasterThan - Generic</h4>
              </div>
              <div className="col">
                <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>
                    <li><Tooltip title="Add">
                      <button
                        onClick={ShowFasterThanGeneric}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                      </button></Tooltip>
                    </li>
                    <li><Tooltip title="Import">
                      <button
                        onClick={() => {
                          importBiggerThanStatecsv(4);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-upload"></i>
                      </button></Tooltip>
                    </li>

                    <li><Tooltip title="Delete">
                      <button
                        onClick={() => {
                          importStateDeletecsv(4);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la flaticon-delete-1"></i>
                      </button></Tooltip>
                    </li>
                    <Tooltip title="Export"><li>
                      <CSVDownloader
                        className="px-0"
                        data={ExportFasterThanGeneric}
                        filename={`${ClientName}-FasterThan_-_Generic`}
                        bom={true}
                      >
                        <button
                          onClick={() => {
                            ExportCsvMsg(3);
                          }}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                        </button>
                      </CSVDownloader>
                    </li></Tooltip>             
                    </ul>
                </div>
              </div>
            </div>

            <div className="px-0 py-2" id="AddBox3">
              <div className="row borderbtnm pt-2 pb-3 mx-0">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 potential_text"> Value </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="value"
                        type="text"
                        name="Value"
                        value={Values}
                        onBlur={GenericHandlerBlur}
                        onChange={(e) => SetValues(e.target.value)}
                      />

                      {Errors.Value && (
                        <span style={{ color: "red" }}>{Errors.Value}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row borderbtnm pb-3 mx-0">
                <div className="col-xl-12">
                  <div class="alignright">
                    {EditID == 0 ? (
                      <button
                        class="btn btn-primary btnColor"
                        onClick={SaveFasterThanGeneric}
                        disabled={BtnDisabled}
                      >
                        <i class="la la-save"></i> Save
                      </button>
                    ) : (
                      <button
                        class="btn btn-primary btnColor"
                        onClick={FasterThanGenericUpdateDetails}
                        disabled={BtnDisabled}
                      >
                        <i class="la la-save"></i> Save
                      </button>
                    )}
                    <button
                      class="btn btn-secondary"
                      onClick={CancelFasterThanGeneric}
                    >
                      <i class="la flaticon-cancel"></i> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-0">
              <div className="col ">
                <div className="table-bordered border-none">
                  <FasterThanGenericTable
                    key={UpdateKey}
                    EditBtnFasterThanGeneric={EditBtnFasterThanGeneric}
                    updata={UpdataChildComponent}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={4}>
          <div className="tabmainerinner p-0">
            <div className="row borderbtnm mx-0">
              <div className="col padd-0">
                <h4 className="headertitlebd xs-headertitle">MoreExcitingThan - State</h4>
              </div>
              <div className="col">
                <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>
                    <li><Tooltip title="Add">
                      <button
                        onClick={ShowMoreExcitingThanState}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                      </button></Tooltip>
                    </li>
                    <li><Tooltip title="Import">
                      <button
                        onClick={() => {
                          importBiggerThanStatecsv(5);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-upload"></i>
                      </button></Tooltip>
                    </li>

                    <li><Tooltip title="Delete">
                      <button
                        onClick={() => {
                          importStateDeletecsv(5);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la flaticon-delete-1"></i>
                      </button></Tooltip>
                    </li>
                    <Tooltip title="Export"><li>
                      <CSVDownloader
                        className="px-0"
                        data={ExportMoreExcitingThanState}
                        filename={`${ClientName}-MoreExciting_-_State`}
                        bom={true}
                      >
                        <button
                          onClick={() => {
                            ExportCsvMsg(4);
                          }}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                        </button>
                      </CSVDownloader>
                    </li></Tooltip>
                  </ul>
                </div>
              </div>
            </div>

            <div className="px-0 py-2" id="AddBox4">
              <div className="row borderbtnm pt-2 pb-3 mx-0">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 potential_text"> Value </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="value"
                        type="text"
                        name="Value"
                        value={Values}
                        onBlur={BiggerThanStateHandleBlur}
                        onChange={(e) => SetValues(e.target.value)}
                      />

                      {Errors.Value && (
                        <span style={{ color: "red" }}>{Errors.Value}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 potential_text"> City </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="city"
                        type="text"
                        name="City"
                        value={City}
                        onBlur={BiggerThanStateHandleBlur}
                        onChange={(e) => SetCity(e.target.value)}
                      />

                      {Errors.City && (
                        <span style={{ color: "red" }}>{Errors.City}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 potential_text"> State </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="state"
                        type="text"
                        name="State"
                        value={State}
                        onBlur={BiggerThanStateHandleBlur}
                        onChange={(e) => SetState(e.target.value)}
                      />

                      {Errors.State && (
                        <span style={{ color: "red" }}>{Errors.State}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row borderbtnm pb-3 mx-0">
                <div className="col-xl-12">
                  <div class="alignright">
                    {EditID == 0 ? (
                      <button
                        class="btn btn-primary btnColor"
                        onClick={MoreExcitingThanState}
                        disabled={BtnDisabled}
                      >
                        <i class="la la-save"></i> Save
                      </button>
                    ) : (
                      <button
                        class="btn btn-primary btnColor"
                        disabled={BtnDisabled}
                        onClick={UpdateMoreExcitingThanStateDetails}
                      >
                        <i class="la la-save"></i> Save
                      </button>
                    )}

                    <button
                      class="btn btn-secondary"
                      onClick={CancelMoreExcitingThanState}
                    >
                      <i class="la flaticon-cancel"></i> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-0">
              <div className="col ">
                <div className="table-bordered border-none">
                  <MoreExcitingThanStateTable
                    key={UpdateKey}
                    EditBtnMoreExcitingThanState={EditBtnMoreExcitingThanState}
                    updata={UpdataChildComponent}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={5}>
          <div className="tabmainerinner p-0">
            <div className="row borderbtnm mx-0">
              <div className="col padd-0">
                <h4 className="headertitlebd xs-headertitle">MoreExcitingThan - Generic</h4>
              </div>
              <div className="col">
                <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>
                    <li><Tooltip title="Add">
                      <button
                        onClick={ShowMoreExcitingThanGeneric}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                      </button></Tooltip>
                    </li>
                    <li><Tooltip title="Import">
                      <button
                        onClick={() => {
                          importBiggerThanStatecsv(6);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-upload"></i>
                      </button></Tooltip>
                    </li>

                    <li><Tooltip title="Delete">
                      <button
                        onClick={() => {
                          importStateDeletecsv(6);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la flaticon-delete-1"></i>
                      </button></Tooltip>
                    </li>
                    <Tooltip title="Expoer"><li>
                      <CSVDownloader
                        className="px-0"
                        data={ExportMoreExcitingThanGeneric}
                        filename={`${ClientName}-MoreExcitingThan_-_Generic`}
                        bom={true}
                      >
                        <button
                          onClick={() => {
                            ExportCsvMsg(5);
                          }}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                        </button>
                      </CSVDownloader>
                    </li></Tooltip>
                  </ul>
                </div>
              </div>
            </div>

            <div className="px-0 py-2" id="AddBox5">
              <div className="row borderbtnm pt-2 pb-3 mx-0">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 potential_text"> Value </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="value"
                        type="text"
                        name="Value"
                        value={Values}
                        onBlur={GenericHandlerBlur}
                        onChange={(e) => SetValues(e.target.value)}
                      />

                      {Errors.Value && (
                        <span style={{ color: "red" }}>{Errors.Value}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row borderbtnm pb-3 mx-0">
                <div className="col-xl-12">
                  <div class="alignright">
                    {EditID == 0 ? (
                      <button
                        class="btn btn-primary btnColor"
                        onClick={MoreExcitingThanGeneric}
                        disabled={BtnDisabled}
                      >
                        <i class="la la-save"></i> Save
                      </button>
                    ) : (
                      <button
                        class="btn btn-primary btnColor"
                        disabled={BtnDisabled}
                        onClick={UpdateMoreExcitingThanGenericDetails}
                      >
                        <i class="la la-save"></i> Save
                      </button>
                    )}

                    <button
                      class="btn btn-secondary"
                      onClick={CancelMoreExcitingThanGeneric}
                    >
                      <i class="la flaticon-cancel"></i> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-0">
              <div className="col ">
                <div className="table-bordered border-none">
                  <MoreExcitingThanGenericTable
                    key={UpdateKey}
                    EditBtnMoreExcitingThanGeneric={
                      EditBtnMoreExcitingThanGeneric
                    }
                    updata={UpdataChildComponent}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={6}>
          <div className="tabmainerinner p-0">
            <div className="row borderbtnm mx-0">
              <div className="col padd-0">
                <h4 className="headertitlebd xs-headertitle">MascotCheer - State</h4>
              </div>
              <div className="col">
                <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>
                    <li><Tooltip title="Add">
                      <button
                        onClick={ShowMascotCheerState}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                      </button></Tooltip>
                    </li>
                    <li><Tooltip title="Import">
                      <button
                        onClick={() => {
                          importBiggerThanStatecsv(7);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-upload"></i>
                      </button></Tooltip>
                    </li>

                    <li><Tooltip title="Delete">
                      <button
                        onClick={() => {
                          importStateDeletecsv(7);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la flaticon-delete-1"></i>
                      </button></Tooltip>
                    </li>
                    <Tooltip title="Export"><li>
                      <CSVDownloader
                        className="px-0"
                        data={MascotCheerState}
                        filename={`${ClientName}-MascotCheer_-_State`}
                        bom={true}
                      >
                        <button
                          onClick={() => {
                            ExportCsvMsg(6);
                          }}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                        </button>
                      </CSVDownloader>
                    </li></Tooltip>
                  </ul>
                </div>
              </div>
            </div>

            <div className="px-0 py-2" id="AddBox6">
              <div className="row borderbtnm pt-2 pb-3 mx-0">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 potential_text"> Value </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="value"
                        type="text"
                        name="Value"
                        value={Values}
                        onBlur={BiggerThanStateHandleBlur}
                        onChange={(e) => SetValues(e.target.value)}
                      />

                      {Errors.Value && (
                        <span style={{ color: "red" }}>{Errors.Value}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 potential_text"> City </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="city"
                        type="text"
                        name="City"
                        value={City}
                        onBlur={BiggerThanStateHandleBlur}
                        onChange={(e) => SetCity(e.target.value)}
                      />

                      {Errors.City && (
                        <span style={{ color: "red" }}>{Errors.City}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 potential_text"> State </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="state"
                        type="text"
                        name="State"
                        value={State}
                        onBlur={BiggerThanStateHandleBlur}
                        onChange={(e) => SetState(e.target.value)}
                      />

                      {Errors.State && (
                        <span style={{ color: "red" }}>{Errors.State}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row borderbtnm pb-3 mx-0">
                <div className="col-xl-12">
                  <div class="alignright">
                    {EditID == 0 ? (
                      <button
                        class="btn btn-primary btnColor"
                        onClick={MascotCheerStateSave}
                        disabled={BtnDisabled}
                      >
                        <i class="la la-save"></i> Save
                      </button>
                    ) : (
                      <button
                        class="btn btn-primary btnColor"
                        onClick={UpdateMascotCheerStateDetails}
                        disabled={BtnDisabled}
                      >
                        <i class="la la-save"></i> Save
                      </button>
                    )}

                    <button
                      class="btn btn-secondary"
                      onClick={CancelMascotCheerState}
                    >
                      <i class="la flaticon-cancel"></i> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-0">
              <div className="col ">
                <div className="table-bordered border-none">
                  <MascotCheerStateTable
                    key={UpdateKey}
                    EditBtnMascotCheerState={EditBtnMascotCheerState}
                    updata={UpdataChildComponent}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={7}>
          <div className="tabmainerinner p-0">
            <div className="row borderbtnm mx-0">
              <div className="col padd-0">
                <h4 className="headertitlebd xs-headertitle">MascotCheer - Generic</h4>
              </div>
              <div className="col">
                <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>
                    <li><Tooltip title="Add">
                      <button
                        onClick={ShowMascotCheerGeneric}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                      </button></Tooltip>
                    </li>
                    <li><Tooltip title="Import">
                      <button
                        onClick={() => {
                          importBiggerThanStatecsv(8);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-upload"></i>
                      </button></Tooltip>
                    </li>

                    <li><Tooltip title="Delete">
                      <button
                        onClick={() => {
                          importStateDeletecsv(8);
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la flaticon-delete-1"></i>
                      </button></Tooltip>
                    </li>
                    <Tooltip title="Export"><li>
                      <CSVDownloader
                        className="px-0"
                        data={MascotCheerGeneric}
                        filename={`${ClientName}-MascotCheer_-_Generic`}
                        bom={true}
                      >
                        <button
                          onClick={() => {
                            ExportCsvMsg(7);
                          }}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                        </button>
                      </CSVDownloader>
                    </li></Tooltip>
                  </ul>
                </div>
              </div>
            </div>

            <div className="px-0 py-2" id="AddBox7">
              <div className="row borderbtnm pt-2 pb-3 mx-0">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 potential_text"> Value </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="value"
                        type="text"
                        name="Value"
                        value={Values}
                        onBlur={GenericHandlerBlur}
                        onChange={(e) => SetValues(e.target.value)}
                      />

                      {Errors.Value && (
                        <span style={{ color: "red" }}>{Errors.Value}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row borderbtnm pb-3 mx-0">
                <div className="col-xl-12">
                  <div class="alignright">
                    {EditID == 0 ? (
                      <button
                        disabled={BtnDisabled}
                        class="btn btn-primary btnColor"
                        onClick={MascotCheerGenericStateSave}
                      >
                        <i class="la la-save"></i> Save
                      </button>
                    ) : (
                      <button
                        disabled={BtnDisabled}
                        class="btn btn-primary btnColor"
                        onClick={UpdateMascotCheerGenericDetails}
                      >
                        <i class="la la-save"></i> Save
                      </button>
                    )}

                    <button
                      class="btn btn-secondary"
                      onClick={CancelMascotCheerGeneric}
                    >
                      <i class="la flaticon-cancel"></i> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-0">
              <div className="col ">
                <div className="table-bordered border-none">
                  <MascotCheerGenericTable
                    key={UpdateKey}
                    EditMascotCheerGenericBtn={EditMascotCheerGenericBtn}
                    updata={UpdataChildComponent}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}
