import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
const moment = require("moment");
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
toast.configure();

import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import ArticleCategories from "../../_components/user/ArticleCategories/ArticleCategories";

class ArticleCategoriesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ClientID: null,
      UserID: null,
      CUserID: null,
      StartDate: null,
      EndDate: null,
      exportdata: {
        Start: moment(new Date().setMonth(new Date().getMonth() - 3)).format(
          "YYYY/MM/DD"
        ),
        End: moment(new Date()).format("YYYY/MM/DD"),
      },
      pageData: null,
    };
  }
  render() {
    return (
      <>   
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="listblock">
              <ArticleCategories />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedArticleCategoriesPage = connect(mapState, actionCreators)(ArticleCategoriesPage);
export { connectedArticleCategoriesPage as ArticleCategoriesPage };
