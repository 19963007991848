import React, { useState, useEffect } from 'react';
import Axios from "axios";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif"; 
 
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";

const ArticleCategories = () => {
    const [CategoryList, setCategoryList] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = `Article Categories | SalesHive`;
        const fetchCategory = async () => {
            await getCategories();
        }
        fetchCategory()
        setIsLoading(true);
    }, []);

    const getCategories = async () => {
        try {
            const response = await Axios({
                url: CommonConstants.MOL_APIURL + "/knowledge_base/fetchCategories",
                method: "GET"
            });
            if (response.data && response.data.StatusMessage == "SUCCESS") {
                const categoryData = response.data.Data;
                if (categoryData.length > 0) {
                    const categoryList = categoryData.map((val) => {
                        return { value: val._id, label: val.FieldName, cid: val._id };
                    });
                    setCategoryList(categoryList);

                }
            }
            else {
                toast.error(response.data.Message);
            }
            setIsLoading(false);
        }
        catch (error) {
            toast.error(error.message);
        }
    }

    const getArticles = (id) => {
        const obj = {
            categoryId: id
        }
        history.push("/articlelists", obj);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const obj = {
            searchText: inputValue
        }
        history.push("/articlelists", obj);
        setInputValue('');
    };

    return (
        <>
            {
                isLoading === true ? <div id="hideloding" className="loding-display">
                    <img src={loadingicon} />
                </div> : null
            }
            <div>
                <div className='header-Knowledge py-5 mt-3'>
                    <div className='maxwidth-header'>
                        <h1>Hello. How can we help you?</h1>
                        
                        <div className='input-boxserch'>
                            <svg width='22px' viewBox="0 0 12 13"><g stroke-width="2" stroke="#999999" fill="none"><path d="M11.29 11.71l-4-4"/><circle cx="5" cy="5" r="4"/></g></svg>
                            <form onSubmit={handleSubmit}>
                                <input
                                    type="text"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    class="form-control m-input" 
                                    placeholder="Search articles"
                                />
                                {/* <button type="submit">Submit</button> */}
                            </form>
                        </div>
                    </div>
                </div>
                <div class="mt-5 max-widthcard"> 
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid className='grid-boxcard' container spacing={2}>
                            {CategoryList?.map((row) => (
                                <Grid xs={6} md={4}>
                                    <a
                                        onClick={() => {
                                            getArticles(row.cid);
                                        }}
                                        className="btn-eyesicon"
                                        >
                                    <div>
                                        <h4>
                                            {row?.label}
                                        </h4>
                                        {/* <p>Articles & Resources for the SDR - Cold Calling Specialist Role</p> */}
                                    </div>
                                </a>
                                </Grid> 
                            ))}
                        </Grid>
                    </Box>  
                </div>
            </div>
        </>
    );
};

export default ArticleCategories;