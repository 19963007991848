import React from "react";
import Axios from "axios";
const moment = require("moment");
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import "react-toastify/dist/ReactToastify.css";

import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";

toast.configure();
var atob = require("atob");

class EditCEmailAccountsPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      EmailNameAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ChildUserID,
      ParentUserID:Details.ParentUserID,
      Role: Details.Role,
      Typelist: [],
      VariableTypeID: null,
      CustomVariable: {},
      IsExist: false,
      DefualtSelectAcounAuthtype: 1,
      DefualtSelectAccountType: null,
      DefultSelectAccountSMTPType: null,
      DefultSelectAccountIMAPType: null,
      SmtpHost: "smtp.gmail.com",
      ImapHost: "imap.gmail.com",
      SmtpPort: "465",
      ImapPort: "993",
      AccountType: [],
      AccountSslType: [],
      AccountAuthType: [],
      AccountSetValue: null,
      EmailWarmingReceiving: null,
      EmailWarmingSending: null,
      SendingSchedul: [],
      DefaultSendingSchedul: null,
      AID: "",
      RefreshToken: null,
      IsValid: false,
      IsValidImap: false,
      Oldname: null,
      IsAuthonicate: false,
      BtnDisabled:false,
      NewRefreshToken:null,
      Authenticationsuccess:false,
      AuthenticationError:false,
      IsLoading:true
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.EmailAuthenicate = this.EmailAuthenicate.bind(this);
  }
  componentDidMount() {
    document.title = "Update | SalesHive";
    this.CheckAccountAuthonicate();
    this.AllCommonfieldGet();
    this.SendingSchedulingTimeGet();
    this.setState({
      IsLoading:false
    });
  }



 async CheckAccountAuthonicate() {
    //debugger
    var queryparamter = window.location.search.substring(1);
    
    if (queryparamter != "") {
      
      var ResultMessage = atob(queryparamter.split("data=")[1]);
     // debugger
      var AAccountID = ResultMessage.split("account=")[1].split("message=")[0];
      this.EmailAccountIdByData(AAccountID);

      this.setState({AID:AAccountID})
      // 
      var RefreshToken = ResultMessage.split("page=")[0];
      // this.EmailAccountIdByData(AAccountID);
      

      // 
      //this.props.location.state.id = AccountID;
      if (RefreshToken != null || RefreshToken != "") {
        // this.EmailAccountIdByData(AAccountID)
        this.setState({NewRefreshToken:RefreshToken})
        // this.setState({IsAuthonicate: true});
        var Emailfromauth= ResultMessage.split("email=")[1];
        let EmailData = localStorage.getItem("FromEmail")
        
        if (EmailData && EmailData != null) {
          document.getElementById("SMTPFromEmail").value = localStorage.getItem("FromEmail");
          document.getElementById("SMTPFromEmail").readonly = true;
            function normalizeEmail(email) {
              const [local, domain] = email.split('@');
              
              const normalizedLocal = local.split('+')[0].replace(/\./g, '');
              
              return normalizedLocal + '@' + domain;
            }
            
            let datas=this.state.DefualtSelectAcounAuthtype 
          //  const emailfromstorage=await normalizeEmail(EmailData)
          if(Emailfromauth==EmailData){
            
            const dataParam =ResultMessage.split("page=")[1].split("message=")[1].split("email=")[0];
            if (dataParam && dataParam=="SUCCESS") {
              this.setState({
                Authenticationsuccess: true,
              });
            }
            this.setState({
              IsAuthonicate: true,
            });
          }else{
            const dataParam =ResultMessage.split("page=")[1].split("message=")[1].split("email=")[0];
            if (dataParam && dataParam=="SUCCESS") {
              this.setState({
                AuthenticationError: true,
                IsAuthonicate: false,
                  RefreshToken: null,
                  NewRefreshToken:null
              });
            }
            
          }
        }
      }
    } else {
      document.getElementById("SMTPFromEmail").readonly = true;
      this.setState({AID:this.props.location.state.id})
      //this.props.location.state.id = this.props.location.state.id;
      this.EmailAccountIdByData(this.props.location.state.id);
    }
  }

  // For edit details
  EmailAccountIdByData(AccountID) {
    
    var str_in = {
      id: AccountID,
      Role: this.state.Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/emailaccount/AccountlistByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      
      if (res.data.StatusMessage == "SUCCESS") {
        
        
        // document.getElementById("hideloding").style.display = "none";
        this.setState({
          IsLoading:false
        });
        let Emails = res.data.Data[0].SMTPFromEmail;
        
        if (localStorage.getItem("FromEmail") === undefined || localStorage.getItem("FromEmail") === null || localStorage.getItem("FromEmail") === "") {
          localStorage.setItem("FromEmail", Emails);
        }
        
        document.getElementById("SMTPFromEmail").value = localStorage.getItem("FromEmail");
        

        // document.getElementById("SMTPFromEmail").value =localStorage.getItem('FromEmail')
        document.getElementById("AliasTitle").value =
          res.data.Data[0].AliasTitle;
        document.getElementById("Name").value = res.data.Data[0].Name;
        document.getElementById("SMTPEmail").value = res.data.Data[0].SMTPEmail;
        document.getElementById("IMAPEmail").value = res.data.Data[0].IMAPEmail;
        document.getElementById("SMTPPassword").value =
          res.data.Data[0].SMTPPassword;
        document.getElementById("IMAPPassword").value =
          res.data.Data[0].IMAPPassword;
        document.getElementById("SMTPHost").value = res.data.Data[0].SMTPHost;
        document.getElementById("IMAPHost").value = res.data.Data[0].IMAPHost;
        document.getElementById("SMTPPort").value = res.data.Data[0].SMTPPort;
        document.getElementById("IMAPPort").value = res.data.Data[0].IMAPPort;
        document.getElementById("ThrottleStartingVolume").value =
          res.data.Data[0].ThrottleStartingVolume;
        document.getElementById("ThrottleEndingVolume").value =
          res.data.Data[0].ThrottleEndingVolume;
        document.getElementById("EmailDelay").value =
          res.data.Data[0].EmailDelay;
        document.getElementById("DaysTillFullThrottle").value =
          res.data.Data[0].DaysTillFullThrottle;
        this.setState({ Oldname: res.data.Data[0].SMTPFromEmail });
        if (this.state.IsAuthonicate == false) {
          this.setState({ IsAuthonicate: res.data.Data[0]?.IsAuthenticated });
        }
        this.setState({
          DefaultSendingSchedul: res.data.Data[0].SendingSchedularID,
        });

        this.setState({
          DefualtSelectAcounAuthtype: res.data.Data[0].AccountAuthTypeID,
        });

        this.setState({
          DefultSelectAccountSMTPType: res.data.Data[0].SMTPSSLID,
        });
        this.setState({
          DefultSelectAccountIMAPType: res.data.Data[0].IMAPSSLID,
        });
        this.setState({
          DefualtSelectAccountType: res.data.Data[0].AccountTypeID,
        });
        this.setState({
          EmailWarmingSending: res.data.Data[0].IsEmailWarmingSending,
        });
        this.setState({
          EmailWarmingReceiving: res.data.Data[0].IsEmailWarmingReceiving,
        });
        this.setState({
          AccountID: res.data.Data[0].AccountID,
        });
        // let FromEmails = document.getElementById('SMTPFromEmail').value;
        // localStorage.setItem('FromEmail',FromEmails);
        this.setState({ RefreshToken: res.data?.Data[0]?.RefreshToken });
        //     if(res.data.Data[0].AccountAuthTypeID==2)
        // {
        //   this.setState({
        //   IsDispalyAuthonicate:true
        //   })
        //     }
        //     else
        //     {
        //       this.setState({
        //         IsDispalyAuthonicate:false
        //         })
        //     }
        this.setState({
          IsLoading:false
        });
      }
    });
  }


  // get time scheduling
  SendingSchedulingTimeGet() {
    var str_in = {
      ClientID: this.state.ClientID,
      Role: this.state.Role,
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/emailaccount/SendingschedulingGet",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
      this.setState({
        SendingSchedul: result.data.PageData,
      });
    });
  }

  async FormValidation() {
    let formIsValid = true;
    let Errors = {};
    this.setState({ IsValid: false });
    this.setState({ IsValidImap: false });

    let Email = document.getElementById("SMTPFromEmail").value.trim();
    let Name = document.getElementById("Name").value.trim();
    var AccountAuthTypeID = document.getElementById("AccountAuthTypeID").value;
    localStorage.setItem("FromEmail", Email);
    let IsExist = await this.CheckExistEmailAccountsName(Email);
    var ThrottleStartingVolume = document
      .getElementById("ThrottleStartingVolume")
      .value.trim();
    var ThrottleEndingVolume = document
      .getElementById("ThrottleEndingVolume")
      .value.trim();
    var EmailDelay = document.getElementById("EmailDelay").value.trim();
    var DaysTillFullThrottle = document
      .getElementById("DaysTillFullThrottle")
      .value.trim();

    if (ThrottleStartingVolume == "") {
      formIsValid = false;
      Errors["ThrottleStartingVolume"] =
        "Please enter throttle starting volume";
    }

    if (ThrottleEndingVolume == "") {
      formIsValid = false;
      Errors["ThrottleEndingVolume"] = "Please enter throttle ending volume";
    }

    if (EmailDelay == "") {
      formIsValid = false;
      Errors["EmailDelay"] = "Please enter email delay";
    }

    if (DaysTillFullThrottle == "") {
      formIsValid = false;
      Errors["DaysTillFullThrottle"] = "Please enter days till full throttle";
    }
    
    if (parseInt(EmailDelay) <= 0) {
      formIsValid = false;
      Errors["EmailDelay"] = "Please enter a value greater than or equal to 1.";
    }

    if (parseInt(DaysTillFullThrottle) <= 0) {
      formIsValid = false;
      Errors["DaysTillFullThrottle"] = "Please enter a value greater than or equal to 1.";
    }

    if (Email == "") {
      formIsValid = false;
      Errors["SMTPFromEmail"] = "Please enter from email";
    }

    if (Name == "") {
      formIsValid = false;
      Errors["Name"] = "Please enter full name";
    }
    if (this.state.IsValid == true) {
      formIsValid = false;
      Errors["SMTPFromEmail"] = "Invalid from email.";
    }

    if (IsExist == true) {
      formIsValid = false;
    }
    if (AccountAuthTypeID == 1) {
      var SMTPHost = document.getElementById("SMTPHost").value.trim();
      var IMAPHost = document.getElementById("IMAPHost").value.trim();
      var SMTPPort = document.getElementById("SMTPPort").value.trim();
      var IMAPPort = document.getElementById("IMAPPort").value.trim();
      var SMTPSSLID = document.getElementById("SMTPSSLID").value;
      var IMAPSSLID = document.getElementById("IMAPSSLID").value;
      let IMAPEmail = document.getElementById("IMAPEmail").value.trim();

      let IsExists = await this.MeetingImapCheckExist(IMAPEmail);

      if (SMTPHost == "") {
        formIsValid = false;
        Errors["SMTPHost"] = "Please enter from SMTPHost";
      }

      if (IMAPHost == "") {
        formIsValid = false;
        Errors["IMAPHost"] = "Please enter from IMAPHost";
      }

      if (SMTPPort == "") {
        formIsValid = false;
        Errors["SMTPPort"] = "Please enter from SMTPPort";
      }

      if (IMAPPort == "") {
        formIsValid = false;
        Errors["IMAPPort"] = "Please enter from IMAPPort";
      }

      if (IsExists == true) {
        formIsValid = false;
      }
      if (this.state.IsValidImap == true) {
        formIsValid = false;
        Errors["IMAPEmail"] = "Invalid imap email.";
      }

      if (SMTPSSLID == "") {
        formIsValid = false;
        Errors["SMTPSSLID"] = "Please enter from SMTPSSLID";
      }

      if (IMAPSSLID == "") {
        formIsValid = false;
        Errors["IMAPSSLID"] = "Please enter from IMAPSSLID";
      }
    }

    if (parseInt(ThrottleStartingVolume) > parseInt(ThrottleEndingVolume) && parseInt(ThrottleStartingVolume) > 0 && parseInt(ThrottleEndingVolume) > 0) {
      // if (formIsValid) {
        formIsValid = false;
        Errors["ThrottleStartingVolume"] = "Throttle starting volume must be less than throttle ending volume.";
        Errors["ThrottleEndingVolume"] = "Throttle ending volume must be greater than throttle starting volume.";
     // }
    }
    if (parseInt(ThrottleStartingVolume) == parseInt(ThrottleEndingVolume) && parseInt(ThrottleStartingVolume) !=0 && parseInt(ThrottleEndingVolume) !=0) {
     // if (formIsValid) {
        // formIsValid = false;
        Errors["ThrottleStartingVolume"] = "";
        Errors["ThrottleEndingVolume"] = "";
    //  }
    }

    if (parseInt(ThrottleStartingVolume) <= 0) {
      // if (formIsValid) {
         formIsValid = false;
         Errors["ThrottleStartingVolume"] = "Please enter a value greater than or equal to 1.";
     //  }
     }

     if (parseInt(ThrottleEndingVolume) <= 0) {
      // if (formIsValid) {
         formIsValid = false;
         Errors["ThrottleEndingVolume"] = "Please enter a value greater than or equal to 1.";
     //  }
     }

     if (ThrottleStartingVolume == "") {
      // if (formIsValid) {
         formIsValid = false;
         Errors["ThrottleStartingVolume"] = "Please enter throttle starting volume";
     //  }
     }

     if (ThrottleEndingVolume == "") {
      // if (formIsValid) {
         formIsValid = false;
         Errors["ThrottleEndingVolume"] = "Please enter throttle ending volume";
     //  }
     }

    if (parseInt(ThrottleStartingVolume) >200) {
      formIsValid = false;
      Errors["ThrottleStartingVolume"] = "Please enter value less than or equal to 200.";
    }

    if (parseInt(ThrottleEndingVolume) >1000) {
      formIsValid = false;
      Errors["ThrottleEndingVolume"] = "Please enter value less than or equal to 1000.";
    }

    this.setState({ Errors: Errors });

    return formIsValid;
  }

  // HandleChange
  HandleChange(Field, e) {
      
      const { Fields, Errors } = this.state;
      Fields[Field] = e.target.value;
      this.setState({ Fields });
  
      if (Field === "SMTPFromEmail") {
        if (e.target.value !== "") {
          
          Errors.SMTPFromEmail = null;
          this.setState({ Errors, StatusAvailable: null });
          localStorage.setItem("FromEmail", e.target.value);
          this.CheckExistEmailAccountsName(e.target.value);
        } else {
          let newErrors = { ...Errors };
          newErrors["SMTPFromEmail"] = "Please Enter email";
          this.setState({ Errors: newErrors, StatusAvailable: null });
        }
      }
   
  }

  // check exit name
  async CheckExistEmailAccountsName(FromEmail) {
    let Errors = {};

    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if(regexp.test(FromEmail) == false){
        Errors["SMTPFromEmail"] = "Invalid from email.";
        this.setState({ Errors: Errors });
      }else{
        var str_in = {
          ClientID: this.state.ClientID,
          SMTPFromEmail: FromEmail,
          ParentUserID:this.state.ParentUserID,
          Role: this.state.Role,
        };
        let resdata = await Axios({
          url: CommonConstants.MOL_APIURL + "/emailaccount/EmailAccountExists",
          method: "POST",
          data: str_in,
        });
        if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data.length > 0) {
            if (this.state.Oldname == FromEmail) {
              
              this.setState({ IsExist: false });
              return false;
            } else {
              this.setState({
                StatusAvailable: resdata.data.Data[0]?.SMTPFromEmail,
              });
              this.setState({ IsExist: true });
              return true;
            }
          } else {
            // let Errors = {};
            // if (regexp.test(FromEmail) == false && FromEmail != "") {
            //   Errors["SMTPFromEmail"] = "Invalid from email.";
            //   this.setState({ Errors: Errors });
            //   this.setState({ IsValid: true });
            //   return true;
            // } else {
            //   this.setState({ IsExist: false });
            //   return false;
            // }
          }
        }
      }
   
  }

  // HandleChange
  HandleImapChange(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });

    if (Fields.IMAPEmail != "") {
      this.state.Errors.IMAPEmail = null;
      // this.state.StatusAvailable = null;
      this.MeetingImapCheckExist(e.target.value);
    } else {
      // this.state.StatusAvailable = null;
      this.state.Errors.IMAPEmail = null;
      // let Errors = {};
      // Errors["SMTPFromEmail"] = "Please enter from email";
      // this.setState({ Errors: Errors })
    }
  }

  async MeetingImapCheckExist(Email) {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let Errors = {};
    if (!regexp.test(Email) && Email != "") {
      Errors["IMAPEmail"] = "Invalid imap email.";
      this.setState({ Errors: Errors });
      this.setState({ IsValidImap: true });
      return true;
    } else {
      // this.setState({ IsExist: false });
      return false;
    }
  }

  // handle change field
  HandledropdownChangeInput(e) {
    this.setState({
      FieldID: e.target.value,
    });
    this.setState({ DefualtSelectAcounAuthtype: e.target.value });
    if (e.target.value == 2) {
      this.setState({
        IsDispalyAuthonicate: true,
      });
      this.setState({EmailWarmingReceiving:true})
      this.setState({EmailWarmingSending:true})
    } else {
      this.setState({
        IsDispalyAuthonicate: false,
      });
      this.setState({EmailWarmingReceiving:false})
      this.setState({EmailWarmingSending:false})
    }
  }

  // handle change smtphost
  HandleChangeSmtpHost(e) {
    this.setState({
      SmtpHost: e.target.value,
    });
  }

  // handle change imaphost
  HandleChangeImapHost(e) {
    this.setState({
      ImapHost: e.target.value,
    });
  }

  // handle change smtp port
  HandleChangeSmptPort(e) {
    this.setState({
      SmtpPort: e.target.value,
    });
  }

  // handle change imap port
  HandleChangeImapPort(e) {
    this.setState({
      ImapPort: e.target.value,
    });
  }

  // for update details
  async SaveBtn(e) {
    
    
    this.setState({BtnDisabled:true})
    e.preventDefault();
   
    var Final_flag = await this.FormValidation();

    if (Final_flag == true) {
      var AccountAuthTypeID =
        document.getElementById("AccountAuthTypeID").value;

      if (AccountAuthTypeID == 1) {
        var AliasTitle = document.getElementById("AliasTitle").value;
        var Name = document.getElementById("Name").value;

        var SMTPEmail = document.getElementById("SMTPEmail").value;
        var IMAPEmail = document.getElementById("IMAPEmail").value;
        var SMTPPassword = document.getElementById("SMTPPassword").value;
        var IMAPPassword = document.getElementById("IMAPPassword").value;
        var SMTPHost = document.getElementById("SMTPHost").value;
        var IMAPHost = document.getElementById("IMAPHost").value;
        var SMTPPort = document.getElementById("SMTPPort").value;
        var IMAPPort = document.getElementById("IMAPPort").value;
        var ThrottleStartingVolume = document.getElementById(
          "ThrottleStartingVolume"
        ).value;
        var ThrottleEndingVolume = document.getElementById(
          "ThrottleEndingVolume"
        ).value;
        var EmailDelay = document.getElementById("EmailDelay").value;
        var DaysTillFullThrottle = document.getElementById(
          "DaysTillFullThrottle"
        ).value;
        var SMTPSSLID = document.getElementById("SMTPSSLID").value;
        var IMAPSSLID = document.getElementById("IMAPSSLID").value;
        var SendingSchedularID =
          document.getElementById("SendingSchedularID").value;

        var AccountTypeID = document.getElementById("AccountTypeID").value;
        var InputParameters = {
          _id: this.state.AID,
          SMTPFromEmail: SMTPFromEmail,
          AliasTitle: AliasTitle,
          Name: Name,
          AccountAuthTypeID: AccountAuthTypeID,
          SMTPEmail: SMTPEmail,
          IMAPEmail: IMAPEmail,
          SMTPPassword: SMTPPassword,
          IMAPPassword: IMAPPassword,
          SMTPHost: SMTPHost,
          IMAPHost: IMAPHost,
          SMTPPort: SMTPPort,
          IMAPPort: IMAPPort,
          ThrottleStartingVolume: ThrottleStartingVolume,
          ThrottleEndingVolume: ThrottleEndingVolume,
          EmailDelay: EmailDelay,
          DaysTillFullThrottle: DaysTillFullThrottle,
          SMTPSSLID: SMTPSSLID,
          IMAPSSLID: IMAPSSLID,
          SendingSchedularID: SendingSchedularID,
          AccountTypeID: AccountTypeID,
          IsDeleted: false,
          IsEmailWarmingSending: false,
          IsEmailWarmingReceiving: false,
          LastUpdatedBy: this.state.UserID,
          LastUpdatedDate: new Date(),
          RefreshToken: this.state.NewRefreshToken == null ? this.state.RefreshToken : this.state.NewRefreshToken,
          IsAuthenticated: this.state.IsAuthonicate == true ? true : false,
          IsWorking: this.state.RefreshToken == null ? true : false,
          Role: this.state.Role,
          ClientID:this.state.ClientID,
          CreatedBy:this.state.UserID
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/emailaccount/EmailAccountUpdate",
          method: "POST",
          data: InputParameters,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(<div className="toastsize">Account Edit <br/>Account updated successfully.</div>);
            if(this.props.location.state.pagename == "clientemailaccounts"){
              history.push("/clientemailaccounts");
            }else{
              history.push("/CEmailAccounts");
            }
            localStorage.setItem("FromEmail", "");
          } else {
            toast.error(res.data.Message);
            this.setState({BtnDisabled:false})

          }
        });
      } else if (AccountAuthTypeID == 2) {
        var SMTPFromEmail = document.getElementById("SMTPFromEmail").value;
        var AliasTitle = document.getElementById("AliasTitle").value;
        var Name = document.getElementById("Name").value;
        var ThrottleStartingVolume = document.getElementById(
          "ThrottleStartingVolume"
        ).value;
        var ThrottleEndingVolume = document.getElementById(
          "ThrottleEndingVolume"
        ).value;
        var EmailDelay = document.getElementById("EmailDelay").value;
        var DaysTillFullThrottle = document.getElementById(
          "DaysTillFullThrottle"
        ).value;
        // var SMTPSSLID = document.getElementById("SMTPSSLID").value;
        // var IMAPSSLID = document.getElementById("IMAPSSLID").value;
        var SendingSchedularID =
          document.getElementById("SendingSchedularID").value;
        var AccountTypeID = document.getElementById("AccountTypeID").value;
        var InputParameters = {
          _id: this.state.AID,
          SMTPFromEmail: SMTPFromEmail,
          AliasTitle: AliasTitle,
          Name: Name,
          AccountAuthTypeID: AccountAuthTypeID,
          SMTPEmail: "",
          IMAPEmail: "",
          SMTPPassword: "",
          IMAPPassword: "",
          SMTPHost: this.state.SmtpHost,
          IMAPHost: this.state.ImapHost,
          SMTPPort: this.state.SmtpPort,
          IMAPPort: this.state.ImapPort,
          ThrottleStartingVolume: ThrottleStartingVolume,
          ThrottleEndingVolume: ThrottleEndingVolume,
          EmailDelay: EmailDelay,
          DaysTillFullThrottle: DaysTillFullThrottle,
          SMTPSSLID: "2",
          IMAPSSLID: "2",
          SendingSchedularID: SendingSchedularID,
          AccountTypeID: AccountTypeID,
          IsDeleted: false,
          IsEmailWarmingSending: this.state.EmailWarmingSending,
          IsEmailWarmingReceiving: this.state.EmailWarmingReceiving,
          LastUpdatedBy: this.state.UserID,
          LastUpdatedDate: new Date(),
          RefreshToken: this.state.NewRefreshToken == null ? this.state.RefreshToken : this.state.NewRefreshToken,
          IsAuthenticated: this.state.IsAuthonicate,
          IsWorking: this.state.RefreshToken == null ? true : false,
          Role: this.state.Role,
          ClientID:this.state.ClientID,
          CreatedBy:this.state.UserID
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/emailaccount/EmailAccountUpdate",
          method: "POST",
          data: InputParameters,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(<div className="toastsize">Account Edit <br/>Account updated successfully.</div>);
            if(this.props.location?.state?.pagename == "clientemailaccounts"){
              history.push("/clientemailaccounts");
            }else{
              history.push("/CEmailAccounts");
            }
            localStorage.setItem("FromEmail", "");

          } else {
            toast.error(res.data.Message);
      this.setState({BtnDisabled:false})

          }
        });
      }
    } else {
      this.setState({BtnDisabled:false})

    }
  }

  EmailAuthenicate() {
    
    var AccountID = this.state.AID;
    
    var SMTPFromEmail = document.getElementById("SMTPFromEmail").value;
    localStorage.setItem("FromEmail", SMTPFromEmail);
    var ObjLoginData = {
      SMTPFromEmail: SMTPFromEmail,
    };
    localStorage.setItem("EmailAccountPage", JSON.stringify(ObjLoginData));
    var loginHint = SMTPFromEmail;
    var scope = encodeURIComponent(CommonConstants.SCOPE);
    var redirect_uri_encode = encodeURIComponent(CommonConstants.REDIRECT_URL);
    var client_id = encodeURIComponent(CommonConstants.CLIENT_ID);
    var response_type = "code";
    var access_type = "offline";
    var state = AccountID;
    const host = window.location.href;
    var Url =
      "https://accounts.google.com/o/oauth2/auth?scope=" +
      scope +
      "&redirect_uri=" +
      redirect_uri_encode +
      "&response_type=" +
      response_type +
      "&client_id=" +
      client_id +
      "&state=" +
      host +"/"+state+
      "&access_type=" +
      access_type +
      "&approval_prompt=force&login_hint=" +
      loginHint +
      "";
    window.location.href = Url;
  }

  // commonfield data get
  AllCommonfieldGet() {
    var str_in = {
      IsDeleted: false,
      Role: this.state.Role,
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/emailaccount/AccountTypeGetAll",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
      this.setState({
        AccountType: result.data.Accounttype,
        AccountSslType: result.data.AccountSsltype,
        AccountAuthType: result.data.Accountauthtype,
      });
    });
  }
  // handle dropdown change input
  HandleDropdownChangeInput(e) {
    this.setState({
      DefualtSelectAcounAuthtype: e.target.value,
    });
  }
  // handle dropdown change account
  HandleDropdownChangeAccount(e) {
    this.setState({
      DefualtSelectAccountType: e.target.DefualtSelectAccountType,
    });
  }

  // handle dropdown change sending scedule
  HandleDropdownChangeSendingScedule(e) {
    this.setState({
      DefaultSendingSchedul: e.target.value,
    });
  }

  // handle dropdown change accountsSsl
  HandledropDownChangeAccountsSsl(e) {
    this.setState({
      DefultSelectAccountSMTPType: e.target.value,
    });
  }

  // handle change throttleste volume
  HandleChangeThrottlestrVolume(e) {
    this.setState({
      ThrottlestrVolumne: e.target.value,
    });
    let ThrottleStartingVolume = document.getElementById("ThrottleStartingVolume").value.trim();
    let ThrottleEndingVolume = document.getElementById("ThrottleEndingVolume").value.trim();


    if (parseInt(ThrottleStartingVolume) > parseInt(ThrottleEndingVolume)) {
      // let Errors={}
      // Errors["ThrottleStartingVolume"] = "Throttle starting volume must be less than throttle ending volume.";
      // this.setState({ Errors: Errors });
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleStartingVolume: "Throttle starting volume must be less than throttle ending volume."
        }
      })

    }else{
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleStartingVolume: ""
        }
      })
    }

    if (parseInt(ThrottleStartingVolume) == parseInt(ThrottleEndingVolume && parseInt(ThrottleStartingVolume) !=0 && parseInt(ThrottleEndingVolume) !=0) ) {
      // let Errors={}
      // Errors["ThrottleStartingVolume"] = "";
      // this.setState({ Errors: Errors });
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleStartingVolume: ""
        }
      })

    }else if (parseInt(ThrottleStartingVolume) == 0) {
         this.setState({
          Errors: {
            ...this.state.Errors,
            ThrottleStartingVolume: "Please enter a value greater than or equal to 1."
          }
        })
     }else if (ThrottleStartingVolume == "") {
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleStartingVolume: "Please enter throttle starting volume"
        }
      })

     } else if (parseInt(ThrottleStartingVolume) > 200) {
      // let Errors={}
      // Errors["ThrottleStartingVolume"] = "Please enter value less than or equal to 200.";
      // this.setState({ Errors: Errors });
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleStartingVolume: "Please enter value less than or equal to 200."
        }
      })

    }

  }
  // handle change throttle ending volume
  HandleThrottleEndingVolume(e) {
    this.setState({
      ThrottleEndingVolume: e.target.value,
    });
    let ThrottleStartingVolume = document.getElementById("ThrottleStartingVolume").value.trim();
    let ThrottleEndingVolume = document.getElementById("ThrottleEndingVolume").value.trim();
    if (parseInt(ThrottleStartingVolume) > parseInt(ThrottleEndingVolume)) {
      // let Errors={}
      // Errors["ThrottleEndingVolume"] = "Throttle ending volume must be greater than throttle starting volume.";
      // this.setState({ Errors: Errors });
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleEndingVolume: "Throttle ending volume must be greater than throttle starting volume."
        }
      })

    }else{
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleEndingVolume: ""
        }
      })
    }

    if (parseInt(ThrottleStartingVolume) == parseInt(ThrottleEndingVolume) && parseInt(ThrottleStartingVolume) !=0 && parseInt(ThrottleEndingVolume) !=0) {
      // let Errors={}
      // Errors["ThrottleEndingVolume"] = "";
      // this.setState({ Errors: Errors });
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleEndingVolume: ""
        }
      })

    }else if (parseInt(ThrottleEndingVolume) == 0) {
      this.setState({
       Errors: {
         ...this.state.Errors,
         ThrottleEndingVolume: "Please enter a value greater than or equal to 1."
       }
     })
  } else if (ThrottleEndingVolume == "") {
   this.setState({
     Errors: {
       ...this.state.Errors,
       ThrottleEndingVolume: "Please enter throttle ending volume"
     }
   })

  } else if (parseInt(ThrottleEndingVolume) > 1000) {
      // let Errors={}
      // Errors["ThrottleStartingVolume"] = "Please enter value less than or equal to 1000.";
      // this.setState({ Errors: Errors });
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleEndingVolume: "Please enter value less than or equal to 1000."
        }
      })

    }
  }

  // handle change email delay
  HandleChangeEmailDelay(e) {
    this.setState({
      EmailDelay: e.target.value,
    });
    var EmailDelay = document.getElementById("EmailDelay").value.trim();

    if (EmailDelay == "") {
      this.setState({
        Errors: {
          ...this.state.Errors,
          EmailDelay: "Please enter email delay"
        }
      })
    }

    if (parseInt(EmailDelay) == 0) {
      this.setState({
        Errors: {
          ...this.state.Errors,
          EmailDelay: "Please enter a value greater than or equal to 1."
        }
      })
    }

    if (parseInt(EmailDelay) > 0) {
      this.setState({
        Errors: {
          ...this.state.Errors,
          EmailDelay: ""
        }
      })
    }
  }

  // handle change still full throttle
  HandleChangeDayStillFullThrottle(e) {
    this.setState({
      DaystillFullThrottle: e.target.value,
    });

    var DaysTillFullThrottle = document.getElementById("DaysTillFullThrottle").value.trim()

    if (DaysTillFullThrottle == "") {
      this.setState({
        Errors: {
          ...this.state.Errors,
          DaysTillFullThrottle: "Please enter days till full throttle"
        }
      })
    }

    if (parseInt(DaysTillFullThrottle) == 0) {
      this.setState({
        Errors: {
          ...this.state.Errors,
          DaysTillFullThrottle: "Please enter a value greater than or equal to 1."
        }
      })
    }

    if (parseInt(DaysTillFullThrottle) > 0) {
      this.setState({
        Errors: {
          ...this.state.Errors,
          DaysTillFullThrottle: ""
        }
      })
    }
    
  }

  // back button
  BackBtn() {
  localStorage.setItem("FromEmail", "");
    if(this.props.location.state.pagename == "clientemailaccounts"){
      history.push("/clientemailaccounts");
    }else{
      history.push("/CEmailAccounts");
    }
  }

  // handle change email sending
  HandleChangeCheckEmailSend = (e) => {
    const { checked } = e.target;
    
    this.setState({
      EmailWarmingSending: checked,
    });
  };
  // handle change email recieve
  HandleChangeCheckEmailReciv = (e) => {
    const { checked } = e.target;
    
    this.setState({
      EmailWarmingReceiving: checked,
    });
  };

  handleclearmessages = async () => {
    ;
    this.setState({
      Authenticationsuccess: false,
      AuthenticationError:false
    });
    const { pathname, search } = location;
    const urlParams = new URLSearchParams(search);
    urlParams.delete("data");

    history.push({
      pathname: pathname,
      search: urlParams.toString(),
    });
  };

  render() {
    return (
      <>
        { this.state.IsLoading == true ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>: <></>}
      
        {/* <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> */}
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle float-left">
                    Edit Email Configuration{" "}
                  </h4>
                </div>
              </div>

            <div className="bg-white p-3">
              <div className="pt-2">
              {this.state.Authenticationsuccess ? (
              <div
                style={{
                  backgroundColor: "#fff",

                  color: "#34bfa3",
                  fontWeight: 400,
                  padding: "0.85rem 1.5rem",
                  borderRadius: "4px",
                  position: "relative",
                  marginBottom: "1rem",
                  border: "1px solid transparent",
                  borderColor: "#34bfa3",
                }}
              >
                {" "}
                <strong> Well Done! </strong>Authentication of your account is done.{" "}
                <button
                  type="button"
                  style={{
                    float: "right",
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    lineHeight: 1,
                    color: "#34bfa3",
                    textShadow: "0 1px 0 #fff",
                    opacity: 0.5,
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={this.handleclearmessages}
                >
                  x
                </button>
              </div>
            ) :  null}
              {this.state.AuthenticationError ? (
              <div
                style={{
                  backgroundColor: "#fff",

                  color: "#f70f0f",
                  fontWeight: 400,
                  padding: "0.85rem 1.5rem",
                  borderRadius: "4px",
                  position: "relative",
                  marginBottom: "1rem",
                  border: "1px solid transparent",
                  borderColor: "#f70f0f",
                }}
              >
                {" "}
                <strong> Failed! </strong>Please authenticate with similar email account.{" "}
                <button
                  type="button"
                  style={{
                    float: "right",
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    lineHeight: 1,
                    color: "#f70f0f",
                    textShadow: "0 1px 0 #fff",
                    opacity: 0.5,
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={this.handleclearmessages}
                >
                  x
                </button>
              </div>
            ) :  null}
              <div className="row px-2">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">From Email </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter  name"
                        onBlur={this.HandleChange.bind(this, "SMTPFromEmail")}
                        // value={this.state.Fields["SMTPFromEmail"]}
                        id="SMTPFromEmail"
                        name="SMTPFromEmail"
                        placeholder=""
                        type="text"
                        disabled="disabled"
                      />
                      <span style={{ color: "red" }}>
                      <small>
                        {this.state.Errors["SMTPFromEmail"]}
                        </small>
                      </span>
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                           <small>
                          From email already exist.
                          </small>
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                {this.state.DefualtSelectAcounAuthtype == "2" &&
                this.state.IsAuthonicate == false ? (
                  <>
                    <div
                      className="col-lg-6 boxsinput_group"
                      id="authenticate_btn"
                      onClick={this.EmailAuthenicate}
                    >
                      <div className="row max-cols">
                        <a className="btn btn-primary btnColor">
                          <span>Authenticate</span>
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols"></div>
                    </div>
                  </>
                )}

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Alias Title </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="AliasTitle"
                        name="AliasTitle"
                        type="text"
                        // onChange={this.HandleChange.bind(this, "AliasTitle")}
                        // value={this.state.Fields["AliasTitle"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group"> </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Full Name </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="Name"
                        name="Name"
                        type="text"
                        // onChange={this.HandleChange.bind(this, "Name")}
                        // value={this.state.Fields["Name"]}
                      />
                      <span style={{ color: "red" }}>
                      <small>
                        {this.state.Errors["Name"]}
                        </small>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Account Auth Type{" "}
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="AccountAuthTypeID"
                        name="AccountAuthTypeID"
                        autoComplete="off"
                        value={this.state.DefualtSelectAcounAuthtype}
                        // value={this.state.FieldID}
                        onChange={this.HandledropdownChangeInput.bind(this)}
                      >
                        {this.state.AccountAuthType.map((value) => (
                          <option key={value.FieldID} value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                {/* Edit set */}
                {this.state.DefualtSelectAcounAuthtype == 1 ? (
                  <>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols">
                        <label className="col-lg-4 view-lable-meet">
                          SMTP Email{" "}
                        </label>
                        <div className="col-lg-8">
                          <input
                            className="form-control m-input"
                            data-val="true"
                            data-val-required="Please enter first name"
                            id="SMTPEmail"
                            name="SMTPEmail"
                            type="text"
                            onChange={this.HandleChange.bind(this, "SMTPEmail")}
                            value={this.state.Fields["SMTPEmail"]}
                          />
                          <span style={{ color: "red" }}>
                          <small>
                            {this.state.Errors["SMTPEmail"]}
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols">
                        <label className="col-lg-4 view-lable-meet">
                          IMAP Email
                        </label>
                        <div className="col-lg-8">
                          <input
                            className="form-control m-input"
                            data-val="true"
                            data-val-required="Please enter first name"
                            id="IMAPEmail"
                            name="IMAPEmail"
                            type="text"
                            onBlur={this.HandleImapChange.bind(
                              this,
                              "IMAPEmail"
                            )}
                            // value={this.state.Fields["IMAPEmail"]}
                          />
                          <span style={{ color: "red" }}>
                          <small>
                            {this.state.Errors["IMAPEmail"]}
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols">
                        <label className="col-lg-4 view-lable-meet">
                          SMTP Password
                        </label>
                        <div className="col-lg-8">
                          <input
                            className="form-control m-input"
                            data-val="true"
                            data-val-required="Please enter first name"
                            id="SMTPPassword"
                            name="SMTPPassword"
                            type="password"
                            // onChange={this.HandleChange.bind(
                            //   this,
                            //   "SMTPPassword"
                            // )}
                            // value={this.state.Fields["SMTPPassword"]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols">
                        <label className="col-lg-4 view-lable-meet">
                          IMAP Password
                        </label>
                        <div className="col-lg-8">
                          <input
                            className="form-control m-input"
                            data-val="true"
                            data-val-required="Please enter first name"
                            id="IMAPPassword"
                            name="IMAPPassword"
                            type="password"
                            // onChange={this.HandleChange.bind(
                            //   this,
                            //   "IMAPPassword"
                            // )}
                            // value={this.state.Fields["IMAPPassword"]}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols">
                        <label className="col-lg-4 view-lable-meet">
                          SMTP Host
                        </label>
                        <div className="col-lg-8">
                          <input
                            className="form-control m-input"
                            data-val="true"
                            data-val-required="Please enter first name"
                            id="SMTPHost"
                            name="SMTPHost"
                            type="text"
                            value={this.state.SmtpHost}
                            onChange={this.HandleChangeSmtpHost.bind(this)}
                            // onChange={this.HandleChange.bind(this, "SMTPHost")}
                            // value={this.state.Fields["SMTPHost"]}
                          />
                          <span style={{ color: "red" }}>
                          <small>
                            {this.state.Errors["SMTPHost"]}
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols">
                        <label className="col-lg-4 view-lable-meet">
                          IMAP Host
                        </label>
                        <div className="col-lg-8">
                          <input
                            className="form-control m-input"
                            data-val="true"
                            data-val-required="Please enter first name"
                            id="IMAPHost"
                            name="IMAPHost"
                            type="text"
                            value={this.state.ImapHost}
                            onChange={this.HandleChangeImapHost.bind(this)}
                            // onChange={this.HandleChangeImapHost.bind(this)}
                            // value={this.state.Fields["IMAPHost"]}
                          />
                          <span style={{ color: "red" }}>
                          <small>
                            {this.state.Errors["IMAPHost"]}
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols">
                        <label className="col-lg-4 view-lable-meet">
                          SMTP Port
                        </label>
                        <div className="col-lg-8">
                          <input
                            className="form-control m-input"
                            data-val="true"
                            data-val-required="Please enter first name"
                            id="SMTPPort"
                            name="SMTPPort"
                            type="text"
                            value={this.state.SmtpPort}
                            onChange={this.HandleChangeSmptPort.bind(this)}
                            // onChange={this.HandleChange.bind(this, "SMTPPort")}
                            // value={this.state.Fields["SMTPPort"]}
                          />
                          <span style={{ color: "red" }}>
                          <small>
                            {this.state.Errors["SMTPPort"]}
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols">
                        <label className="col-lg-4 view-lable-meet">
                          IMAP Port
                        </label>
                        <div className="col-lg-8">
                          <input
                            className="form-control m-input"
                            data-val="true"
                            data-val-required="Please enter first name"
                            id="IMAPPort"
                            name="IMAPPort"
                            type="text"
                            value={this.state.ImapPort}
                            onChange={this.HandleChangeImapPort.bind(this)}
                            // onChange={this.HandleChange.bind(this, "IMAPPort")}
                            // value={this.state.Fields["IMAPPort"]}
                          />
                          <span style={{ color: "red" }}>
                          <small>
                            {this.state.Errors["IMAPPort"]}
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols">
                        <label className="col-lg-4 view-lable-meet">
                          SMTP Security{" "}
                        </label>
                        <div className="col-lg-8">
                          <select
                            className="form-control  m-input"
                            data-val="true"
                            data-val-number="The field SMTPSSLID must be a number."
                            id="SMTPSSLID"
                            autoComplete="off"
                            value={
                              this.state.DefultSelectAccountSMTPType == null ||
                              ""
                                ? 2
                                : this.state.DefultSelectAccountSMTPType
                            }
                            onChange={(e) =>
                              this.HandledropDownChangeAccountsSsl(e)
                            }
                          >
                            <option value="">--Select--</option>

                            {this.state.AccountSslType.map((value) => (
                              <option key={value.FieldID} value={value.FieldID}>
                                {value.FieldName}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                          <small>
                            {this.state.Errors["SMTPSSLID"]}
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols">
                        <label className="col-lg-4 view-lable-meet">
                          IMAP Security
                        </label>
                        <div className="col-lg-8">
                          <select
                            className="form-control  m-input"
                            data-val="true"
                            data-val-number="The field IMAPSSLID must be a number."
                            id="IMAPSSLID"
                            autoComplete="off"
                            value={
                              this.state.DefultSelectAccountIMAPType == null ||
                              ""
                                ? 2
                                : this.state.DefultSelectAccountIMAPType
                            }
                            onChange={(e) => {
                              this.setState({
                                DefultSelectAccountIMAPType: e.target.value,
                              });
                            }}
                          >
                            <option value="">--Select--</option>

                            {this.state.AccountSslType.map((value) => (
                              <option key={value.FieldID} value={value.FieldID}>
                                {value.FieldName}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                          <small>
                            {this.state.Errors["IMAPSSLID"]}
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Throttle Starting Volume{" "}
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input arrow-none"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="ThrottleStartingVolume"
                        name="ThrottleStartingVolume"
                        type="number"
                        // onChange={this.HandleChange.bind(
                        //   this,
                        //   "ThrottleStartingVolume"
                        // )}
                        // value={this.state.Fields["ThrottleStartingVolume"]}
                      
                        value={this.state.ThrottlestrVolumne}
                        onChange={this.HandleChangeThrottlestrVolume.bind(this)}
                        min="1"
                        max="200"
                        />
                      <span style={{ color: "red" }}>
                        <small>
                        {this.state.Errors["ThrottleStartingVolume"]}
                        </small>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Throttle Ending Volume
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input arrow-none"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="ThrottleEndingVolume"
                        name="ThrottleEndingVolume"
                        type="number"
                        value={this.state.ThrottleEndingVolume}
                        onChange={this.HandleThrottleEndingVolume.bind(this)}
                        min="1"
                        max="1000"
                        // onChange={this.HandleChange.bind(
                        //   this,
                        //   "ThrottleEndingVolume"
                        // )}
                        // value={this.state.Fields["ThrottleEndingVolume"]}
                      />
                      <span style={{ color: "red" }}>
                        <small>
                        {this.state.Errors["ThrottleEndingVolume"]}
                        </small>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Email Delay </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input arrow-none"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="EmailDelay"
                        name="EmailDelay"
                        type="number"
                        onChange={this.HandleChangeEmailDelay.bind(this)}
                        // onChange={this.HandleChange.bind(this, "EmailDelay")}
                        // value={this.state.Fields["EmailDelay"]}
                      />
                      <span style={{ color: "red" }}>
                        <small>
                        {this.state.Errors["EmailDelay"]}
                        </small>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Days Till Full Throttle{" "}
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input arrow-none"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="DaysTillFullThrottle"
                        name="DaysTillFullThrottle"
                        type="number"
                        onChange={this.HandleChangeDayStillFullThrottle.bind(
                          this
                        )}
                        // onChange={this.HandleChange.bind(
                        //   this,
                        //   "DaysTillFullThrottle"
                        // )}
                        // value={this.state.Fields["DaysTillFullThrottle"]}
                      />
                      <span style={{ color: "red" }}>
                      <small>
                        {this.state.Errors["DaysTillFullThrottle"]}
                        </small>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Sending Schedule{" "}
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="SendingSchedularID"
                        value={this.state.DefaultSendingSchedul}
                        onChange={(e) =>
                          this.HandleDropdownChangeSendingScedule(e)
                        }
                      >
                        {this.state.SendingSchedul.map((value) => (
                          <option key={value._id} value={value._id}>
                            {value?.ScheduleStartTime!=null?moment(value?.ScheduleStartTime,'HH:mm').format('h:mm A'):("")}
                            {" "}To{" "}
                            {value?.ScheduleEndTime!=null?moment(value?.ScheduleEndTime,'HH:mm').format('h:mm A'):("")}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Account Type{" "}
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="AccountTypeID"
                        name="AccountTypeID"
                        autoComplete="off"
                        value={this.state.DefualtSelectAccountType}
                        onChange={(e) => this.HandleDropdownChangeAccount(e)}
                      >
                        <option>--Select--</option>
                        {this.state.AccountType.map((value) => (
                          <option key={value.FieldID} value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                {this.state.DefualtSelectAcounAuthtype == 1 ? null : (
                  <>
                    <div className="col-lg-6 my-3">
                      <div className="m-checkbox-inline">
                        <label className="check_bx">
                          <input
                            type="checkbox"
                            id="IsEmailWarmingSending"
                            onChange={(e) => this.HandleChangeCheckEmailSend(e)}
                            checked={this.state.EmailWarmingSending}
                          />
                          <span className="checkmark"></span>
                          Email Warming Sending
                        </label>
                      </div>
                    </div>

                    <div className="col-lg-6 my-3">
                      <div className="m-checkbox-inline">
                        <label className="check_bx">
                          <input
                            type="checkbox"
                            id="IsEmailWarmingReceiving"
                            onChange={(e) =>
                              this.HandleChangeCheckEmailReciv(e)
                            }
                            checked={this.state.EmailWarmingReceiving}
                          />
                          <span className="checkmark"></span>
                          Email Warming Receiving
                        </label>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

              <div className="row py-4">
              <div className="row px-3">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.BtnDisabled}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditCEmailAccountsPage = connect(
  mapState,
  actionCreators
)(EditCEmailAccountsPage);
export { connectedEditCEmailAccountsPage as EditCEmailAccountsPage };
