import React, { useEffect, useState } from "react";
const moment = require("moment");
const Fileupload = require("../../../_helpers/fileupload");
import parse from "html-react-parser";
import Axios from "axios";
import loadingicon from "../../../images/loading.gif";
import Pagination from "@material-ui/lab/Pagination";

import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";

import "froala-editor/js/plugins.pkgd.min.js";

import FroalaEditor from "react-froala-wysiwyg";
import Froala from "froala-editor";
import { CommonConstants } from "../../../_constants/common.constants";
import { Variables } from "../../../_constants/common.constants";

import { GetUserDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { GetClientDetails } from "../../../_helpers/Utility";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import $ from "jquery";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../../user/StickyHeader/StickyHeader";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable({ customtabdata, dater, updateData }) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Value");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [CUserID, SetCUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Fields, SetFields] = React.useState({});
  const [Errors, SetErrors] = React.useState({});
  const [SignatureSC2, SetSignatureSC2] = useState({ data: "" });
  const [SignatureSC1, SetSignatureSC1] = useState({ data: "" });
  const [VariableSet, SetVariableSet] = useState("");
  const [AFID, SetAFID] = useState("");
  const [ExportData, SetExportData] = useState([]);
  const [Reset, SetReset] = React.useState(false);
  const [ResetBulk, SetResetBulk] = React.useState(false);


  const [DropboxData, SetDropboxData] = React.useState([]);
  const [filename, setfilename] = React.useState("");
  const [csvData, setcsvData] = React.useState([]);
  const [files, setfiles] = React.useState([]);
  const [FeatureNameForDrop, SetFeatureNameForDrop] = React.useState(null);
  const [VariableAvailable, SetCustomVariableValue] = React.useState(null);
  const [IsExist, SetIsExist] = React.useState(false);
  const [OldVariableName, SetOldVariableName] = React.useState("");
  const [CName, SetCName] = React.useState("");
  const [trigger, setTrigger] = useState(false);
  const [IsDisabled, setIsDisabled] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  // const [Greeting, SetGreeting] = React.useState(null);
  const [Value, SetValue] = React.useState(null);
  // total display
  const [Total, SetTotal] = React.useState(0);
  const [TotalSucess, SetTotalSucess] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);

  const [MapAttrBox, SetMapAttrBox] = useState(false);
  const [IsLoading, SetIsLoading] = useState(true);

  const [DataRange, SetDataRange] = useState(dater);
  const [ShowTextEditor, setShowTextEditor] = useState(false);
  const [ShowTextEditEditor, setShowTextEditEditor] = useState(false);
  const [ShowImportCsvEditor, setShowImportCsvEditor] = useState(false)
  const [ShowDeleteImportCsvEditor, setDeleteShowImportCsvEditor] = useState(false)
  const [ShowMapAttributes, setShowMapAttributes] = useState(false)
  const [ShowDeleteMapAttributes, setDeleteShowMapAttributes] = useState(false)
  const [ShowTotalDisplayDetails, setShowTotalDisplayDetails] = useState(false)
  const [IsEditCustomVariableSets, setIsEditCustomVariableSets] = useState(false)
  const [IsBulkDeleteBtn, setIsBulkDeleteBtn] = useState(false);
  const [IsActiveBtnCheck, setIsActiveBtnCheck] = useState(false);




  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
  }, []);

  useEffect(() => {

    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetClientDetails(Details.ClientID).then((result) => {
      SetCName(result[0].Name);
    });
    Froala.RegisterCommand("Variable", {
      title: "Advanced options",
      type: "dropdown",
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      options: Vari,
      callback: function (cmd, val) {
        var editorInstance = this;
        var currentModel = editorInstance.html.get();
        var newModel = currentModel + `<span>${val}</span>`;
        editorInstance.html.set(newModel);
      },
      // Callback on refresh.
      refresh: function ($btn) {

      },
      // Callback on dropdown show.
      refreshOnShow: function ($btn, $dropdown) {
        // 
      },
    });
    // SetPage(pageforre)
    CustomVariableValuesGet(Details.ClientID, Details.ParentUserID, dater);
    ExportDataFuction(Details.ClientID, Details.ParentUserID)
  }, [Search, Page, RowsPerPage, trigger, dater]);


  // variables dropdown mapping
  var Vari = {};
  if (Variables.length > 0) {
    for (var i = 0; i < Variables.length; i++) {
      Vari["{" + Variables[i].name + "}"] = Variables[i].name;
    }
  }

  // config for code editor
  let config = {
    placeholderText: "Type something!",
    charCounterCount: false,
    toolbarButtons: ["Variable"],
    //shortcutsEnabled: ["insertTemplateButton"],
    enter: Froala.ENTER_BR,
    key:CommonConstants.EditorValidationKey,
    pastePlain: true,
    attribution: false
  };
  // get value for text editor addd
  const CustomVariableValuesSc2ModelChange = (model) => {
    SetSignatureSC2({
      data: model,
    });
  };
  const UpdataChildComponent = (value) => {
    if (value == true) {
      setTrigger(value)
    }
  }
  // get value for text editor addd
  const CustomVariableValuesSc1ModelChangeForEdit = (model) => {
    SetSignatureSC1({
      data: model,
    });
  };
  // show editor btn
  const AddBtn = () => {
    setIsDisabled(false)
    setShowTextEditor(true);
    SetMapAttrBox(false);
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("delete_csv").style.display = "none";
    setShowTextEditEditor(false);
    setShowTotalDisplayDetails(false)
  };

  // Hide editor btn
  const CancelBtn = () => {
    SetSignatureSC2({ data: "" });
    setShowTextEditor(false);
    setIsDisabled(false)
    SetMapAttrBox(false);
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("delete_csv").style.display = "none";
    setShowTextEditEditor(false);
    setShowTotalDisplayDetails(false)

  };

  // Hide editor btn for edit
  const CancelBtnEdit = () => {
    setShowTextEditEditor(false);
    SetSignatureSC1({ data: "" });
    setShowTextEditor(false);
    setIsDisabled(false)
    SetMapAttrBox(false);
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("delete_csv").style.display = "none";
    setShowTotalDisplayDetails(false)
  };
  // form validation for add
  const FromValidation = async () => {

    let FormIsValid = true;
    let Errors = {};
    var IsExist = await CustomVariableValueCheckExits(SignatureSC2?.data.replace(/<[^>]+>/g, ''));
    if (!SignatureSC2.data) {
      FormIsValid = false;
      toast.error("Add Step Body is empty, fill it!");
      SetIsLoading(false)
      setIsDisabled(false)
    }
    if (IsExist == true) {
      FormIsValid = false;
      toast.error(<>Custom Variable Value Add<br />Custom variable value already exist.</>);
      SetIsLoading(false)
      setIsDisabled(false)
    }
    SetErrors(Errors);
    return FormIsValid;
  };
  // check variable set exists edit
  const CustomVariableValueCheckExits = async (Value) => {

    var VariableData = {
      ClientID: ClientID,
      UserID: UserID,
      CustomVariableID: customtabdata._id,
      Value:  parse(Value.replace(/<[^>]+>/g, "")).trim(),
    };
    var resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/customvariablevalue/CustomVariableValueCheckExits",
      method: "POST",
      data: VariableData,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        SetCustomVariableValue(resdata.data.Data[0]?.Value);
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };

  // check variable set exists add
  const CustomVariableValueForEdit = async (Value) => {
    var VariableData = {
      ClientID: ClientID,
      UserID: UserID,
      CustomVariableID: customtabdata._id,
      Value: parse(Value.replace(/<[^>]+>/g, "")).trim(),
    };
    var resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/customvariablevalue/CustomVariableValueCheckExits",
      method: "POST",
      data: VariableData,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (OldVariableName == Value) {
          SetIsExist(true);
          return false;
        } else {
          SetCustomVariableValue(resdata.data.Data[0]?.Value);
          SetIsExist(true);
          return true;
        }
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };
  // form validation for edit
  const FromValidationForEdit = async () => {

    let FormIsValid = true;
    let Errors = {};
    var IsExist = await CustomVariableValueForEdit(SignatureSC1?.data.replace(/<[^>]+>/g, ''));


    if (!SignatureSC1?.data) {
      FormIsValid = false;
      toast.error(<>Add Step <br />Body is empty, fill it!</>);
    }
    if (IsExist == true) {
      FormIsValid = false;
      toast.error(<>Custom Variable Value Add<br />{customtabdata.Name} value already exist.</>);
    }
    SetErrors(Errors);
    return FormIsValid;
  };

  // add variable sets
  const CustomVariableValueAdd = async (e) => {
    e.preventDefault();
    setIsDisabled(true)
debugger
if(SignatureSC2?.data.trim()=="" || parse(SignatureSC2?.data.replace(/<[^>]+>/g, "")).trim() ==""){

  toast.error(<div className="toastsize">{customtabdata.Name} <br />
                  Body text is empty, fill it!.</div>);
                  setIsDisabled(false)
                  SetIsLoading(false)
            return
}
    // e.currentTarget.disabled = true;
    let Final_flag = await FromValidation();
    if (Final_flag == true) {
      SetIsLoading(true)
      let InputParaMeter = {
        ClientID: ClientID,
        UserID: UserID,
        CustomVariableID: customtabdata._id,
        Value:parse(SignatureSC2?.data.replace(/<[^>]+>/g, "")).trim(),
        IsActive: true,
        CreatedDate: new Date(),
        CreatedBy: CUserID,
        Role: Role
      };

      Axios({
        url:
          CommonConstants.MOL_APIURL + "/customvariablevalue/CustomVariableValueAdd",
        method: "POST",
        data: InputParaMeter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          CancelBtn();
          toast.success(<div className="toastsize">Custom Variable Value Add <br />
            Custom variable value added successfully.</div>);
          CustomVariableValuesGet(ClientID, UserID, dater);
          setShowTextEditor(false);
          SetSignatureSC2({ data: "" });
          updateData(true)
          UpdataChildComponent(true)
          setIsDisabled(false)
          SetIsLoading(false)

        } else {
          toast.error(res.data.Message);
          setIsDisabled(false)
          SetIsLoading(false)

        }
      });
    } else {
      document.getElementById("submitAdd").disabled = false;
    }
  };
  //get admin list
  const CustomVariableValuesGet = (CID, UID, DataRange) => {
    SetIsLoading(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      CustomVariableID: customtabdata._id,
      DateRange: DataRange,
      Role: Role
    };
    console.log(InputParameter)
    const AutomatorFeatureData = Axios({
      url:
        CommonConstants.MOL_APIURL + "/customvariablevalue/GetCustomVariableValue",
      method: "POST",
      data: InputParameter,
    });
    AutomatorFeatureData.then((Result) => {

      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);

      SetIsLoading(false)
      setIsDataLoaded(true);

    });
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);

    SetIsLoading(true)
  };
  const ExportDataFuction = (CID, UID) => {
    const Inputparameters = {
      ClientID: CID,
      UserID: UID,
      CustomVariableID: customtabdata._id,
      DateRange: dater
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/customvariablevalue/CustomVariableValueExport",
      method: "POST",
      data: Inputparameters,
    }).then((Res) => {
      SetExportData(Res.data.Data);
    });
  }
  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("Search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);

        SetIsLoading(true)
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);

      SetIsLoading(true)
    }
  };

  //get sort field data
  const SortData = (Field) => {



    var SearchedVal = document.getElementById("Search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SetIsLoading(true)
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        CustomVariableID: customtabdata._id,
        DateRange: DataRange,
        Role: Role
      };
      const AutomatorFeatureData = Axios({
        url:
          CommonConstants.MOL_APIURL + "/customvariablevalue/GetCustomVariableValue",
        method: "POST",
        data: InputParameter,
      });
      AutomatorFeatureData.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);

        SetIsLoading(false)

      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      SetIsLoading(true)
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        CustomVariableID: customtabdata._id,
        DateRange: DataRange,
        Role: Role
      };

      const AutomatorFeatureData = Axios({
        url:
          CommonConstants.MOL_APIURL + "/customvariablevalue/GetCustomVariableValue",
        method: "POST",
        data: InputParameter,
      });
      AutomatorFeatureData.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);

        SetIsLoading(false)

      });
    }
  };

  // Delete for auto feature
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a custom variable value..",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          CustomVariableValueID: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/customvariablevalue/CustomVariableValueDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire("Deleted!", "Custom variable value deleted successfully", "success");
              CustomVariableValuesGet(ClientID, UserID, dater);
              updateData(true)
              UpdataChildComponent(true)
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // automator feature  edit
  const EditBtn = (CustomVariableValueID) => {
    SetIsLoading(true)
    SetAFID(CustomVariableValueID);
    setShowTextEditEditor(true);
    var InputParaMeter = {
      CustomVariableValueID: CustomVariableValueID,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/customvariablevalue/CustomVariableValueGetIdByData",
      method: "POST",
      data: InputParaMeter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        SetSignatureSC1({ data: res.data.Data[0].Value });
        SetVariableSet(res.data.Data[0].Value);
        SetOldVariableName(res.data.Data[0].Value);

        SetIsLoading(false)

      } else {
        toast.error(res.data.Message);

        SetIsLoading(false)

      }
    });
  };
  // save button for automator update
  const SaveBtnForUpdateAutomator = async () => {
    SetIsLoading(true)
    
    if(SignatureSC1?.data=="" ||  parse(SignatureSC1?.data.replace(/<[^>]+>/g, "")).trim()==""){

      toast.error(<div className="toastsize">{customtabdata.Name} <br />
                      Body text is empty, fill it!.</div>);
                      setIsDisabled(false)
                      SetIsLoading(false)
                return
    }
    // e.currentTarget.disabled = true;
    var FinalValidation = await FromValidationForEdit();

    if (FinalValidation == true) {
      setIsEditCustomVariableSets(true)
      var AutomatorFeatureData = {
        CustomVariableValueID: AFID,
        Value: parse(SignatureSC1?.data.replace(/<[^>]+>/g, "")).trim(),
        LastUpdatedBy: CUserID,
        LastUpdatedDate: new Date(),
        Role: Role
      };

      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/customvariablevalue/CustomVariableValueUpdate",
        method: "POST",
        data: AutomatorFeatureData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {

          toast.success(<>Custom Variable Value Edit<br />{customtabdata.Name} updated successfully..</>);

          CancelBtnEdit();
          CustomVariableValuesGet(ClientID, UserID, dater);
          updateData(true)
          UpdataChildComponent(true)
          SetIsLoading(false)
          setIsEditCustomVariableSets(false)
        } else {
          toast.error(res.data.Message);
          SetIsLoading(false)
          setIsEditCustomVariableSets(false)
        }
      });
    } else {

      SetIsLoading(false)
      setIsEditCustomVariableSets(false)
    }
  };

  //Update Status is active
  const UpdateStatus = (CustomVariableValueID, Flag) => {
    setIsActiveBtnCheck(true)
    const InputParameters = {
      CustomVariableValueID: CustomVariableValueID,
      IsActive: Flag,
      LastUpdatedBy: CUserID,
      LastUpdatedDate: new Date(),
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/customvariablevalue/CustomVariableValueIsActive",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res) {
        if ((res.statusText = "Ok")) {
          if (res.data.Data?.IsActive == true) {
            toast.success(
              <div className="toastsize">
                Custom Variable Value
                <br />
                {customtabdata.Name} variable value Active successfully.{" "}
              </div>
            );
            updateData(true)
            UpdataChildComponent(true)
            setIsActiveBtnCheck(false)
            CustomVariableValuesGet(ClientID, UserID, DataRange);
          } else {
            toast.success(
              <div className="toastsize">
                Custom Variable Value <br /> {customtabdata.Name} variable value Inactivated
                successfully.{" "}
              </div>
            );

          }
          CustomVariableValuesGet(ClientID, UserID, DataRange);
          updateData(true)
          UpdataChildComponent(true)
          setIsActiveBtnCheck(false)
        } else {
          setIsActiveBtnCheck(false)
        }
      }
    });
  };

  // export csv
  const ExportCsv = () => {
    toast.success(<div className="toastsize">Data exported successfully.</div>);
    SetMapAttrBox(false);
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("delete_csv").style.display = "none";
    setShowTextEditor(false);
    setShowTextEditEditor(false);
    document.getElementById("delete_csv").style.display = "none";
    setShowTotalDisplayDetails(false)
  };

  // import csv
  const CustomVariableValueImportCsv = () => {
    SetMapAttrBox(false);
    document.getElementById("import_csv").style.display = "block";
    document.getElementById("delete_csv").style.display = "none";
    document.getElementById("delete_map_attribute").style.display = "none";
    document.getElementById("delete_csv").style.display = "none";
    document.getElementById("map_attribute").style.display = "none";
    setShowTextEditor(false);
    setShowTextEditEditor(false);
    setShowTotalDisplayDetails(false)
  };

  // reset function
  const ResetCsv = () => {
    SetReset(true);
  };

  // handle on drop
  const HandleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;

    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {

      SetReset(true);

      ResetCsv();
    } else {
      var csvfile =  sep.slice(0, -1).join(".") + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);


      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  // handle on error
  const HandleOnError = (err, file, inputElem, reason) => { };

  // handle on remove file
  const HandleOnRemoveFile = (data) => { };

  // map csv
  const CustomVariableValueImportCsvMapCsv = () => {
    SetReset(!Reset);

    SetMapAttrBox(true);
    SetIsLoading(true)
    if (csvData.length != 0) {
      document.getElementById("map_attribute").style.display = "block";
      document.getElementById("import_csv").style.display = "block";
      SetMapAttrBox(false);
      SetReset(!Reset);

      SetIsLoading(false)

      DropboxData.map((dddd, index) => { });
    } else {
      SetReset(!Reset);
      SetMapAttrBox(false);
      document.getElementById("map_attribute").style.display = "none";
      document.getElementById("import_csv").style.display = "block";
      setShowTotalDisplayDetails(false)

      SetIsLoading(false)
      toast.error(<div>{customtabdata.Name} <br />Please select file.</div>)

    }
    hideCSVHeaderOptions();
  };

  // map csv for delte
  const DeleteForMapCsv = () => {
    SetResetBulk(!ResetBulk);
    document.getElementById("delete_map_attribute").style.display = "block";
    if (csvData.length != 0) {
      SetResetBulk(!ResetBulk);
      DropboxData.map((dddd, index) => { });
    } else {
      SetResetBulk(!ResetBulk);
    }
    hideCSVHeaderOptions();
  };

  // show bulk delete
  const DeleteBtnBulk = () => {
    document.getElementById("delete_csv").style.display = "block";
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("delete_map_attribute").style.display = "none";
    document.getElementById("map_attribute").style.display = "none";


    setShowTextEditor(false);
    setShowTextEditEditor(false);
    SetMapAttrBox(false);
    setShowTotalDisplayDetails(false)
  };

  const hideCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };

  $("select").on("change", function () {
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");

    if (Name === "Value") {
      SetValue(value);
    } else {
      SetValue(value);
    }
    hideCSVHeaderOptions();
  });

  // cancel button import
  const VariableSetCancelBtn = () => {
    ResetCsv()
    SetReset(!Reset);
    SetMapAttrBox(false);
    setcsvData([]);
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("import_csv").style.display = "none";
    setShowTotalDisplayDetails(false)
    SetValue(null)
    document.getElementById("delete_csv").style.display = "none";
  };

  const BulkDeleteCancelBtn = () => {
    SetResetBulk(!ResetBulk);

    document.getElementById("delete_map_attribute").style.display = "none";
    document.getElementById("delete_csv").style.display = "none";
    SetValue(null)
  };

  // save csv
  const Savecsv = () => {
    var Value = document.getElementById("ValueImport").value;
    if (Value === "Not Mapped") {
      toast.error(<div>{customtabdata.Name}<br />{customtabdata.Name} not mapped!</div>)
    } else {
      SetIsLoading(true)
      SetMapAttrBox(true)

      var CsvData = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParaMeter = {
          fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserCustomVariableValue",
          File: _base64,
          ImageName: filename,
          FileData: CsvData,
          Value: Value,
          UserID: UserID,
          ClientID: ClientID,
          CreatedBy: UserID,
          CreatedDate: new Date(),
          CustomVariableID: customtabdata._id,
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL + "/customvariablevalue/Customvariablevalueimportfile",
          method: "POST",
          data: InputParaMeter,
        }).then((res) => {

          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">custom variable values added successfully.</div>
            );
            SetReset(!Reset);
            SetMapAttrBox(true)
            document.getElementById("map_attribute").style.display = "block";
            document.getElementById("import_csv").style.display = "block";
            setShowTotalDisplayDetails(true)
            SetTotal(res.data.Total);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetTotalSucess(res.data.Totalsuccess);
            SetTotalError(res.data.TotalError);
            setcsvData([]);
            SetValue(null)
            CustomVariableValuesGet(ClientID, UserID, dater)
            updateData(true)
            UpdataChildComponent(true)
            SetIsLoading(false)
          } else {
            toast.error(res.data.Message);
            SetReset(!Reset);
            SetMapAttrBox(false);
            SetValue(null)
            SetIsLoading(false)
            SetMapAttrBox(true)
          }
        });
      });
    }

  };

  // bulk delete variables set
  const BulkDeleteSave = () => {
    var Value = document.getElementById("ValueBulkDelte").value;
    if (Value === "Not Mapped") {
      toast.error(<div>{customtabdata.Name}<br />{customtabdata.Name} not mapped!</div>)
    } else {
      SetIsLoading(true)
      setIsBulkDeleteBtn(true)
      var CsvData = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParaMeter = {
          fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserCustomVariableValue",
          File: _base64,
          ImageName: filename,
          FileData: CsvData,
          Value: Value,
          UserID: UserID,
          ClientID: ClientID,
          LastUpdatedBy: CUserID,
          LastUpdatedDate: new Date(),
          CustomVariableID: customtabdata._id,
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL + "/customvariablevalue/CustomVariableValueBulkDelete",
          method: "POST",
          data: InputParaMeter,
        }).then((res) => {

          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">
                custom variable values sets deleted successfully.
              </div>
            );
            SetResetBulk(!ResetBulk)
            SetMapAttrBox(true)
            SetValue(null)
            setcsvData([]);
            document.getElementById("delete_map_attribute").style.display =
              "none";
            document.getElementById("delete_csv").style.display = "none";
            updateData(true)
            CustomVariableValuesGet(ClientID, UserID,dater);
            UpdataChildComponent(true)
            setIsBulkDeleteBtn(false)
            SetIsLoading(false)
          } else {
            toast.error(res.data.Message);
            SetResetBulk(!ResetBulk)
            SetMapAttrBox(true)
            setcsvData([]);
            SetValue(null)
            setIsBulkDeleteBtn(false)
            SetIsLoading(false)
          }
        });
      });
    }

  };
  return (
    <>
      {
        IsLoading === true ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : null
      }

      <div className="row borderbtnm d-flex align-items-center  ">
        <div className="col">
          <h4 className="headertitlenop pl-2 mb-0">{customtabdata.Name}</h4>
        </div>
        <div className="col-7 pb-3">
          <div className="listing-li float-right">
            <ul>
              <li className="px-0">
                <a
                  onClick={AddBtn}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i class="la la-icon-font-size-13 la-plus"></i>
                  <span>Add</span>
                </a>
              </li>
              <li>
                <a
                  onClick={CustomVariableValueImportCsv}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i class="la la-icon-font-size-13 la-upload"></i>
                  <span>Import</span>
                </a>
              </li>
              <li className="px-0">
                <CSVDownloader
                  className="px-0"
                  data={ExportData}
                  filename={CName + "CustomVariableSet"}
                  bom={true}
                >

                  <a
                    onClick={ExportCsv}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >

                    <i class="la la-icon-font-size-13 la-download"></i>
                    <span>Export</span>

                  </a>
                </CSVDownloader>

              </li>
              <li>
                <a
                  onClick={DeleteBtnBulk}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i class="la flaticon-delete-1"></i>
                  <span>Delete</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Import Csv for variable sets*/}

      <div className="portletbody" id="import_csv" style={{ display: "none" }}>
        <div className="row">
          <div className="col-xl-8 offset-xl-2">
            <h3 className="uploadheadingtitle">Import {customtabdata.Name}</h3>
            <div className="uplodfilesbox">
              <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                onDrop={HandleOnDrop}
                onError={HandleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={HandleOnRemoveFile}
                isReset={Reset}
              >
                <span>Select .CSV file</span>
                <p>Add {customtabdata.Name} data from csv file to import.</p>
              </CSVReader>
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2">
            <div className="alignright">
              <a
                onClick={CustomVariableValueImportCsvMapCsv}
                className="btn btn-primary btnColor"
              >
                <i className="la la-upload"></i> Upload
              </a>
              <a onClick={VariableSetCancelBtn} className="btn btn-secondary">
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div>


      {/* Mapped div */}
      <div
        className="portletbody portletshadow"
        id="map_attribute"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left">Map attributes</h4>
        </div>
        <div className="col-xs-12" id="divContactSelection">
          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">

              <p>Value</p>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ValueImport"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  disabled={MapAttrBox}
                  value={
                    Value == null
                      ? DropboxData.indexOf("Value")
                      : Value
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <button onClick={Savecsv} disabled={MapAttrBox} className="btn btn-primary btn-lightgreen">
              <i className="la la-save"></i>Save
            </button>
          </div>
        </div>
      </div>


      {/* Total Details for csv */}
      {
        ShowTotalDisplayDetails === true ? <div
          className="portletbody px-4"
          id="total_div_display"
        >
          <div className="row col-xs-12">
            <h4 className="headertitle float-left">Results</h4>
          </div>

          <div className="col-xs-12">
            <div class="row colfive">
              <div class="col-md-3">
                <div class="shadowcard boxcardcounter">
                  <div class="d-flex align-center">
                    <div class="contectboxes w-100">
                      <div class="line-title mb-2">
                        <span class="box-main-title font-primary">{Total}</span>
                      </div>
                      <div class="box-inner-title xs-headertitle">
                        <string class="clr-sky">Total</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="shadowcard boxcardcounter">
                  <div class="d-flex align-center">
                    <div class="contectboxes w-100">
                      <div class="line-title mb-2">
                        <span class="box-main-title font-success">
                          {TotalSucess}
                        </span>
                      </div>
                      <div class="box-inner-title xs-headertitle">
                        <string class="clr-sky">Total success</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="shadowcard boxcardcounter">
                  <div class="d-flex align-center">
                    <div class="contectboxes w-100">
                      <div class="line-title mb-2">
                        <span class="box-main-title font-danger">
                          {TotalError}
                        </span>
                      </div>
                      <div class="box-inner-title xs-headertitle">
                        <string class="clr-sky">Total error</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="shadowcard boxcardcounter">
                  <div class="d-flex align-center">
                    <div class="contectboxes w-100">
                      <div class="line-title mb-2">
                        <span class="box-main-title font-warning">
                          {TotalDuplicate}
                        </span>
                      </div>
                      <div class="box-inner-title xs-headertitle">
                        <string class="clr-sky">Total Duplicate</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : null
      }


      {/* Bulk delete for variables set */}
      <div className="portletbody" id="delete_csv" style={{ display: "none" }}>
        <div className="row">
          <div className="col-xl-8 offset-xl-2">
            <h3 className="uploadheadingtitle">Bulk Delete {customtabdata.Name}</h3>
            <div className="uplodfilesbox">
              <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                onDrop={HandleOnDrop}
                onError={HandleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={HandleOnRemoveFile}
                isReset={ResetBulk}
              >
                <span>Select .CSV file</span>
                <p>Add {customtabdata.Name} data from csv file to bulk delete.</p>
              </CSVReader>
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2">
            <div className="alignright">
              <a onClick={DeleteForMapCsv} className="btn btn-primary btnColor">
                <i className="la la-upload"></i> Upload
              </a>
              <a onClick={BulkDeleteCancelBtn} className="btn btn-secondary">
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mapped div */}
      <div
        className="portletbody portletshadow"
        id="delete_map_attribute"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left">Map attributes</h4>
        </div>
        <div className="col-xs-12" id="divContactSelection">
          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <p>Value</p>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ValueBulkDelte"
                  disabled={MapAttrBox}
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Value == null
                      ? DropboxData.indexOf("Value")
                      : Value
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <button
              onClick={BulkDeleteSave}
              disabled={IsBulkDeleteBtn}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Save
            </button>
          </div>
        </div>
      </div>


      {/* Add flora editor code */}
      {
        ShowTextEditor === true ? <div
          class="col-lg-12 boxsinput_group"
          id="text-editor"

        >
          <div class="row">
            <label class="col-lg-2 view-lable-meet">{customtabdata.Name}</label>
            <div class="col-lg-10">
              <FroalaEditor
                id="signature"
                config={config}
                onModelChange={CustomVariableValuesSc2ModelChange}
                model={SignatureSC2.data}
              />
            </div>
          </div>
          <div class="row border-top pt-4 mt-4 mb-3">
            <div class="col-lg-12 text-right">
              <button
                id="submitAdd"
                onClick={CustomVariableValueAdd}
                class="btn btn-primary btn-lightgreen mr-1"
                disabled={IsDisabled}
              >
                <i class="la la-save"></i> Save
              </button>
              <button
                id="backtolist"
                onClick={CancelBtn}
                class="btn btn-secondary"
              >
                <i class="la la-arrow-circle-left"></i> Cancel
              </button>
            </div>
          </div>
        </div> : null
      }

      {/* Edit flora editor code */}
      {
        ShowTextEditEditor === true ? <div
          class="col-lg-12"
          id="text-editor-edit"
        >
          <div class="row pt-3">
            <label class="col-lg-2 view-lable-meet">{customtabdata.Name}</label>
            <div class="col-lg-10">
              <FroalaEditor
                id="signature"
                config={config}
                onModelChange={CustomVariableValuesSc1ModelChangeForEdit}
                model={SignatureSC1.data}
              />
            </div>
          </div>
          <div class="row mt-3 border-top py-3">
            <div class="col-lg-12">
              <div class="pull-right">
                <button
                  id="submitedit"
                  onClick={SaveBtnForUpdateAutomator}
                  class="btn btn-primary btn-lightgreen mr-1"
                  disabled={IsEditCustomVariableSets}
                >
                  <i class="la la-save"></i> Save
                </button>
                <button
                  id="backtolist"
                  onClick={CancelBtnEdit}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Cancel
                </button>
              </div>
            </div>
          </div>
        </div> : null
      }


      <div className="row pt-2 px-2 pb-3">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              defaultValue={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="Search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
      <TableContainer component={Paper}>
      <StyleHeader isDataLoaded={isDataLoaded} />
          <Table class='table-ref' aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Active</TableCell>
                <TableCell              onClick={() => {
                      SortData("Value");
                    }}>
                  {" "}
                  <a
       
                  >
                    Value
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "Value" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "Value" ? "active" : null} />
                    </span>
                  </a>{" "}
                </TableCell>
                <TableCell onClick={() => {
                      SortData("Sent");
                    }}>
                  <a
                    
                  >
                    {" "}
                    Sent{" "}
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "Sent" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "Sent" ? "active" : null} />
                    </span>
                  </a>
                </TableCell>
                <TableCell     onClick={() => {
                      SortData("Tracked");
                    }}>
                  <a
                
                  >
                    {" "}
                    Tracked{" "}
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "Tracked" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "Tracked" ? "active" : null} />
                    </span>
                  </a>
                </TableCell>
                <TableCell  onClick={() => {
                      SortData("Reply");
                    }}>
                  <a
                   
                  >
                    {" "}
                    Replies{" "}
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "Reply" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "Reply" ? "active" : null} />
                    </span>
                  </a>
                </TableCell>
                <TableCell onClick={() => {
                      SortData("Intrested");
                    }}>
                  <a
                    
                  >
                    {" "}
                    Interested{" "}
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "Intrested" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "Intrested" ? "active" : null} />
                    </span>
                  </a>
                </TableCell>
                <TableCell              onClick={() => {
                      SortData("OpenPercentage");
                    }}>
                  <a
       
                  >
                    {" "}
                    Open %{" "}
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "OpenPercentage" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "OpenPercentage" ? "active" : null} />
                    </span>
                  </a>
                </TableCell>
                <TableCell     onClick={() => {
                      SortData("ReplyPercentage");
                    }}>
                  <a
                
                  >
                    {" "}
                    Reply %{" "}
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "ReplyPercentage" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "ReplyPercentage" ? "active" : null} />
                    </span>
                  </a>
                </TableCell>
                <TableCell          onClick={() => {
                      SortData("IntrestedPercentage");
                    }}>
                  <a
           
                  >
                    Interested %{" "}
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "IntrestedPercentage" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "IntrestedPercentage" ? "active" : null} />
                    </span>
                  </a>
                </TableCell>
                <TableCell          onClick={() => {
                      SortData("CreatedDate");
                    }}>
                  <a
           
                  >
                    Created Date
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "CreatedDate" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "CreatedDate" ? "active" : null} />
                    </span>
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length == 0 ? (
                <p className="text-center">No data in available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow>
                    <TableCell>
                      <div class="toggleswich">
                        <input
                          type="checkbox"
                          disabled={IsActiveBtnCheck}
                          onChange={() => {
                            UpdateStatus(row._id, !row.IsActive);
                          }}
                          class="checktogle"
                          checked={row?.IsActive}
                        />
                        <b class="switch">
                          <span class="checkion"></span>
                          <span class="uncheckion"></span>
                        </b>
                        <b class="track"></b>
                      </div>
                    </TableCell>
                    <TableCell>
                      {parse(row?.Value.replace(/<[^>]+>/g, ""))}
                    </TableCell>
                    <TableCell>{row?.Sent}</TableCell>
                    <TableCell>{row?.Tracked}</TableCell>
                    <TableCell>{row?.Reply}</TableCell>
                    <TableCell>{row?.Intrested}</TableCell>
                    <TableCell><Tooltip title={<>
                      <span>Opens: {row?.Open === undefined ? 0 : row?.Open}</span><br />
                      <span>Tracked: {row.Tracked === undefined ? 0 : row?.Tracked}</span><br />
                      <span>Open %:   {parseInt(row?.OpenPercentage) + "%"}</span>
                    </>} placement="right">
                      <span>  {parseInt(row?.OpenPercentage) + "%"}</span>
                    </Tooltip></TableCell>
                    <TableCell><Tooltip title={<>
                      <span>Replies: {row.Reply === undefined ? 0 : row?.Reply}</span><br />
                      <span>Sent: {row.Sent === undefined ? 0 : row?.Sent}</span><br />
                      <span>Reply %: {parseInt(row?.ReplyPercentage) + "%"}</span>
                    </>} placement="right">
                      <span>{parseInt(row?.ReplyPercentage) + "%"}</span>
                    </Tooltip></TableCell>
                    <TableCell><Tooltip title={<>
                      <span>Replies: {row.Reply === undefined ? 0 : row?.Reply}</span><br />
                      <span>Interested: {row.Intrested === undefined ? 0 : row?.Intrested}</span><br />
                      <span>Interested %: {parseInt(row?.IntrestedPercentage) + "%"}</span>
                    </>} placement="right">
                      <span>{parseInt(row?.IntrestedPercentage) + "%"}</span>
                    </Tooltip></TableCell>
                    <TableCell>
                      {" "}
                      {moment(new Date(row?.CreatedDate).toDateString()).format(
                        "MM/DD/YYYY"
                      )}
                    </TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a
                        onClick={() => {
                          DeleteBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
        </div>
      ) : (
        <div className="row px-4 pt-3">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
